import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { userEmailCheck } from "../../../services/loginService";
import { unBlank } from "../../../common/util";

function FacebookEmailModal({ handleResult2, setModal2 }) {
  const { t } = useTranslation();
  const [userEmail, setUserEmail] = useState("");
  const [failMsg, setFailMsg] = useState("");
  const [emailIsFail, setEmailIsFail] = useState(false);

  //이메일 정규식
  function validateEmail() {
    if (userEmail !== undefined && userEmail.trim() !== "") {
      const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regexp.test(userEmail);
    }
    return false;
  }

  const submit = () => {
    if (
      userEmail === undefined ||
      userEmail.trim() === "" ||
      userEmail == null
    ) {
      setFailMsg(t("EmailIn"));
      setEmailIsFail(true);
    } else if (validateEmail(userEmail) === false) {
      setFailMsg(t("EmailInE"));
      setEmailIsFail(true);
    } else {
      userEmailCheck(userEmail).then((res) => {
        if (res.resCd === "0000") {
          setModal2(false);
          handleResult2(userEmail);
        } else {
          setEmailIsFail(true);
          setFailMsg(t("DuplicateEmail"));
        }
      });
    }
  };

  return (
    <div className="modal-body-alert">
      <div className="modal-body-header">
        {t('SnsEmail-Msg')}
        <br /> {t('SnsEmail-Msg2')}
      </div>
      <div className="modal-msg-area">
        <input
          type="text"
          id="inputEmail"
          placeholder={t("EmailIn")}
          value={userEmail}
          onChange={(e) => {
            setUserEmail(unBlank(e.target.value));
          }}
        />
      </div>
      <div>
      {emailIsFail && (<p className="SignupAlertMsg">{failMsg}</p>)}
      </div>
      <div className="modal-btn-area">
        <button
          type="button"
          className="alert-default-btn"
          onClick={() => {
            submit();
          }}
        >
          {t("Check")}
        </button>
      </div>
    </div>
  );
}

export default FacebookEmailModal;
