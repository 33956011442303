import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import phoneImg from "../../../images/phone_2.png";
import webSiteImg from "../../../images/website.png";
import mailImg from "../../../images/mail_2.png";
import messageImg from "../../../images/message.png";
import people from "../../../images/people.png";
import profile from '../../../images/profile.png';
import { useMediaQuery } from "react-responsive";

import { getProfessional, delegateBtnNm } from "../../../common/util";
import { getDelegateClick } from "../../../services/delegateService";

import "../../../css/delegate/Delegate.css";
import arrow from "../../../images/back.png";
function DelegateItem({ item }) {
  const { t } = useTranslation();
  let lang = localStorage.getItem("language");
  let userInfo = JSON.parse(localStorage.getItem("ziple_user"));
  const [profieldUrl, setProfieldUrl] = useState([]);
  const [field, setField] = useState([]);
  const [fieldBackground, setFieldBackground] = useState([]);

  const isMobile = useMediaQuery({ maxWidth: 767 });
  // setIsMobile(isMobile?true:false);
  // return isMobile ? children : null;

  useEffect(() => {
    if (lang === "ko") {
      const parse = getProfessional(
        item.professionalField,
        item.delegateIPMandate
      );
      setFieldBackground(parse.backgroundColor);
      setProfieldUrl(parse.flagUrls);
      setField(parse.field);
    } else {
      const parse = getProfessional(
        item.professionalFieldEn,
        item.delegateIPMandate
      );
      setFieldBackground(parse.backgroundColor);
      setProfieldUrl(parse.flagUrls);
      setField(parse.field);
    }
  }, [item, lang]);

  const delegateClick = (div, value, adNo, adNm) => {
    if (value != null) {
      if (div === 1) {
        window.location.href = "tel:" + value;
      } else if (div === 2) {
        window.location.href =
          "mailto:" +
          value +
          "?subject=[ziple]대리인에게 질문입니다. " +
          "&body=[자이플]에서 보낸 메일입니다. https://www.ziple.biz";
      } else if (div === 4 || div === 5 || div === 6 || div === 7) {
        window.open(value);
      }

      window.gtag(
        "event",
        adNm + "대리인 " + delegateBtnNm(div.toString()) + " 버튼 클릭",
        {
          eventCategory: div,
          eventLabel:
            adNm + "대리인 " + delegateBtnNm(div.toString()) + " 버튼 클릭",
          eventAction: div + "버튼 클릭",
          eventValue: 1,
        }
      );

      let form = {
        userNo: userInfo !== null ? userInfo.userNo : 0,
        action: div,
        no: adNo,
        cateDiv: "brand",
      };
      getDelegateClick(form).then((res) => {});
    }
  };

  const handleImgError = (e)=>{
    e.target.src = profile
 }

  return (
    <>
      {isMobile ? (
        <div className="delegate-item-box ">
          <div className="delegate-profile-content">
            <div className="flex">
              <img src={people} alt="" style={{ marginRight: 10 }} />
              {item.adGrade === "1" ? (
                <div className="delegate-adGrade-member">MEMBER</div>
              ) : (
                <div className="delegate-adGrade-premium">PREMIUM</div>
              )}
            </div>
            <div className="delegate-profile">
            <div className="delegate-profile-img">
              <img src={item.profileUrl} alt="profile"  onError={handleImgError}/>
            </div>
            <div className="delegate-profile-name">
              {item.regUserNm}
              &nbsp;
              {item.delegateDiv === "1" ? (
                <span>{t("Attorney")}</span>
              ) : (
                <span>{t("Lawyer")}</span>
              )}
              &nbsp;
              {item.phoneYn !== "N" && (
                <button
                  className="delegate-info-button"
                  onClick={() =>
                    delegateClick(1, item.phoneNo, item.adNo, item.regUserNm)
                  }
                >
                  <img alt="phoneImg" src={phoneImg} />
                </button>
              )}
              {item.adGrade != "1" && (
                <>
                  {item.siteUrl != null && (
                    <button
                      className="delegate-info-button"
                      onClick={() =>
                        delegateClick(
                          7,
                          item.siteUrl,
                          item.adNo,
                          item.regUserNm
                        )
                      }
                    >
                      <img src={webSiteImg} />
                    </button>
                  )}
                  {item.userEmail != null && (
                    <button
                      className="delegate-info-button"
                      onClick={() =>
                        delegateClick(
                          2,
                          item.userEmail,
                          item.adNo,
                          item.regUserNm
                        )
                      }
                    >
                      <img src={mailImg} />
                    </button>
                  )}
                  {item.kakaoInfo != null && (
                    <button
                      className="delegate-info-button"
                      onClick={() =>
                        delegateClick(
                          4,
                          item.kakaoInfo,
                          item.adNo,
                          item.regUserNm
                        )
                      }
                    >
                      <img src={messageImg} />
                    </button>
                  )}
                </>
              )}
            </div>
            {item.adGrade === "2" && (
              <>
                <div className="delegate-detail-btn">
                  <div
                    onClick={() => {
                      window.location.href = "/info/detail/" + item.userNo;
                      window.scrollTo(0, 0);
                    }}
                  >
                    {t("Delegate-View-More")}
                    {/* <img src={arrow} /> */}
                  </div>
                </div>
                <div className="delegate-detail-btn">
                  <div
                    style={{ backgroundColor: "#3F63E5", color: "white" }}
                    onClick={() => {
                      window.location.href = "/reservation/" + item.userNo;
                      window.scrollTo(0, 0);
                    }}
                  >
                    상담 예약 하기
                    {/* <img src={arrow} /> */}
                  </div>
                </div>
              </>
            )}
          </div>
           

            <div className="flex delegate-detail-field-wrap">
              <div className="flex">
                {profieldUrl.map((img, k) => (
                  <div className="delegate-detail-cate" key={k}>
                    {/* <img src={img} alt="img" /> */}
                    <div
                      style={{ background: fieldBackground[k] }}
                    >
                      {field[k]}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {lang === "ko" ? (
              <div className="delegate-explain">{item.contents}</div>
            ) : (
              <div className="delegate-explain">{item.contentsEn}</div>
            )}
          </div>
          
        </div>
      ):(
        <div className="delegate-item-box flex">
          <div className="delegate-profile-content">
            <div className="flex">
              <img src={people} alt="" style={{ marginRight: 10 }} />
              {item.adGrade === "1" ? (
                <div className="delegate-adGrade-member">MEMBER</div>
              ) : (
                <div className="delegate-adGrade-premium">PREMIUM</div>
              )}
            </div>
            <div className="delegate-profile-name">
              {item.regUserNm}
              &nbsp;
              {item.delegateDiv === "1" ? (
                <span>{t("Attorney")}</span>
              ) : (
                <span>{t("Lawyer")}</span>
              )}
              &nbsp;
              {item.phoneYn !== "N" && (
                <button
                  className="delegate-info-button"
                  onClick={() =>
                    delegateClick(1, item.phoneNo, item.adNo, item.regUserNm)
                  }
                >
                  <img alt="phoneImg" src={phoneImg} />
                </button>
              )}
              {item.adGrade != "1" && (
                <>
                  {item.siteUrl != null && (
                    <button
                      className="delegate-info-button"
                      onClick={() =>
                        delegateClick(
                          7,
                          item.siteUrl,
                          item.adNo,
                          item.regUserNm
                        )
                      }
                    >
                      <img src={webSiteImg} />
                    </button>
                  )}
                  {item.userEmail != null && (
                    <button
                      className="delegate-info-button"
                      onClick={() =>
                        delegateClick(
                          2,
                          item.userEmail,
                          item.adNo,
                          item.regUserNm
                        )
                      }
                    >
                      <img src={mailImg} />
                    </button>
                  )}
                  {item.kakaoInfo != null && (
                    <button
                      className="delegate-info-button"
                      onClick={() =>
                        delegateClick(
                          4,
                          item.kakaoInfo,
                          item.adNo,
                          item.regUserNm
                        )
                      }
                    >
                      <img src={messageImg} />
                    </button>
                  )}
                </>
              )}
            </div>

            <div className="flex delegate-detail-field-wrap">
              <div className="flex">
                {profieldUrl.map((img, k) => (
                  <div className="delegate-detail-cate" key={k}>
                    {/* <img src={img} alt="img" /> */}
                    <div
                      style={{ background: fieldBackground[k], padding: 10 }}
                    >
                      {field[k]}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {lang === "ko" ? (
              <div className="delegate-explain">{item.contents}</div>
            ) : (
              <div className="delegate-explain">{item.contentsEn}</div>
            )}
          </div>
          <div className="delegate-profile">
            <div className="delegate-profile-img">
              <img src={item.profileUrl} alt="profile"  onError={handleImgError}/>
            </div>
            {item.adGrade === "2" && (
              <>
                <div className="delegate-detail-btn">
                  <div
                    onClick={() => {
                      window.location.href = "/info/detail/" + item.userNo;
                      window.scrollTo(0, 0);
                    }}
                  >
                    {t("Delegate-View-More")}
                    {/* <img src={arrow} /> */}
                  </div>
                </div>
                <div className="delegate-detail-btn">
                  <div
                    style={{ backgroundColor: "#3F63E5", color: "white" }}
                    onClick={() => {
                      window.location.href = "/reservation/" + item.userNo;
                      window.scrollTo(0, 0);
                    }}
                  >
                    상담 예약 하기
                    {/* <img src={arrow} /> */}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )
      
      }
    </>
  );
}

export default DelegateItem;
