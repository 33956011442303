import React from "react";
import { useTranslation } from "react-i18next";

import IOS from "../../../images/app_store.png";
import Android from "../../../images/play_store.png";

import { recordClick } from "../../../common/util";

function BannerBottom() {
  const { t } = useTranslation();

  return (
    <div className="bottom-banner-box flex">
      {/* <div className="bottom-banner-content">
        <p>{t("Main-Store-Msg1")}</p>
        <p>{t("Main-Store-Msg2")}</p>
      </div> */}
       <img
        src={Android}
        className="android-in"
        alt="안드로이드 이미지"
        onClick={() =>
          recordClick(
            "Android",
            "https://play.google.com/store/apps/details?id=biz.ziple",
            t("Android-App")
          )
        }
      />
      <img
        src={IOS}
        className="ios-in"
        alt="앱스토어 이미지"
        onClick={() =>
          recordClick(
            "IOS",
            "https://apps.apple.com/kr/app/id1344704077",
            t("Apple-App")
          )
        }
      />
     
    </div>
  );
}

export default BannerBottom;
