import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import click from "../../../images/ArrowRight.png";
import Lock from "../../../images/Lock.png";
import Profile from "../../../images/profile.png";

import Modal from "../../../common/Modal";
import Alert from "../../../common/AlertModal";

import { ideaDivNm, formatDate, choiceRandomColor } from "../../../common/util";
import { getReplyList } from "../../../services/qnaService";
function QnaItem({ history, qna, user }) {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState("");
  const [replyList, setReplyList] = useState({});
  const checkLogin = () => {
    if (user.userNo === 0) {
      setMessage(t("Login-Alert"));
      setModal(true);
    } else {
      if (user.userDivCd === "1") {
        if (qna.toPublic === "Y" || user.userNo === qna.userNo) {
          history.push("/question/detail/" + qna.qnaNo);
        } else {
          setMessage(t("PrivatePost"));
          setModal(true);
        }
      } else {
        history.push("/question/detail/" + qna.qnaNo);
      }
    }
  };
  useEffect(async () => {
    if (qna.qnaNo != null) {
      await getReplyList({
        qnaNo: qna.qnaNo,
        userNo: user.userNo,
        div:'list'
      }).then((res) => {
        if (res !== null) {
          setReplyList(res.replyList);
        }
      });
    }
  }, [qna]);

  return (
    <>
      <div className="flex qna-item-wrap" onClick={checkLogin}>
        {qna.profileUrl != null ? (
          <div>
            {qna.profileUrl.indexOf("gravatar") === -1  && qna.profileUrl != null? (
              <img
                className="profile-image"
                src={qna.profileUrl}
                alt="유저 프로필"
                onError={(e) => {
                  e.target.onError = null;
                  e.target.src = Profile;
                }}
              />
            ) : (
              <div
                className="qna-color-profile"
                style={{ background: choiceRandomColor(qna.userNo) }}
              >
                {qna.nickNm.substr(0, 1)}*
              </div>
            )}
          </div>
        ) : (
          <div>
            <img src={Profile} alt="유저 프로필" className="profile-image" />
          </div>
        )}
        <div>
          <span className="qna-ideaDiv">{t(ideaDivNm(qna.ideaDiv))} </span>
          <div className="flex">
            <p className="qna-item-title">{qna.title}</p>
           
            {qna.replyCnt > 0 && (
              <span className="qna-reply-count">{qna.replyCnt}</span>
            )}
          </div>
          <p className="qna-item-content">
            {qna.nickNm != null ? qna.nickNm.substr(0, 1) + "**" : ""}{" "}
            <span style={{ opacity: "0.5" }}>|</span> {formatDate(qna.regDate)}
          </p>
          <div className="qna-item-delegate-div">
            {replyList.length > 0 && (
              <>
                {replyList.map((reply, k) => (
                  <>
                    {k < 6 && (
                      <>
                        {qna.userNo !== reply.userNo && (
                          <div style={{ display: "inline-flex" }}>
                            {reply.profileUrl != null && (
                              <>
                            {reply.profileUrl.indexOf("gravatar") === -1 ? (
                              <img
                                className="profile-image3"
                                src={reply.profileUrl}
                                alt="유저 프로필"
                                onError={(e) => {
                                  e.target.onError = null;
                                  e.target.src = Profile;
                                }}
                              />
                            ) : (
                              <div
                                className="qna-color-profile3"
                                style={{
                                  background: choiceRandomColor(qna.userNo),
                                }}
                              >
                                {reply.nickNm.substr(0, 1)}*
                              </div>
                            )}</>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </>
                ))}
              </>
            )}
            {replyList.length > 6 && (
              <div className="qna-item-delegate-div2">
                외 {replyList.length - 5}인
              </div>
            )}
          </div>
        </div>
        <div>
          {qna.toPublic === "N" ? 
              <img className="lock-image" src={Lock} alt="좌물쇠 이미지" />
            :        <img className="qna-item-click-image" src={click} alt="클릭 이미지" />
            }
        </div>
      </div>

      {modal && (
        <Modal>
          <Alert msg={message} setShowModal={setModal} />
        </Modal>
      )}
    </>
  );
}

export default withRouter(QnaItem);
