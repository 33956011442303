import React, { useState, useCallback, useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";

import PrivateRoute from "./common/PrivateRoute";
import WithMenu from "./common/WithMenu";
import Login from "./pages/login/Login";
import Main from "./pages/main/Main";
import SignUp from "./pages/login/SignUp";
import Password from "./pages/login/Password";
import MyPage from "./pages/myPage/MyPage";
import List from "./pages/delegateList/List";
import DelegateDetail from "./pages/delegateDetail/Detail";
import Lounge from "./pages/infoLounge/Lounge";
import Question from "./pages/question/Question";
import View from "./pages/view/ViewPage";
import Inactive from "./pages/inactiveAccount/Inactive";
import Delegate from "./pages/main/components/MDelegate";
import Reservation from "./pages/reservation/Reservation";
import ReservationDetail from './pages/reservation/component/Detail';

function App() {
  const { i18n } = useTranslation();
  const [initialized, setInitialized] = useState(false);

  const changeLanguage = useCallback(
    (lng) => {
      i18n.changeLanguage(lng);
    },
    [i18n]
  );

  useEffect(() => {
    if (!initialized) {
      changeLanguage(localStorage.getItem("language") || "ko");
      setInitialized(true);
    }
  }, [initialized, changeLanguage]);

  return (
    <BrowserRouter>
      <Switch>
        <WithMenu path="/" exact component={Main} />
        <Route path="/login" exact component={Login} />
        <Route path="/inactive" exact component={Inactive} />
        <Route path="/inactive/:id" exact component={Inactive} />
        <Route path="/sign-up" exact component={SignUp} />
        <Route path="/find" exact component={Password} />
        <Route path="/delegate" exact component={Delegate} />
        <PrivateRoute path="/my/:type" exact component={MyPage} />
        <WithMenu path="/faq" exact component={View} />
        <WithMenu path="/notice" exact component={View} />
        <WithMenu path="/view" exact component={View} />
        <WithMenu path="/view/:type" exact component={View} />
        <WithMenu path="/private/:id" exact component={View} />
        <WithMenu path="/private" exact component={View} />
        <WithMenu path="/info" exact component={List} />
        <WithMenu path="/info/:keyword" exact component={List} />
        <WithMenu path="/info/detail/:id" exact component={DelegateDetail} />
        <WithMenu path="/lounge" exact component={Lounge} />
        <WithMenu path="/lounge/:type" exact component={Lounge} />
        <WithMenu path="/question/list" exact component={Question} />
        <PrivateRoute path="/question/:type" exact component={Question} />
        <PrivateRoute path="/question/:type/:id" exact component={Question} />
        <PrivateRoute path="/reservation/:id" exact component={Reservation} />
        <PrivateRoute path="/rez/:type/:id" exact component={ReservationDetail} />
        <Redirect path="*" to="/" />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
