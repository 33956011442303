import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import Empty from "../../../images/empty_icon.png";
import {formatDate} from '../../../common/util';
import Item from "./QnaItem";
import RezItem from "./RezItem";
import Loading from "../../../common/Loading";

import { myQuestionList, myRezList } from "../../../services/userService";

function MyQnA({ history, user }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [rezList, setRezList] = useState([]);
  let today = new Date();
  const [menu, setMenu] = useState("qna");
  useEffect(() => {
    setLoading(true);
    
      myRezList({
        userNo: user.no,
        userDiv: "1",
        pageSize: "50",
        pageNo: 1,
        content: "",
        sortDbField: "reg_date",
        sortDirection: "desc",
        selectDate: formatDate(today), // 선택한 날짜
        deRezApm: ""
      }).then((res) => {
        console.log(res);
        setRezList(res.lists);
        setLoading(false);
      });
   
      myQuestionList({
        title: "",
        userNm: "",
        userNo: user.no,
        pageNo: 1,
        content: "",
        ideaDiv: "",
        pageSize: "10",
        sortDbField: "reg_date",
        sortDirection: "desc",
      }).then((res) => {
        setList(res.lists);
        setLoading(false);
      });
    
  }, []);

  if (loading) {
    return (
      <div className="my-page-box white">
        <Loading />
      </div>
    );
  }

  return (
    <>
      <div className="white my-page-box">
        <div className="my-page-title">
          <p>나의 상담내역</p>
        </div>
        <div className="flex book-mark-category">
          <div
            className={
              menu === "qna"
                ? "bookmark-category-active"
                : "bookmark-category-noactive"
            }
            onClick={() => setMenu("qna")}
          >
            {t("My-Q&A")} {list.length}
          </div>

          <div
            className={
              menu === "rez"
                ? "bookmark-category-active"
                : "bookmark-category-noactive"
            }
            onClick={() => setMenu("rez")}
          >
            1:1 영상 상담{rezList.length}
          </div>
        </div>
        {menu === "qna" && (
          <>
            {list.length > 0 ? (
              <>
                {list.map((qna, index) => (
                  <Item key={index} qna={qna} />
                ))}
              </>
            ) : (
              <div className="book-mark-empty-wrap">
                <img
                  src={Empty}
                  alt="비어있는 아이콘"
                  className="book-mark-empty"
                />
                <p>등록하신 질의응답이 없습니다.</p>
                <p>궁금한 서비스에 대해 언제든지 문의하세요.</p>
                <button
                  className="my-page-book-mark-button dark-blue"
                  onClick={() => history.push("/question/write")}
                >
                  질문하러가기
                </button>
              </div>
            )}
          </>
        )}
        {menu === "rez" && (
          <>
            {rezList.length > 0 ? (
              <>
                {rezList.map((rez, index) => (
                  <RezItem key={index} rez={rez} />
                ))}
              </>
            ) : (
              <div className="book-mark-empty-wrap">
                <img
                  src={Empty}
                  alt="비어있는 아이콘"
                  className="book-mark-empty"
                />
                <p>등록하신 영상상담이 없습니다.</p>
                <p>궁금한 서비스에 대해 언제든지 문의하세요.</p>
                <button
                  className="my-page-book-mark-button dark-blue"
                  onClick={() => history.push("/info")}
                >
                  상담하러가기
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default withRouter(MyQnA);
