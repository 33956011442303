import React, { useState } from "react";
// import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Brand from "../../../images/Brand.png";
import Design from "../../../images/Design.png";
import Semiconductor from "../../../images/Semiconductor.png";
import IT from "../../../images/IT.png";
import Chemistry from "../../../images/Chemistry.png";
import Elec from "../../../images/Elec.png";
import InfoCommunity from "../../../images/InfoCommunity.png";
import BigData from "../../../images/BigData.png";
import All from "../../../images/All.png";

function FieldIcon() {
  const { t } = useTranslation();
  const [modal, setModal] = useState({
    value: false,
    icon: -1,
  });

  let icon = [
    {
      name: t("Trademark"),
      image: Brand,
      text: "상표 설명",
    },
    {
      name: t("Design"),
      image: Design,
      text: "디자인 설명",
    },
    {
      name: t("Semiconductor"),
      image: Semiconductor,
      text: "반도체 설명",
    },
    {
      name: "IT",
      image: IT,
      text: "IT 설명",
    },
    {
      name: t("Chemistry"),
      image: Chemistry,
      text: "화학 설명",
    },
    {
      name: t("Electrical"),
      image: Elec,
      text: "전기전자 설명",
    },
    {
      name: t("Information"),
      image: InfoCommunity,
      text: "정보통신 설명",
    },
    {
      name: t("BigData"),
      image: BigData,
      text: "빅데이터 설명",
    },
  ];

  return (
    <div className="field-icon">
      <p>{t("Main-Field")}</p>
      <div className="flex">
        {icon.map((item, index) => (
          <div className="field-icon-box" key={index}>
            {/* <Link to={`/info/${item.name}`}> */}
            <img
              src={item.image}
              alt="분야 아이콘"
              onMouseOver={() => setModal({ value: true, icon: index })}
              onMouseLeave={() => setModal({ value: false, icon: index })}
            />
            {/* </Link> */}
            <p>{item.name}</p>
            <div className="field-explain">
              <div>
                {modal.value && modal.icon === index && <div>{item.text}</div>}
              </div>
            </div>
          </div>
        ))}
        <div className="field-icon-box">
          <img src={All} alt="전체보기 아이콘" />
          <p>{t("ViewAll")}</p>
          <div className="field-explain"></div>
        </div>
      </div>
    </div>
  );
}

export default FieldIcon;
