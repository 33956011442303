import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Modal from "../../../common/Modal";
import Alert from "../../../common/AlertModal";
import Timer from "../../../common/Timer";

import { authPhone } from "../../../services/loginService";
import { phoneHyphen } from "../../../util/inputUtil";

import {
  checkPassword,
  removeSpecialSymbol,
  onlyNumber,
  checkPhoneNumber,
} from "../../../util/inputUtil";

function InfoForm({ changeInfo, setChangeInfo, user, isLeave, setIsLeave }) {
  const { t } = useTranslation();
  const [message, setMessage] = useState("");
  const [modal, setModal] = useState(false);
  const [authNumber, setAuthNumber] = useState("");
  const [startTimer, setStartTimer] = useState(false);
  const [resetTimer, setResetTimer] = useState(false);
  const [expire, setExpire] = useState(false);
  const [showRepeatBtn, setShowRepeatBtn] = useState(false);
  const [repeatCerti, setRepeatCerti] = useState(false);

  const sendCertiNumber = () => {
    // 휴대폰 번호 11자리인지 확인 후 인증번호 전송
    if (
      changeInfo.phone.length <= 3 ||
      checkPhoneNumber(changeInfo.phone) === false
    ) {
      setMessage(t("PhoneNoCheck1"));
      setModal(true);
      return;
    }
    setMessage(t("SendAuthAlert"));
    setModal(true);
    setStartTimer(true);
    authPhone(changeInfo.phone).then((res) => {
      setAuthNumber(res.authCode);
    });
    setShowRepeatBtn(true);
  };

  const sendCertiNumberRepeat = async () => {
    if (
      changeInfo.phone.length <= 3 ||
      checkPhoneNumber(changeInfo.phone) === false
    ) {
      setMessage(t("PhoneNoCheck1"));
      setModal(true);
      return;
    }
    setMessage(t("SendAuthAlert"));
    setModal(true);
    setRepeatCerti(true);
    setResetTimer(true);
    setExpire(false);
    await authPhone(changeInfo.phone).then((res) => {
      setAuthNumber(res.authCode);
      setResetTimer(false);
    });
  };

  const checkCertiNumber = () => {
    if (
      changeInfo.phone.length <= 3 ||
      checkPhoneNumber(changeInfo.phone) === false
    ) {
      setMessage(t("PhoneNoCheck1"));
      setModal(true);
      return;
    }
    if (changeInfo.certiNumber === "") {
      setMessage(t("AuthInsert"));
      setModal(true);
      return;
    }
    // 입력한 인증번호와, 서버에서 받아온 인증번호가 일치한지 확인
    if (!expire && changeInfo.certiNumber === authNumber) {
      setMessage(t("AuthSuccess"));
      setModal(true);
      setStartTimer(false);
      setChangeInfo({
        ...changeInfo,
        certi: true,
      });
    } else if (!expire && changeInfo.certiNumber !== authNumber) {
      setMessage(t("AuthFail"));
      setModal(true);
    } else {
      setMessage(t("AuthTimerFail"));
      setModal(true);
    }
  };

  return (
    <div className="my-page-info-form-box">
      <div className="common-input-box">
        <p>{t("Email")}</p>
        <input type="text" value={user.email} readOnly />
      </div>

      {user.joinPath === "email" && (
        <>
          <div className="common-input-box">
            <p>{t("Password")}</p>
            <div className="common-input-button-box flex">
              <input type="password" value="********" readOnly />
              <button
                onClick={() => {
                  setChangeInfo({ ...changeInfo, changePassword: true });
                  setIsLeave({ ...isLeave, change: true });
                }}
              >
                {t("Change-Password")}
              </button>
            </div>
          </div>

          {changeInfo.changePassword && (
            <>
              <div
                className={
                  changeInfo.conditionPassword
                    ? "common-input-box"
                    : "common-input-box sign-up-red-line"
                }
              >
                <p>
                  {t("New")}&nbsp;{t("Password")}
                </p>
                <input
                  type="password"
                  placeholder={t("PasswordIn")}
                  maxLength="20"
                  value={changeInfo.password}
                  onChange={(e) =>
                    setChangeInfo({
                      ...changeInfo,
                      password: e.target.value,
                      conditionPassword: checkPassword(e.target.value),
                    })
                  }
                />
              </div>

              <div
                className={
                  changeInfo.checkPassword === "" ||
                  changeInfo.password === changeInfo.checkPassword
                    ? "common-input-box"
                    : "common-input-box sign-up-red-line"
                }
              >
                <p>{t("PasswordCheck")}</p>
                <input
                  type="password"
                  placeholder={t("PasswordCheck")}
                  value={changeInfo.checkPassword}
                  maxLength="20"
                  onChange={(e) => {
                    setChangeInfo({
                      ...changeInfo,
                      checkPassword: e.target.value,
                      conditionPassword: checkPassword(e.target.value),
                    });
                  }}
                />
              </div>
            </>
          )}
        </>
      )}

      <div className="common-input-box">
        <p>{t("Name")}</p>
        <div className="common-input-button-box flex">
          {changeInfo.changeName ? (
            <input
              type="text"
              value={changeInfo.name}
              placeholder={t("NameIn")}
              maxLength="20"
              onChange={(e) =>
                setChangeInfo({
                  ...changeInfo,
                  name: removeSpecialSymbol(e.target.value),
                })
              }
            />
          ) : (
            <input type="text" value={user.name} maxLength="50" readOnly />
          )}
          <button
            onClick={(e) => {
              setChangeInfo({ ...changeInfo, changeName: true });
              setIsLeave({ ...isLeave, change: true });
            }}
          >
            {t("ModifyQnA")}
          </button>
        </div>
      </div>

      <div className="common-input-box">
        <p>{t("NickName")}</p>
        <div className="common-input-button-box flex">
          {changeInfo.changeNick ? (
            <input
              type="text"
              value={changeInfo.nickname}
              placeholder={t("NickIn")}
              maxLength="20"
              onChange={(e) =>
                setChangeInfo({
                  ...changeInfo,
                  nickname: removeSpecialSymbol(e.target.value),
                })
              }
            />
          ) : (
            <input type="text" value={user.nickname} maxLength="50" readOnly />
          )}
          <button
            onClick={(e) => {
              setChangeInfo({ ...changeInfo, changeNick: true });
              setIsLeave({ ...isLeave, change: true });
            }}
          >
            {t("ModifyQnA")}
          </button>
        </div>
      </div>

      <div className="common-input-box">
        <p>{t("PhoneNo")}</p>
        <div className="common-input-button-box flex">
          <input
            type="text"
            value={user.phone !== null ? phoneHyphen(user.phone) : ""}
            readOnly
          />
          <button
            onClick={() => {
              setChangeInfo({ ...changeInfo, changePhone: true });
              setIsLeave({ ...isLeave, change: true });
            }}
          >
            {t("Change-Phone")}
          </button>
        </div>
      </div>

      {changeInfo.changePhone && (
        <>
          <div className="common-input-box">
            <div className="common-input-button-box flex">
              <input
                type="text"
                placeholder={t("PhoneNoIn")}
                maxLength="11"
                value={changeInfo.phone}
                onChange={(e) =>
                  setChangeInfo({
                    ...changeInfo,
                    phone: onlyNumber(e.target.value),
                  })
                }
              />
              {startTimer && (
                <Timer
                  mm="3"
                  ss="00"
                  setExpire={setExpire}
                  resetTimer={resetTimer}
                />
              )}
              {showRepeatBtn ? (
                <button
                  onClick={sendCertiNumberRepeat}
                  disabled={repeatCerti ? true : false}
                >
                  {t("SendAuthRe")}
                </button>
              ) : (
                <button onClick={sendCertiNumber}>{t("SendAuth")}</button>
              )}
            </div>
          </div>

          <div className="common-input-box">
            <div className="common-input-button-box flex">
              <input
                type="text"
                placeholder={t("AuthIn")}
                maxLength="6"
                value={changeInfo.certiNumber}
                onChange={(e) =>
                  setChangeInfo({
                    ...changeInfo,
                    certiNumber: onlyNumber(e.target.value),
                  })
                }
              />

              <button onClick={checkCertiNumber}>{t("Ok")}</button>
            </div>
          </div>
        </>
      )}

      {modal && (
        <Modal>
          <Alert msg={message} setShowModal={setModal} />
        </Modal>
      )}
    </div>
  );
}

export default InfoForm;
