import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Prompt, withRouter } from "react-router-dom";

import InfoForm from "./InfoForm";
import Modal from "../../../common/Modal";
import Alert from "../../../common/AlertModal";
import Confirm from "../../../common/ConfirmModal";

import { userUpdaate } from "../../../services/userService";

function MyInfo({ history, user }) {
  const { t } = useTranslation();
  // 수정하기 버튼 누르고 수정안하고 페이지 이탈 제어
  const [isLeave, setIsLeave] = useState({
    leave: false,
    change: false,
    next: "",
  });
  const [modal, setModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [message, setMessage] = useState("");
  const [changeInfo, setChangeInfo] = useState({
    changePassword: false,
    password: "", // 변경할 비밀번호
    checkPassword: "",
    conditionPassword: true, // 비밀번호 규칙
    changeName: false,
    name: "", // 변경할 이름
    changeNick: false,
    nickname: "",
    changePhone: false,
    phone: "", // 변경할 핸드폰번호
    certiNumber: "",
    certi: false,
  });

  const handleChangeInfo = () => {
    if (
      !changeInfo.changePassword &&
      !changeInfo.changeName &&
      !changeInfo.changePhone &&
      !changeInfo.changeNick
    ) {
      setMessage(t("No-Change"));
      setModal(true);
      return;
    }
    if (changeInfo.changePassword) {
      if (changeInfo.password === "" && changeInfo.checkPassword === "") {
        setMessage(t("PasswordAlert"));
        setModal(true);
        return;
      }
      if (!changeInfo.conditionPassword) {
        setMessage(t("PasswordAlert"));
        setModal(true);
        return;
      } else if (changeInfo.password !== changeInfo.checkPassword) {
        setMessage(t("PasswordCheckMsg"));
        setModal(true);
        return;
      }
    }
    if (changeInfo.changeName) {
      if (changeInfo.name === "") {
        setMessage(t("NameIn"));
        setModal(true);
        return;
      }
    }
    if (changeInfo.changeNick) {
      if (changeInfo.nickname === "") {
        setMessage(t("NickIn"));
        setModal(true);
        return;
      }
    }
    if (changeInfo.changePhone) {
      if (changeInfo.phone !== "") {
        if (!changeInfo.certi) {
          setMessage(t("PhoneAuthCheck"));
          setModal(true);
          return;
        }
      } else {
        setMessage(t("PhoneNoIn"));
        setModal(true);
        return;
      }
    }
    userUpdaate({
      userNo: user.no,
      userEmail: user.userEmail,
      password2: changeInfo.password,
      nickNm: changeInfo.nickname,
      phoneNo: changeInfo.phone,
      userNm: changeInfo.name,
      profileUrl: "",
      pushYn: "true",
      language: "",
      countryCd: "",
      joinPath: "",
    }).then((res) => {
      setIsLeave(false);
      setMessage(t("ChangeSuccess"));
      setModal(true);
    });
  };

  const handlePrompt = (location) => {
    if (!isLeave.leave && isLeave.change) {
      setIsLeave({
        ...isLeave,
        next: location.pathname,
      });
      setConfirmModal(true);
      return false;
    }
    return true;
  };

  const goToLeave = () => {
    setIsLeave({
      ...isLeave,
      leave: true,
    });
  };

  useEffect(() => {
    if (isLeave.leave) {
      setIsLeave({
        ...isLeave,
        change: false,
      });
      return history.push(isLeave.next);
    }
  }, [isLeave, history]);

  useEffect(() => {
    if (!modal) {
      if (message === t("ChangeSuccess")) {
        window.location.reload();
      }
    }
  }, [modal]);

  // 핸드폰 번호 입력 창 변경시 인증 확인 값 false 처리
  useEffect(() => {
    if (changeInfo.certi) {
      setChangeInfo({
        ...changeInfo,
        certi: false,
      });
    }
  }, [changeInfo.phone]);

  return (
    <div className="white my-page-box">
      <div className="my-page-title">
        <p>{t("My-Page")}</p>
      </div>

      <InfoForm
        changeInfo={changeInfo}
        setChangeInfo={setChangeInfo}
        user={user}
        isLeave={isLeave}
        setIsLeave={setIsLeave}
      />

      <button className="my-info-ok-btn dark-blue" onClick={handleChangeInfo}>
        {t("ModifyMyPage")}
      </button>

      {modal && (
        <Modal>
          <Alert msg={message} setShowModal={setModal} />
        </Modal>
      )}

      <Prompt when={isLeave.change} message={handlePrompt} />
      {confirmModal && (
        <Modal>
          <Confirm
            msg={t("LeaveMyPage")}
            setShowModal={setConfirmModal}
            setResult={goToLeave}
          />
        </Modal>
      )}
    </div>
  );
}

export default withRouter(MyInfo);
