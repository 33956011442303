import { server } from "../config/config";
import axios from "axios";

let currentToken = "";
let user = JSON.parse(localStorage.getItem("ziple_user"));

if (user !== null) {
  currentToken = user.token;
}

//서버연결
const http = axios.create({
  baseURL: server,
});

// 401 에러 발생시 == user 정보 없을 시, 로그인 페이지로 이동
http.interceptors.response.use(
  (res) => {
    return res;
  },
  (e) => {
    if (e.response.status === 401) {
      localStorage.removeItem("ziple_user");
      window.location = "/";
    } else {
      return e;
    }
  }
);

// 마이페이지 유저정보
export const userSelect = async (userNo) => {
  try {
    return await http
      .get(`/api/user?userNo=${userNo}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentToken,
        },
      })
      .then((res) => res.data);
  } catch (e) {
    console.log(e);
  }
};

// 마이페이지 유저정보 수정
export const userUpdaate = async (form) => {
  try {
    return await http
      .put(`/api/user`, form, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentToken,
        },
      })
      .then((res) => res.data);
  } catch (e) {
    console.log(e);
  }
};

// 마이페이지 북마크 리스트 (delegate_like 테이블 : 대리인)
export const bookMarkDelegate = async (form) => {
  try {
    return await http
      .get("/myDelegateList", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentToken,
        },
        params: form,
      })
      .then((res) => res.data);
  } catch (e) {}
};

// 마이페이지 북마크 리스트 (info 테이블 : 정보라운지 뉴스 기사)
export const bookMarkInfo = async (form) => {
  try {
    return await http
      .get("/infoMypageList", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentToken,
        },
        params: form,
      })
      .then((res) => res.data);
  } catch (e) {}
};

export const getBookMarkInfo = async (form) => {
  try {
    return await http
      .get("/my/info", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentToken,
        },
        params: form,
      })
      .then((res) => res.data);
  } catch (e) {}
};

// 마이페이지 질의응답 리스트
export const myQuestionList = async (form) => {
  try {
    return await http
      .get("/qna/list", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentToken,
        },
        params: form,
      })
      .then((res) => res.data);
  } catch (e) {}
};

// 마이페이지 상담예약 리스트 
export const myRezList = async (form) => {
  try {
    return await http
      .get("/rezInfo/list", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentToken,
        },
        params: form,
      })
      .then((res) => res.data);
  } catch (e) {}
};