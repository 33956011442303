import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { getQnaList } from "../../../services/qnaService";
import { formatDate, choiceRandomColor } from "../../../common/util";
import icon from "../../../images/alarm.png";
import {formatDotDate} from '../../../common/util';
import Profile from "../../../images/profile.png";

function Question() {
  const { t } = useTranslation();
  const [qnaList, setQnaList] = useState([]);

  useEffect(() => {
    getQnaList({
      title: "",
      userNm: "",
      userNo: "",
      pageNo: 1,
      content: "",
      ideaDiv: "Y",
      pageSize: "3",
      sortDbField: "reg_date",
      sortDirection: "desc",
    }).then((res) => {
      setQnaList(res.lists);
      console.log(res);
    });
  }, []);

  const pageMove = (no) => {
    window.location.href = "/question/detail/" + no;
  };

  return (
    <div className="main-question">
      <div className="flex">
        <img src={icon} alt="search" style={{ marginRight: 10 }} />

        <p className="main-question-title">{t("Main-QnA")}</p>
        {/* <div
          className="main-view-more flex"
          onClick={() => {
            window.location.href = "/question/list";
          }}
        >
          <p>{t("View-More")}</p>
          <img src={Icon} />
        </div> */}
      </div>

      <div className="flex">
        {qnaList.map((qna, index) => (
          <div
            className="main-question-wrap"
            key={index}
            onClick={() => {
              pageMove(qna.qnaNo);
            }}
          >
            <div className="main-qna-title">{qna.title}</div>
            <div className="flex">
              <img
                src={icon}
                alt="icon"
                style={{ width: 35, height: 35 }}
              />
              <div className="main-qna-question">
                
                <span>{qna.userNm.substr(0, 1)}** | {formatDotDate(qna.regDate)} </span>
                <div className="main-qna-content">{qna.content}</div>
              </div>
            </div>

            <div className="flex main-qna-reply">
              {qna.replyProfileUrl != null && (
                <>
                 <div className="main-qna-reply-wrap">
                   <span>
                   {qna.replyContent !== null && qna.replyContent}
                   </span>
                </div>
                  {qna.replyProfileUrl.indexOf("gravatar") === -1 ? (
                    <img
                      className="profile-image2"
                      src={qna.replyProfileUrl}
                      alt="유저 프로필"
                      onError={(e) => {
                        e.target.onError = null;
                        e.target.src = Profile;
                      }}
                    />
                  ) : (
                    <div
                      className="qna-color-profile2"
                      style={{ background: choiceRandomColor(qna.replyUserNo) }}
                    >
                      {qna.replyNickNm.substr(0, 1)}**
                    </div>
                  )}
                </>
              )}
             
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Question;
