import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

import { FaceBookAppId, KAKAO_JS_KEY } from "../../config/config";

import "../../css/login/Login.css";

import Modal from "../../common/Modal";
import Alert from "../../common/AlertModal";
import SnsEmail from "./component/FacebookEmailModal";
import Button from "./component/Button";

import Logo from "../../images/logo.png";
import Back from "../../images/back.png";
import Email from "../../images/Email_d.png";
import Password from "../../images/Lock_2.png";

import { unBlank } from "../../common/util";
import {
  userLogin,
  loginFacebook,
  FacebookId,
  kakaoLogin,
} from "../../services/loginService";

function Login({ history }) {
  const { t } = useTranslation();
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [isFail, setIsFail] = useState(false);
  const [data, setData] = useState("");
  const [joinPath, setJoinPath] = useState("");
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [failMsg, setFailMsg] = useState("");
  const [message, SetMessage] = useState("");
  let user = localStorage.getItem("ziple_user");

  useEffect(() => {
    document.getElementById("inputEmail").focus();
  }, [user]);

  // 이메일 로그인
  const LoginHandle = async () => {
    if (userEmail === undefined || userEmail === "" || userEmail === null) {
      setIsFail(true);
      setFailMsg(t("LoginCheck1"));
    } else if (
      userPassword === undefined ||
      userPassword === "" ||
      userPassword == null
    ) {
      setIsFail(true);
      setFailMsg(t("LoginCheck1"));
    } else {
      await userLogin({
        userEmail: userEmail,
        password: userPassword,
      }).then((res) => {
        // window.gtag("event", "로그인페이지 로그인버튼 클릭", {
        //   eventCategory: "로그인",
        //   eventLabel: "로그인",
        //   eventAction: "로그인버튼 클릭",
        //   eventValue: 1,
        // });
        if (res.resCd === "0000") {
          localStorage.setItem(
            "ziple_user",
            JSON.stringify({
              token: res.Token,
              userNo: res.resData.userNo,
            })
          );
          window.location.href = "/";
        } else if (res.resCd === "9993") {
          setIsFail(true);
          setFailMsg(t("LoginCheck2"));
        } else if (res.resCd === "7777") {
          localStorage.setItem(
            "ziple_user",
            JSON.stringify({
              userNo: res.resData,
            })
          );
          window.location.href = "/inactive";
        } else if (res.resCd === "6666") {
          setIsFail(true);
          setFailMsg(t("LoginCheck1"));
        } else {
          setIsFail(true);
          setFailMsg(t("LoginCheck1"));
        }
      });
    }
  };

  // 로그인 버튼 클릭
  const clickEvent = (type) => {
    if (type === "simple") {
      LoginHandle();
    } else if (type === "kakao") {
      window.Kakao.init(KAKAO_JS_KEY);
      window.Kakao.Auth.loginForm({
        success: function (authObj) {
          window.Kakao.API.request({
            url: "/v2/user/me",
            success: function (res) {
              kakaoSucessHanler(authObj, res);
            },
            fail: function (error) {
              console.log(JSON.stringify(error));
            },
          });
        },
        fail: function (err) {
          console.log(JSON.stringify(err));
        },
      });
    } else {
      //facebook
    }
  };

  const responseFacebook = (response) => {
    let accessToken = response.accessToken;
    setData(response);
    setJoinPath("facebook");
    if (response.email != null) {
      //페이스북 로그인
      ServiceFaceBook(response.email, accessToken, response);
    } else {
      // 이메일 빈 경우 페이스북 아이디값으로 현재 있는 이메일 정보 가져옴
      FacebookId(response.id).then((res) => {
        if (res.resCd === "0000") {
          ServiceFaceBook(res.resEmail, accessToken, response);
        } else {
          //없는 경우에는 이메일 입력
          setModal2(true);
        }
      });
    }
  };

  const ServiceFaceBook = async (email, accessToken, profile) => {
    let userData = {
      userEmail: email, //ID값을 userEmail로 사용
      password: accessToken, //(토큰을 임시로 패스워드로 사용
      userNm: profile.name,
      nickNm: profile.name,
      authYn: "Y",
      profileUrl: profile.picture.data.url,
      userDivCd: "1",
      pushYn: "Y",
      languageCd: "KR",
      countryCd: "KR",
      userStatusCd: "1",
      joinPath: "facebook",
      deviceId: "",
      deviceOs: "",
      osVersion: "",
      deviceNm: "",
      facebookId: profile.id,
      signupCd: "web",
    };
    await loginFacebook(userData).then((res) => {
      if (res.resCd === "0000" || res.resCd === "0001") {
        localStorage.setItem(
          "ziple_user",
          JSON.stringify({
            token: res.Token,
            userNo: res.resData.userNo,
          })
        );
        window.location.href = "/";
      } else {
      }
    });
  };

  const kakaoSucessHanler = (authObj, res) => {
    //회원정보 가져옴
    let email = res.kakao_account.email;
    let nickNm = res.properties.nickname;
    let profileimg = res.properties.profile_image;
    let facebookId = res.id;
    let password = authObj.access_token;
    setData(res);
    setJoinPath("kakao");

    if (res.kakao_account.email === undefined) {
      // 이메일 빈 경우
      //소셜 아이디값으로 현재 있는 이메일 정보 가져옴
      FacebookId(res.id).then((response) => {
        if (response.resCd === "0000") {
          kakaoSubmit(
            response.resEmail,
            password,
            nickNm,
            profileimg,
            facebookId
          );
        } else {
          //없는 경우에는 이메일 입력
          setModal2(true);
        }
      });
    } else {
      kakaoSubmit(email, password, nickNm, profileimg, facebookId);
    }
  };

  const kakaoSubmit = async (
    email,
    password,
    nickNm,
    profileimg,
    facebookId
  ) => {
    let userForm = {
      userEmail: email,
      password: password,
      userNm: nickNm,
      nickNm: nickNm,
      authYn: "Y",
      profileUrl: profileimg,
      userDivCd: "1",
      pushYn: "Y",
      languageCd: "KR",
      countryCd: "KR",
      userStatusCd: "1",
      joinPath: "kakao",
      deviceId: "",
      deviceOs: "",
      osVersion: "",
      deviceNm: "",
      facebookId: facebookId,
      signupCd: "web",
    };
    await kakaoLogin(userForm).then((res) => {
      if (res.resCd === "0000" || res.resCd === "0001") {
        localStorage.setItem(
          "ziple_user",
          JSON.stringify({
            token: res.Token,
            userNo: res.resData.userNo,
          })
        );
        window.location.href = "/";
      } else {
        SetMessage(res.resMsg);
        setModal(true);
      }
      // else if () {
      //   setShowModal2(true);
      //   SetMessage('이미 사용중인 이메일입니다. 이메일로 회원가입해주세요 ');
      // }
    });
  };
  const enterKey = () =>{
    if (window.event.keyCode === 13) {
 
      // 엔터키가 눌렸을 때 실행할 내용
      LoginHandle();
  }
  } 
  

  //모달창 확인버튼 클릭시
  const handleResult = (val) => {
    if (joinPath === "kakao") {
      let email = val;
      let nickNm = data.properties.nickname;
      let profileimg = data.properties.profile_image;
      let facebookId = data.id;
      let password = data.id;
      kakaoSubmit(email, password, nickNm, profileimg, facebookId);
    } else {
      ServiceFaceBook(val, data.accessToken, data);
    }
  };

  return (
    <>
      <div className="grey full-cover login-padding">
        <div>
          <img
            src={Logo}
            alt="ziple_logo"
            className="block logo-image"
            onClick={() => history.push("/")}
          />

          <div className="white sign-box">
            <div className="login-box">
              <div className="flex login-title">
                <div className="back-box" onClick={() => history.push("/")}>
                  <img src={Back} alt="back_btn" className="back-image" />
                </div>
                <p>{t("Login")}</p>
              </div>

              <div className="flex login-input-box">
                <img src={Email} alt="email" />
                <input
                  type="text"
                  id="inputEmail"
                  placeholder={t("EmailIn")}
                  value={userEmail}
                  onChange={(e) => {
                    setUserEmail(unBlank(e.target.value));
                    setIsFail(false);
                  }}
                />
              </div>

              <div className="flex login-input-box">
                <img src={Password} alt="password" />
                <input
                  type="password"
                  value={userPassword}
                  placeholder={t("LoginPassword")}
                  onKeyUp={()=>enterKey()}
                  onChange={(e) => {
                    setUserPassword(e.target.value);
                    setIsFail(false);
                  }}
                />
              </div>
              {isFail && <p className="login-alert-msg">{failMsg}</p>}

              <Button type="simple" msg={t("Login")} change={clickEvent} />
              <Button type="kakao" msg={t("KakaoLogin")} change={clickEvent} />
              <FacebookLogin
                appId={FaceBookAppId}
                autoLoad={false}
                fields="name,email,picture"
                callback={responseFacebook}
                disableMobileRedirect={true}
                render={(renderProps) => (
                  <Button
                    type="facebook"
                    msg={t("FacebookLogin")}
                    onClick={renderProps.onClick}
                    change={renderProps.onClick}
                  />
                  //     <Button variant="info" onClick={renderProps.onClick} className="faceBtn mb-3">
                  //       <FaFacebook className="mr-2 faceIcon" />
                  //       {t("FacebookLogin")}
                  //     </Button>
                )}
              />
              {/* <Button type="apple" msg="애플 로그인" change={clickEvent} /> */}

              <div className="login-footer flex">
                <p>
                 
                  <Link to="/sign-up" onClick={() => window.scrollTo(0, 0)}>
                    {t("Sign")}
                  </Link>
                  
                </p>
                <p>
                  <Link to="/find" onClick={() => window.scrollTo(0, 0)}>
                    {t("PasswordChg")}
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {modal2 && (
        <Modal>
          <SnsEmail handleResult2={handleResult} setModal2={setModal2} />
        </Modal>
      )}

      {modal && (
        <Modal>
          <Alert msg={message} setShowModal={setModal} />
        </Modal>
      )}
    </>
  );
}

export default withRouter(Login);
