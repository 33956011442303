import React, { useEffect } from "react";

import "../css/common/Modal.css";

const Modal = ({ children }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
  }, []);
  useEffect(() => {
    return () => {
      document.body.style.overflow = "unset";
    };
  });
  return (
    <div>
      <div className="modal-container">{children}</div>
      <div className="modal-background" ></div>
    </div>
  );
};

export default Modal;
