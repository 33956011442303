import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  delegateInfo,
  getDelegateClick,
  delegateLikeInsert,
} from "../../services/delegateService";
import { userSelect } from "../../services/userService";
import { delegateBtnNm, getProfessional } from "../../common/util";

import "../../css/delegate/Detail.css";
import "../../css/delegate/Delegate.css";
import phoneWhite from "../../images/Phone_White.png";

import share from "../../images/share_white.png";
import bookmarkOn from "../../images/bookmark2_white.png";
import bookmarkOff from "../../images/bookmark_white.png";
import phone from "../../images/phone.png";
import profile from "../../images/profile.png";
import Contact from "./components/Contact";
import InfoItem from "./components/InfoItem";
import Modal from "../../common/Modal";
import Alert from "../../common/AlertModal";
import ShareButtons from "../../common/component/ShareButton";
import { withRouter } from "react-router-dom";
function Detail({ match, history }) {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [message, setMessage] = useState("");
  const [user, setUser] = useState({});
  const [detail, setDetail] = useState(""); // 대리인 정보
  const [pastRecord, setPastRecord] = useState([]); // 최근 이력
  const [profieldUrl, setProfieldUrl] = useState([]);
  const [fieldBackground, setFieldBackground] = useState([]);
  const [field, setField] = useState([]);
  let userInfo = JSON.parse(localStorage.getItem("ziple_user"));
  let lang = localStorage.getItem("language");
  let no = history.location.pathname.substr(
    history.location.pathname.lastIndexOf("/") + 1
  );
  //let no = history.location.pathname.substr(0,history.location.pathname.lastIndex('/'));
  const getUserInfo = async () => {
    try {
      await userSelect(userInfo.userNo).then((res) => {
        setUser({
          no: userInfo.userNo,
          email: res.userEmail,
          name: res.userNm,
          phone: res.phoneNo,
          nickname: res.nickNm,
        });
      });
    } catch (e) {}
  };

  useEffect(() => {
    if (userInfo != null) {
      getUserInfo();
    }
  }, []);
  // 대리인 정보 가져오기
  const getDelegateInfo = () => {
    delegateInfo({
      adNo: no,
      userNo: userInfo !== null ? userInfo.userNo : 1,
    }).then((res) => {
      setDetail(res.data);
      // if(res.data.adGrade === '1'){
      //     history.push('/info')
      // }
      if (res.data.delegateIPMandate !== null) {
        setPastRecord(res.data.delegateIPMandate.split("\r\n"));
      }
      if (res.data.professionalField !== null) {
        if (lang === "ko") {
          const parse = getProfessional(
            res.data.professionalField,
            res.data.delegateIPMandate
          );
          setFieldBackground(parse.backgroundColor);
          setProfieldUrl(parse.flagUrls);
          setField(parse.field);
        } else {
          const parse = getProfessional(
            res.data.professionalFieldEn,
            res.data.delegateIPMandate
          );
          setFieldBackground(parse.backgroundColor);
          setProfieldUrl(parse.flagUrls);
          setField(parse.field);
        }
      }
      if (res.data.companyAddr !== null) {
        getKakaoMap(res.data.companyAddrX, res.data.companyAddrY);
      }
    });
  };

  // 지도 정보 가져오기
  const getKakaoMap = (x, y) => {
    const script = document.createElement("script");
    script.async = true;
    script.src =
      "https://dapi.kakao.com/v2/maps/sdk.js?appkey=0edc8d4474271811c9a295f09920b1e5&libraries=services&autoload=false";
    document.head.appendChild(script);

    script.onload = () => {
      window.kakao.maps.load(() => {
        let el = document.getElementById("MyMap");
        let map = new window.kakao.maps.Map(el, {
          center: new window.kakao.maps.LatLng(x, y),
          level: 3,
        });
        // 마커를 생성합니다
        var marker = new window.kakao.maps.Marker({
          position: new window.kakao.maps.LatLng(x, y),
        });
        // map.setZoomable(false);
        // map.setDraggable(false);
        // 마커가 지도 위에 표시되도록 설정합니다
        marker.setMap(map);
      });
    };
  };

  const delegateClick = (div, value, adNo, adNm) => {
    let form = {
      userNo: userInfo !== null ? userInfo.userNo : 0,
      action: div,
      no: adNo,
      cateDiv: "brand",
    };

    // 사무실 전화
    if (div === 13 || div === 1) {
      getDelegateClick(form).then((res) => {
        if (res.resCd === "0000") {
          window.location.href = "tel:" + value;
        }
      });
    }

    // 북마크
    if (div === 9) {
      if (userInfo === null) {
        setMessage(t("LoginErrorMsg"));
        setModal(true);
      } else {
        getDelegateClick(form).then((res) => {
          if (res.resCd === "0000") {
            delegateLikeInsert(
              adNo,
              userInfo.userNo,
              detail.likeYn === null || detail.likeYn === "N" ? "Y" : "N"
            ).then((res) => {
              if (res.resCd === "0000") {
                getDelegateInfo();
              }
            });
          }
        });
      }
    }

    // 지도보기 버튼 클릭
    if (div === 10) {
      getDelegateClick(form).then((res) => {
        if (res.resCd === "0000") {
          if (value != null) {
            window.location.href = "https://map.kakao.com/?q=" + value;
          }
        }
      });
    }

    window.gtag(
      "event",
      adNm + "대리인 " + delegateBtnNm(div.toString()) + " 버튼 클릭",
      {
        eventCategory: div,
        eventLabel:
          adNm + "대리인 " + delegateBtnNm(div.toString()) + " 버튼 클릭",
        eventAction: div + "버튼 클릭",
        eventValue: 1,
      }
    );
  };

  // 대리인 공유하기
  const shareMenu = (content, subject, div, value, adNo, adNm) => {
    // if (userInfo === null) {
    //   setMessage(t("LoginErrorMsg"));
    //   setModal(true);
    // } else {
    delegateClick(div, value, adNo, adNm);
    setMessage(t("Share"));
    setModal2(true);
    // }
    // socialSharing.share(content, subject, null, value);
  };

  useEffect(() => {
    getDelegateInfo();
  }, []);

  useEffect(() => {
    if (!modal) {
      if (message === t("LoginErrorMsg")) {
        window.location.href = "/login";
      }
    }
  }, [modal]);

  const handleImgError= (e)=>{
    e.target.src = profile
  }
  return (
    <>
      <div className="delegate-detail flex">
        <div className="delegate-detail-profile">
          <img src={detail.profileUrl} alt="profile" onError={handleImgError} />
          <div className="delegate-detail-profile-info flex">
            <div className="delegate-profile-userNm">
              {detail.regUserNm} &nbsp;
              {detail.delegateDiv === "1" ? (
                <span>{t("Attorney")}</span>
              ) : (
                <span>{t("Lawyer")}</span>
              )}
            </div>
            <div className="delegate-profile-share">
              <span
                onClick={() =>
                  delegateClick(9, detail.likeYn, detail.adNo, detail.regUserNm)
                }
              >
                {detail.likeYn === "Y" &&
                detail.likeYn !== null &&
                userInfo != null ? (
                  <img src={bookmarkOn} className="bookmark" alt="bookmarkOn" />
                ) : (
                  <img
                    src={bookmarkOff}
                    className="bookmark"
                    alt="bookmarkOff"
                  />
                )}
              </span>
              <span
                onClick={() =>
                  shareMenu(
                    detail.contnets,
                    detail.subject,
                    11,
                    detail.shareUrl,
                    detail.adNo,
                    detail.regUserNm
                  )
                }
              >
                <img src={share} className="bookmark2" alt="share" />
              </span>
              <span
                onClick={() => {
                  delegateClick(
                    1,
                    detail.PhoneNo,
                    detail.adNo,
                    detail.regUserNm
                  );
                }}
              >
                <img src={phoneWhite} className="phone" alt="share" />
              </span>
            </div>
          </div>
          <div>
          <div style={{ height:'50px', display:"flex",cursor:'pointer' }}>
            <div className="delegate-ad-detail"  style={{backgroundColor:"#0E5EC7", width:'100%', color:'#fff'}} onClick={() => {
                  window.location.href = "/reservation/" + detail.userNo;
                  window.scrollTo(0, 0);
                }}><b>상담 예약 하기</b></div>
            
          </div>
          </div>
        </div>
        <div className="delegate-detail-info">
          <div className="delgate-detail-title">
            {lang === "ko" ? detail.subject : detail.subjectEn}
          </div>
          {/* 변리사 정보 */}
          <div className="delegate-info-div flex">
            <div className="delegate-info-div-detail">
              {detail.adGrade === "1" ? (
                <div className="delegate-adGrade-member">MEMBER</div>
              ) : (
                <div className="delegate-adGrade-premium">PREMIUM</div>
              )}
              <p>
                {detail.regUserNm}&nbsp;
                {detail.delegateDiv === "1" ? (
                  <span>{t("Attorney")}</span>
                ) : (
                  <span>{t("Lawyer")}</span>
                )}
              </p>
              <div>{detail.companyNm}</div>
              <div>{detail.companyAddr}</div>
              {detail.companyPhone !== null && (
                <div>
                  <img
                    src={phone}
                    alt="phone"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      delegateClick(
                        13,
                        detail.companyPhone,
                        detail.adNo,
                        detail.regUserNm
                      )
                    }
                  />
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      delegateClick(
                        13,
                        detail.companyPhone,
                        detail.adNo,
                        detail.regUserNm
                      )
                    }
                  >
                    {detail.companyPhone}
                  </span>
                </div>
              )}
            </div>
            <Contact detail={detail} />
          </div>
          <div className="delegate-info-div">
            <div className="delegate-info-div-title">{t("Division")}</div>
            <div className="flex delegate-detail-field-wrap">
              <div className="flex">
                {profieldUrl.map((img, k) => (
                  <div className="delegate-detail-cate" key={k} >
                  {/* <img src={img} alt="img" /> */}
                  <div style={{background:fieldBackground[k], padding:10}} >{field[k]}</div>
                </div>
                ))}
              </div>
            </div>
          </div>

          <InfoItem title={"Mandate"} content={pastRecord} />
          <InfoItem title={"Record"} content={detail.delegateRecord} />
          <InfoItem title={"Career"} content={detail.delegateCareer} />
          <InfoItem title={"Other"} content={detail.delegateOther} />
          <InfoItem
            title={"Introduce"}
            content={lang === "ko" ? detail.contents : detail.contentsEn}
          />

          <div className="delegate-info-div">
            <div className="delegate-info-div-title">{t("ComAddr")}</div>
            <div className="delegate-info-map-content">
              <b>{detail.companyNm}</b>
            </div>
            <div className="flex delegate-detail-map-click">
              <div className="delegate-info-map-content">
                {detail.companyAddr}{" "}
              </div>
              {detail.companyAddrX !== null && detail.companyAddrY !== null && (
                <div
                  className="delegate-info-map-content"
                  onClick={() =>
                    delegateClick(
                      10,
                      detail.companyAddr,
                      detail.adNo,
                      detail.regUserNm
                    )
                  }
                >
                  {t("MapView")}
                </div>
              )}
            </div>
            {detail.companyAddr != null && (
              <div>
                <div id="MyMap"></div>
              </div>
            )}
          </div>
        </div>
      </div>

      {modal && (
        <Modal>
          <Alert msg={message} setShowModal={setModal} />
        </Modal>
      )}
      {modal2 && (
        <Modal>
          <ShareButtons
            msg={message}
            setShowModal2={setModal2}
            link={detail.shareUrl}
            phoneNo={detail.phoneNo}
            content={
              detail.regUserNm +
              "%0d%0a" +
              detail.phoneNo +
              "%0d%0a" +
              detail.subject +
              "%0d%0a" +
              detail.contents
            }
            user={user}
          >
            {" "}
          </ShareButtons>
        </Modal>
      )}
    </>
  );
}

export default withRouter(Detail);
