import React from "react";
import AppleLogin from "react-apple-login";

import KaKao from "../../../images/Kakao.png";
import FaceBook from "../../../images/Facebook.png";
import Apple from "../../../images/Apple.png";

import "../../../css/login/Login.css";

import { server } from "../../../config/config";

function Button({ type, msg, change }) {
  const clickEvent = () => {
    change(type);
  };

  const appleLoginCallback = (res) => {
    // console.log(res);
  };

  return (
    <>
      {type === "simple" && (
        <button className="login-btn2" onClick={clickEvent}>
          {msg}
        </button>
      )}

      {type === "kakao" && (
        <button onClick={clickEvent} className="login-btn-social yellow flex">
          <img src={KaKao} alt="social" className="kakao-image" />
          <p>{msg}</p>
        </button>
      )}

      {type === "facebook" && (
        <button onClick={clickEvent} className="login-btn-social blue flex">
          <img src={FaceBook} alt="social" className="facebook-image" />
          <p>{msg}</p>
        </button>
      )}

      {type === "apple" && (
        <AppleLogin
          clientId={"biz.zipleweb"}
          redirectURI={"https://www.ziple.biz/login"}
          responseType={"id_token"}
          responseMode={"form_post"}
          scope={"email"}
          usePopup={true}
          render={(renderProps) => (
            <button
              className="login-btn black flex"
              onClick={renderProps.onClick}
            >
              <img src={Apple} alt="social" className="kakao-image" />
              <p>{msg}</p>
            </button>
          )}
          callback={appleLoginCallback}
          // designProp={{
          //   height: 30,
          //   // width: 100,
          //   color: "black",
          //   border: false,
          //   type: "sign-in",
          //   border_radius: 10,
          //   scale: 2,
          //   locale: "ko_KR",
          // }}
        />
      )}
    </>
  );
}

export default Button;
