import React from "react";
import { useTranslation } from "react-i18next";

function SubButton({ setAction, type }) {
  const { t } = useTranslation();

  return (
    <div className="sub-menu-box">
      <div onClick={() => setAction(type + "수정")}>{t("Modify")}</div>
      <div onClick={() => setAction(type + "삭제")}>{t("Delete")}</div>
    </div>
  );
}

export default SubButton;
