import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "../../css/question/Question.css";

import List from "./component/List";
import Write from "./component/Write";
import Detail from "./component/Detail";
import Modify from "./component/Modify";
import QnaBanner from "../../images/banner_question.png";
function Question() {
  const { t } = useTranslation();
  const path = useLocation().pathname.split("/");

  useEffect(() => {
    var location = document.querySelector(".question-wrap");
    var loc = document.querySelector(".question-top-notice");
    if (location.offsetTop != null) {
      if(path[2] === "list"){
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 100);
      }else{
        setTimeout(() => {
          window.scrollTo(0, location.offsetTop);
        }, 100);
      }
      
    }
  });

  return (
    <div>
      {/* <div className="question-top-notice">
        <p className="question-notice-bold">{t("Question-Title1")}</p>
        <p className="question-notice-bold">{t("Question-Title2")}</p>
        <p className="question-notice-slide">/</p>
        <p className="question-notice-content">{t("Question-Title3")}</p>
        <p className="question-notice-content">{t("Question-Title4")}</p>
      </div> */}
      <div className="question-top-notice">
        <img src={QnaBanner} alt="상단 배너" />
      </div>
      
      

      <div className=" question-wrap">
        {path[2] === "list" && <List />}
        {path[2] === "write" && <Write />}
        {path[2] === "detail" && <Detail />}
        {path[2] === "modify" && <Modify />}
      </div>
    </div>
  );
}

export default Question;
