import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import "../../../css/question/List.css";

import Item from "./QnaItem";
import Modal from "../../../common/Modal";
import Alert from "../../../common/AlertModal";
import Empty from "../../../images/empty_icon.png";
import arrow from "../../../images/arrow.png";
import viewMore from '../../../images/arrow-down.png';

import { userSelect } from "../../../services/userService";
import { getQnaList } from "../../../services/qnaService";

function List({ history }) {
  const { t } = useTranslation();
  let userInfo = JSON.parse(localStorage.getItem("ziple_user"));
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [qnaListCnt, setQnaListCnt] = useState(0);
  const [qnaList, setQnaList] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [user, setUser] = useState({
    userNo: 0,
    token: "",
    userDivCd: "",
  });

  useEffect(() => {
    questionSearch();
  }, []);

  useEffect(() => {
    if (userInfo != null) {
      userSelect(userInfo.userNo).then((res) => {
        setUser({
          userNo: userInfo.userNo,
          token: userInfo.token,
          userDivCd: res.userDivCd,
        });
      });
    }
  }, []);

  const checkLogin = () => {
    if (userInfo === null) {
      setMessage(t("Login-Alert"));
      setModal(true);
    } else {
      window.scrollTo(0, 20);
      history.push("/question/write");
    }
  };

  const checkEnterKey = (e) => {
    if (e.key === "Enter") {
      questionSearch();
    }
  };

  const questionSearch = () => {
    getQnaList({
      title: keyword,
      userNm: "",
      userNo: "",
      pageNo: 1,
      content: keyword,
      ideaDiv: "",
      pageSize: "5",
      sortDbField: "reg_date",
      sortDirection: "desc",
    }).then((res) => {
      if(res != null){
        setQnaList(res.lists);
        setQnaListCnt(res.listCnt);
      }
      // setPageNo(pageNo + 1);
    });
  };

  const MoreView = async () => {
    if (qnaListCnt > qnaList.length) {
      await getQnaList({
        title: keyword,
        userNm: "",
        userNo: "",
        pageNo: pageNo + 1,
        content: keyword,
        ideaDiv: "",
        pageSize: "5",
        sortDbField: "reg_date",
        sortDirection: "desc",
      }).then((res) => {
        const fetchedData = res.lists; // 피드 데이터 부분
        const mergedData = qnaList.concat(...fetchedData);
        setQnaList(mergedData);
        setQnaListCnt(res.listCnt);
        setPageNo(pageNo + 1);
      });
    }
  };

  return (
    <>
      <div className="white question-box">
        <div className="question-search-box">
          <input
            type="text"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            onKeyPress={checkEnterKey}
          />
          <div onClick={questionSearch}>{t("search")}</div>
        </div>
        
        {qnaList.length > 0 ? (
          <>
            {qnaList.map((qna, index) => (
              <Item qna={qna} key={index} user={user} />
            ))}
          </>
        ) : (
          <>
            {qnaList.length<=0 && (
              <div className="book-mark-empty-wrap">
                <img
                  src={Empty}
                  alt="비어있는 아이콘"
                  className="book-mark-empty"
                />
                <p>{t("Empty-QNA")}</p>
                <button className="question-viewall-button"onClick={()=>window.location.href="/question/list"}>{t('ViewAll')}</button>
              </div>
            )}
          </>
        )}
        {qnaListCnt > pageNo * 5 && (
           <div
           className="question-more-view"
           onClick={() => {
             MoreView();
           }}
         >
           {t("View-More")}
           <img src={viewMore}/>
         </div>
        )}
        <div className="question-more-view" onClick={checkLogin}>
          {t("New-QnA")}
        </div>
      </div>

      

      {modal && (
        <Modal>
          <Alert msg={message} setShowModal={setModal} />
        </Modal>
      )}
    </>
  );
}

export default withRouter(List);
