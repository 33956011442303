import { server } from "../config/config";
import axios from "axios";

let currentToken = "";
let user = JSON.parse(localStorage.getItem("ziple_user"));

if (user !== null) {
  currentToken = user.token;
}

//서버연결
const http = axios.create({
  baseURL: server,
});

// 401 에러 발생시 == user 정보 없을 시, 로그인 페이지로 이동
http.interceptors.response.use(
  (res) => {
    return res;
  },
  (e) => {
    if (e.response.status === 401) {
      localStorage.removeItem("ziple_user");
      window.location = "/";
    } else {
      return e;
    }
  }
);

//대리인 목록
export const delegateList = async (form) => {
  try {
    return await http
      .get(`/infoDelegateList`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentToken,
        },
        params: form,
      })
      .then((res) => res.data);
  } catch (e) {
    console.log(e);
  }
};

//상세정보
export const delegateInfo = async (form) => {
  try {
    return await http
      .get(`/infoDelegate/select`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentToken,
        },
        params: form,
      })
      .then((res) => res.data);
  } catch (e) {
    console.log(e);
  }
};

export const getDelegateClick = async (form) => {
  try {
    return await http
      .post(`/qna/delegateHist`, form, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentToken,
        },
      })
      .then((res) => res.data);
  } catch (e) {
    console.log(e);
  }
};

// 대리인 북마크 Y/N
export const delegateLikeInsert = async (delegateUserNo, userNo, likeYn) => {
  let form = {
    delegateUserNo: delegateUserNo,
    userNo: userNo,
    token: currentToken,
    likeYn: likeYn,
  };
  try {
    return await http
      .post(`delegateLikeInsert`, form, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentToken,
        },
      })
      .then((res) => res.data);
  } catch (e) {
    console.log(e);
  }
};
