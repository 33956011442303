import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

import icon from "../../../images/search.png";
import InfoItem from "./InfoItem";

import { infoList } from "../../../services/infoService";

function Information() {
  const { t } = useTranslation();
  const [info, setInfo] = useState([]);
  const responsive = {
    0: {
      items: 1,
    },
    330: {
      items: 2,
    },
    660: {
      items: 3,
    },
    990: {
      items: 4,
    },
  };

  const getList = () => {
    infoList({
      userDiv: "1", // 1 일반인, 2 대리인
      infoDiv: "", // 01 특허뉴스, 02 R&D, 03 경진대회
      subject: "",
      userNo: 1,
      pageNo: 1,
      pageSize: 20,
      sortDbField: "reg_date",
      sortDirection: "desc",
    }).then((res) => {
      if(res != null){
        setInfo(res.lists);
      }
    });
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <div className="main-information">
      <div className="flex">
        <img src={icon} alt="search" style={{marginRight:10}}/>
        <p className="main-information-title">{t("Main-Information")}</p>
        {/* <div
          className="main-view-more flex"
          onClick={() => {
            window.location.href = "/lounge";
          }}
        >
          <p>{t("View-More")}</p>
          <img src={Icon} />
        </div> */}
      </div>

      <AliceCarousel
        // autoPlay={true}
        // autoPlayInterval="5000"import { useMediaQuery } from "react-responsive";

        mouseTracking={true}
        infinite={true}
        responsive={responsive}
        disableButtonsControls={true}
        disableDotsControls={true}
      >
        {info.map((information, index) => (
          <InfoItem key={index} item={information} />
        ))}
      </AliceCarousel>
    </div>
  );
}

export default Information;
