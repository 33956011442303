import React, { useEffect, useState } from "react";
import { server } from "../../config/config";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import { delegateInfo } from "../../services/delegateService";
import { userSelect } from "../../services/userService";
import { getProfessional } from "../../common/util";
import {phoneHyphen} from '../../util/inputUtil';
import { rezNoInfo,rezInsert ,rezTimeInfo} from "../../services/reservationService";

import Modal from "../../common/Modal";
import Confirm from "../../common/ConfirmModal";
import Alert from "../../common/AlertModal";
import "../../css/delegate/Detail.css";
import "../../css/delegate/Delegate.css";
import arrow from "../../images/back.png";
import profile from "../../images/profile.png";

import Calendar3 from "../../common/Calendar3";
import Time from './component/Time';
function Reservation({ match, history }) {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [message, setMessage] = useState("");
  const [user, setUser] = useState({});
  const [detail, setDetail] = useState(""); // 대리인 정보
  const [rezNo, setRezNo] = useState({}); // 요청한 정보
  const [profieldUrl, setProfieldUrl] = useState([]);
  const [field, setField] = useState([]);
  const [fieldBackground, setFieldBackground] = useState([]);

  const [rezDate, setRezDate] = useState(""); // 날짜정보
  const [rezTime, setRezTime] = useState(""); // 예약 시간 정보
  const [rezTimeDiv, setRezTimeDiv] = useState("15"); // 예약 시간 정보
  const [rezMoney, setRezMoney] = useState("1000"); // 예약 금액 정보
  const [rezContents, setRezContents] = useState(""); // 예약 내용 정보

  const [rezMinTime, setRezMinTime] = useState("09:00"); // 예약 시작 시간
  const [rezMaxTime, setRezMaxTime] = useState("18:00"); // 예약 마감 시간

  const [noDate, setNoDate] = useState({
    rezNoAmDate: [],
    rezNoPmDate: [],
    rezNoBothDate: [],
  }); // 예약 안되는 날짜
  const [noRezTime, setNoRezTime] = useState([]); //예약 안되는 시간
  let userInfo = JSON.parse(localStorage.getItem("ziple_user"));
  let lang = localStorage.getItem("language");
  let no = history.location.pathname.substr(
    history.location.pathname.lastIndexOf("/") + 1
  );
  const getUserInfo = async () => {
    try {
      await userSelect(userInfo.userNo).then((res) => {
        setUser({
          no: userInfo.userNo,
          email: res.userEmail,
          name: res.userNm,
          phone: res.phoneNo != null ? phoneHyphen(res.phoneNo):'',
          nickname: res.nickNm,
        });
      });
    } catch (e) {}
  };

  useEffect(() => {
    if (userInfo != null) {
      getUserInfo();
    }
  }, []);

  // 대리인 정보 가져오기
  const getDelegateInfo = () => {
    delegateInfo({
      adNo: no,
      userNo: userInfo !== null ? userInfo.userNo : 1,
    }).then((res) => {
      setDetail(res.data);
      if (res.data.professionalField !== null) {
        if (lang === "ko") {
          const parse = getProfessional(
            res.data.professionalField,
            res.data.delegateIPMandate
          );
          setFieldBackground(parse.backgroundColor);
          setProfieldUrl(parse.flagUrls);
          setField(parse.field);
        } else {
          const parse = getProfessional(
            res.data.professionalFieldEn,
            res.data.delegateIPMandate
          );
          setFieldBackground(parse.backgroundColor);
          setProfieldUrl(parse.flagUrls);
          setField(parse.field);
        }
      }
    });
    var now = new Date();
    setRezDate(now.getDate() + 1 );
    setRezTime(new Date());
    setRezMoney(25000);

    checkRezTime(new Date());
  };

  const getRezNoInfo = () => {
    rezNoInfo({
      deAdNo: no,
    }).then((res) => {
      let data = res.data;
      let amDate = [];
      let pmDate = [];
      let bothDate = [];
      data.map((d) => {
        if (d.rezApm.includes("am")) {
          amDate = noDate.rezNoAmDate.concat(d.notDate);
          amDate = amDate.filter((element, index) => {
            return amDate.indexOf(element) === index;
          });
         
        } else if (d.rezApm.includes("pm")) {
          pmDate = noDate.rezNoPmDate.concat(d.notDate);
          pmDate = pmDate.filter((element, index) => {
            return pmDate.indexOf(element) === index;
          });
        } else if (d.rezApm.includes("both")) {
          bothDate = noDate.rezNoBothDate.concat(d.notDate);
          bothDate = bothDate.filter((element, index) => {
            return bothDate.indexOf(element) === index;
          });
        }

        setNoDate({
          ...noDate,
          rezNoAmDate: amDate,
          rezNoPmDate: pmDate,
          rezNoBothDate: bothDate,
        });
      });
    });
  };

  useEffect(() => {
    getDelegateInfo();
    getRezNoInfo();
  }, []);

  useEffect(()=>{
   getRezTimeInfo();
  },[rezDate]);

  useEffect(() => {
    if (!modal) {
      if (message === t("LoginErrorMsg")) {
        window.location.href = "/login";
      }
    }
  }, [modal]);
const getRezTimeInfo=()=>{
  rezTimeInfo({
    rezDate:rezDate
  }).then((res) => {
   
    let time = []
    res.data.map((d)=>{
      time = time.concat(d.rezTime);

      time = time.filter((element, index) => {
        return time.indexOf(element) === index;
      });
      setNoRezTime(time); 
    })
  });
}
  const save = () => {
    let dateYMD = rezDate;
    let timeHi = rezTime;
    let ampm;
    if (typeof rezDate !== "string") {
      dateYMD = `${rezDate.getFullYear()}-${
        rezDate.getMonth() + 1
      }-${rezDate.getDate()}`;
    }
    if (typeof rezTime !== "string") {
      timeHi = `${rezTime.getHours()}:${rezTime.getMinutes()}`;
      ampm = `${rezTime.getHours()}` >= 12 ? "pm" : "am";
    } else {
      ampm = rezTime.substring(0, 2) >= 12 ? "pm" : "am";
    }
    
    let form = {
      userNo: user.no,
      userNm : user.name,
      phone : user.phone,
      deAdNo: detail.userNo,
      rezDate: dateYMD,
      rezTime: timeHi,
      rezApm: ampm,
      rezTimeDiv: rezTimeDiv,
      content: rezContents,
      statusCd:"예약요청"
    };
    
    if(rezContents == null || rezContents === ""){
      setModal(true);
      setMessage('상담 요청 내용을 입력해주세요 ');
    }else{
      rezInsert(form).then((res)=>{
        if(res.resCd === '0000'){
          setRezNo(res.data)
          setConfirmModal(true);
          setMessage('결제해 주시면 요청이 완료 됩니다. ');
        }else{
          setModal(true);
          setMessage('이미 상담 요청이 된 시간입니다. \n 다른 시간대로 변경해서 다시 요청해주세요 ');
        }
      })
    }
  };

  const goToPay = () => {
    window.open(server+"/pg/web?Prdtnm="+detail.regUserNm+" 변리사 영상상담 예약&Price="+rezMoney+"&UserNm="+user.name+"&UserId="+user.no+"&No="+rezNo);
    setTimeout(() => {
      window.location.href='/my/qna';
    }, 1000)
  };

  const checkRezTime = (value) => {
    if (noDate.rezNoAmDate.includes(value)) {
      setRezTime("13:00");
      setRezMinTime("13:00");
      setRezMaxTime("18:00");
    } else if (noDate.rezNoPmDate.includes(value)) {
      setRezTime("09:00");
      setRezMinTime("09:00");
      setRezMaxTime("12:00");
    } else {
      setRezMinTime("09:00");
      setRezMaxTime("18:00");
    }
  };
 const handleImgError = (e)=>{
    e.target.src = profile
 }
  return (
    <>
      <div style={{ width: "80vw", margin: "auto", maxWidth: "1440px" }}>
        <div
          style={{
            borderBottom: "1px solid #b3b3b3",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            padding: 20,
          }}
        >
          상담 예약하기
        </div>
        {/* 변리사 정보 */}
        <div className="delegate-item-box flex">
          <div className="delegate-profile-content">
            {detail.adGrade === "1" ? (
              <div className="delegate-adGrade-member">MEMBER</div>
            ) : (
              <div className="delegate-adGrade-premium">PREMIUM</div>
            )}
            <div className="delegate-profile-name">
              {detail.regUserNm}
              &nbsp;
              {detail.delegateDiv === "1" ? (
                <span>{t("Attorney")}</span>
              ) : (
                <span>{t("Lawyer")}</span>
              )}
            </div>

            <div className="flex delegate-detail-field-wrap">
              <div className="flex">
                {profieldUrl.map((img, k) => (
                  <div className="delegate-detail-cate" key={k} >
                  {/* <img src={img} alt="img" /> */}
                  <div style={{background:fieldBackground[k], padding:10}} >{field[k]}</div>
                </div>
                ))}
              </div>
            </div>
            {lang === "ko" ? (
              <div className="delegate-explain">{detail.contents}</div>
            ) : (
              <div className="delegate-explain">{detail.contentsEn}</div>
            )}
          </div>
          <div className="delegate-profile">
            <div className="delegate-profile-img">
              <img src={detail.profileUrl} alt="profile" onError={handleImgError}/>
            </div>
            {detail.adGrade === "2" && (
              <div className="delegate-detail-btn">
                <div
                  onClick={() => {
                    window.location.href = "/info/detail/" + detail.userNo;
                    window.scrollTo(0, 0);
                  }}
                >
                  {t("Delegate-View-More")}
                  {/* <img src={arrow} /> */}
                </div>
              </div>
            )}
          </div>
        </div>
        {/* 예약정보*/}
        <div className="delegate-item-box" style={{ border: 0 }}>
          <div className="rezTitle">상담종류 선택</div>

          <div>
            <div
              style={{
                display: "flex",
                color: "white",
                fontWeight: "bold",
                marginBottom: 10,
              }}
            >
              <div
                className={rezTimeDiv === "15" ? "rez-active rez-15" : "rez-15"}
                onClick={() => {
                  setRezTimeDiv("15");
                  setRezMoney(25000);
                }}
              >
                15분 상담
              </div>
              <div
                className={rezTimeDiv === "30" ? "rez-active rez-30" : "rez-30"}
                onClick={() => {
                  setRezTimeDiv("30");
                  setRezMoney(45000);
                }}
              >
                30분 상담
              </div>
            </div>
            <div style={{ color: "red", fontSize: 13 }}>
              ※ 상담 비용 : {rezMoney.toLocaleString()}원
            </div>
          </div>
          <div className="rezTitle">상담 날짜 및 시간 선택</div>
          <div>
            날짜선택
          </div>
         <Calendar3 rezDate={rezDate} setRezDate={setRezDate} noDate ={noDate}/>
          {/* <Calendar rezDate={rezDate} setRezDate={setRezDate}/> */}
          <div>
            시간선택 
          </div>
            <Time rezTime={rezTime} setRezTime={setRezTime} noRezTime={noRezTime}/>
        
      
        
          {/* <Flatpickr
            value={rezDate}
            style={{ cursor: "pointer" }}
            placeholder="날짜를 선택하세요"
            options={{
              minDate: new Date().fp_incr(1),
              altFormat: "Y-m-d",
              altInput: true,
              disable: [
                noDate.rezNoBothDate,
                function (date) {
                  // return true to disable
                  return date.getDay() === 0 || date.getDay() === 6;
                },
              ],
            }}
            onChange={(date, dateStr) => {
              setRezDate(dateStr);
              checkRezTime(dateStr);
            }}
          />
          <Flatpickr
            data-enable-time
            value={rezTime}
            style={{ cursor: "pointer" }}
            options={{
              enableTime: true,
              noCalendar: true,
              dateFormat: "H:i",

              minTime: rezMinTime,
              maxTime: rezMaxTime,

              minuteIncrement: 15,
              altInput: true,
              disable: [],
            }}
            onChange={(date, dateStr) => {
              setRezTime(dateStr);
            }}
          /> */}

          <div className="rezTitle">상담 요청 내용</div>
          <textarea
            style={{ width: "100%", padding: 10 }}
            name="textarea"
            rows={10}
            placeholder="변리사와 원활한 상담을 위해 상세 상담 내용을 작성해 주세요."
            value={rezContents}
            onChange={(event) => setRezContents(event.target.value)}
          />
          <div style={{ display: "flex" }}>
            <div style={{width:'50%'}}>
              <div className="rezTitle">의뢰인 성함</div>
              <input
                type="text"
                style={{ backgroundColor: "#efefef", padding:10,width:'50%', borderRadius:'5px' }}
                value={user.name}
                placeholder={t("NameIn")}
                maxLength="20"
                onChange={(e) =>
                  setUser({
                    ...user,
                    name: e.target.value,
                  })
                }
              />
            </div>
            <div style={{width:'50%'}}>
              <div className="rezTitle">의뢰인 연락처</div>
              <input
                type="text"
                style={{ backgroundColor: "#efefef", padding:10,width:'50%', borderRadius:'5px' }}
                value={user.phone}
                placeholder={t("PhoneNoIn")}
                maxLength="20"
                onChange={(e) =>
                  setUser({
                    ...user,
                    phone: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>
        <div
          style={{
            justifyContent: "flex-end",
            display: "flex",
            marginBottom: "3%",
           
          }}
         
        >
          <div
            style={{
              backgroundColor: "#0E5EC7",
              padding: 10,
              color: "white",
              borderRadius: 5, cursor: "pointer",
            }} onClick={() => {
              save();
            }}
          >
            요청하기
          </div>
        </div>
      </div>
      {modal && (
        <Modal>
          <Alert msg={message} setShowModal={setModal} />
        </Modal>
      )}
       {confirmModal && (
        <Modal>
          <Confirm
            msg={message}
            setShowModal={setConfirmModal}
            setResult={goToPay}
          />
        </Modal>
      )}
    </>
  );
}

export default withRouter(Reservation);
