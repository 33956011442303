import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import "../../css/myPage/MyPage.css";

import MyInfo from "./component/MyInfo";
import MyBookMark from "./component/MyBookMark";
import MyQna from "./component/MyQnA";
import Menu from "./component/Menu";
import SignOut from "./component/SignOut";
import Loading from "../../common/Loading";

import { userSelect } from "../../services/userService";

function MyPage() {
  let userInfo = JSON.parse(localStorage.getItem("ziple_user"));
  const location = useParams().type;
  const [loading, setLoading] = useState(false);
  const [menu, setMenu] = useState("");
  const [user, setUser] = useState({
    no: "",
    email: "",
    name: "",
    phone: "",
    nickname: "",
    joinPath: "",
  });

  const getUserInfo = async () => {
    try {
      setLoading(true);
      await userSelect(userInfo.userNo).then((res) => {
        setUser({
          no: userInfo.userNo,
          email: res.userEmail,
          name: res.userNm,
          phone: res.phoneNo,
          nickname: res.nickNm,
          joinPath: res.joinPath,
        });
        setLoading(false);
      });
    } catch (e) {}
  };

  useEffect(() => {
    if (location === "personal") {
      setMenu("마이페이지");
    } else if (location === "mark") {
      setMenu("나의 북마크");
    } else if (location === "qna") {
      setMenu("나의 질의응답");
    } else {
      setMenu("회원탈퇴");
    }
  }, [location]);

  useEffect(() => {
    getUserInfo();
  }, []);

  if (loading) {
    return (
      <div className="my-page flex">
        <Menu menu={menu} user={user} />
        <div className="my-page-box">
          <Loading />
        </div>
      </div>
    );
  }

  return (
    <div className="my-page flex">
      <Menu menu={menu} user={user} />

      {menu === "마이페이지" && <MyInfo user={user} />}
      {menu === "나의 북마크" && <MyBookMark user={user} />}
      {menu === "나의 질의응답" && <MyQna user={user} />}
      {menu === "회원탈퇴" && <SignOut user={user} />}
    </div>
  );
}

export default MyPage;
