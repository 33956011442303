import React from "react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { userLogout } from "../../services/loginService";

function MobileSubMenu({ history, setSubMenu, setView }) {
  const { t } = useTranslation();

  //로그아웃 버튼 클릭시
  const LogoutHandle = async () => {
    await userLogout({
      userNo: JSON.parse(localStorage.getItem("ziple_user")).userNo,
    }).then((res) => {
      if (res) {
        // window.Kakao.init("0edc8d4474271811c9a295f09920b1e5");
        // window.Kakao.Auth.logout(function () {
        //   // console.log(window.Kakao.Auth.getAccessToken());
        // });
        setSubMenu(false);
        setView(false);
        localStorage.removeItem("ziple_user");
        document.location.href = "/";
      } else {
        console.log("error");
      }
    });
  };

  const pageMove = (location) => {
    setSubMenu(false);
    setView(false);
    history.push(location);
  };

  return (
    <div className="mobile-dropdown">
      <div onClick={() => pageMove("/my/personal")}>{t("My-Page")}</div>
      <div onClick={() => pageMove("/my/mark")}>{t("My-BookMark")}</div>
      <div onClick={() => pageMove("/my/qna")}>{t("My-Q&A")}</div>
      <div onClick={() => LogoutHandle()}>{t("Logout")}</div>
      <div
        onClick={() => {
          setSubMenu(false);
          setView(false);
        }}
      >
        {t("Close")}
      </div>
    </div>
  );
}

export default withRouter(MobileSubMenu);
