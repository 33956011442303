import React from "react";
import { Route, Redirect } from "react-router-dom";

import TopBar from "../common/TopBar";
import Footer from "../common/Footer";

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <>
      <TopBar />
      <Route {...rest}>
        {localStorage.getItem("ziple_user") ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
            }}
          />
        )}
      </Route>
      <Footer />
    </>
  );
}

export default PrivateRoute;
