import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import Korea from "@svg-maps/south-korea";
import { SVGMap } from "react-svg-map";
import { useTranslation } from "react-i18next";
import location from "../../../images/location.png";
import '../../../css/main/Main.css';
function Map({ history }) {
  const { t } = useTranslation();
  const [lo, setLo] = useState("");
  const [tooltipStyle, setTooltipStyle] = useState({ display: "none" });
  const pageMove = (location) => {
    history.push(location);
  };

  const handleLocationMouseOver = (e) => {
    setLo(t(e.target.id));
  };
  const handleLocationMouseMove = (event) => {
    setTooltipStyle({
      display: "block",
      top: event.clientY + 10,
      left: event.clientX - 50,
    });
  };
  const handleLocationMouseOut = () => {
    setLo(null);
    setTooltipStyle({ display: "none" });
  };
  return (
    <div className="main-map">
      <p>지역별로 변리사를 만나보세요.</p>
      <div className="map">
        <div className="svg-map__tooltip" style={tooltipStyle}>
          {lo}
        </div>
        <div className="map-div">
          <SVGMap
            label={Korea.locations.name}
            map={Korea}
            onLocationClick={(d) => {
              pageMove("/info/" + t(d.target.id));
            }}
            onLocationMouseOver={(e) => {
              handleLocationMouseOver(e);
            }}
            onLocationMouseOut={(e) => {
              handleLocationMouseOut(e);
            }}
            onLocationMouseMove={(e) => {
              handleLocationMouseMove(e);
            }}
          />
        </div>
        <div className="map-all-div"
          onClick={() => {
            pageMove("/info");
          }}
        >
          <img src={location} /><br/>
          {t('ViewAll')}
        </div>
      </div>
    </div>
  );
}

export default withRouter(Map);
