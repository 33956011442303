import React from "react";

import TopBar from "../common/TopBar";
import Footer from "../common/Footer";

function WithMenu({ component: Component, ...rest }) {
  return (
    <>
      <TopBar />
      <Component />
      <Footer />
    </>
  );
}

export default WithMenu;
