import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "../../css/view/ViewPage.css";

import ViewMenu from "./component/ViewMenu";
import FAQ from "./component/Faq";
import Notice from './component/Notice';
import Private from "./component/Private";
function View({match,history}) {
  const { t } = useTranslation();
 // const location = useParams().type;
  const [menu, setMenu] = useState(t("FAQ"));
 // console.log(history.location.pathname.;
    let location = history.location.pathname.substr(history.location.pathname.lastIndexOf('/')+1);

  useEffect(() => {
    if (location === "faq") {
      setMenu("FAQ");
    }else if (location === "notice"){
      setMenu("notice");
    } 
    else if(location === "terms"){
      setMenu( "terms" );
    }else {
      setMenu( "privacy" );
    }
  }, [location]);
  
  return (
    <div className="view-page flex">
      <ViewMenu menu={menu} setMenu ={setMenu}/>
      
      {menu === "FAQ" && <FAQ />}
      {menu === "notice" && <Notice />}
      {(menu === "terms" || menu === "privacy" )  && <Private  menu={menu}/>}
    </div>
  );
}

export default withRouter(View);
