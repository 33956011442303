import React, { useState } from "react";
import {
  getDelegateClick,
} from "../../../services/delegateService";
import kakaoImg from "../../../images/k_btn.png";
import facebookImg from "../../../images/Facebook.png";
import instaImg from "../../../images/insta.png";
import mailImg from "../../../images/Email_d.png";
import webImg from "../../../images/website.png";
import phoneImg from "../../../images/Phone_d.png";

import { delegateBtnNm } from "../../../common/util";
import Modal from "../../../common/Modal";
import Alert from "../../../common/AlertModal";
function Contact({ detail }) {
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState("");
  let userInfo = JSON.parse(localStorage.getItem("ziple_user"));

  const delegateClick = (div, value, adNo, adNm) => {

    // if (userInfo === null) {
    //   setMessage(t('LoginErrorMsg'));
    //   setModal(true);
    // } else {
    if (value != null) {
      if (div === 1) {
        window.location.href = "tel:" + value;
      } else if (div === 2) {
        window.location.href="mailto:" + value+"?subject=[ziple]대리인에게 질문입니다. " + "&body=[자이플]에서 보낸 메일입니다. https://www.ziple.biz" ;
      } else if (div === 4 || div === 5 || div === 6 || div === 7) {
        window.open(value);
      }
    

      window.gtag(
        "event",
        adNm + "대리인 " + delegateBtnNm(div.toString()) + " 버튼 클릭",
        {
          eventCategory: div,
          eventLabel:
            adNm + "대리인 " + delegateBtnNm(div.toString()) + " 버튼 클릭",
          eventAction: div + "버튼 클릭",
          eventValue: 1,
        }
      );

      let form = { userNo: userInfo !== null ? userInfo.userNo:0, action: div, no: adNo, cateDiv: "brand" };
       getDelegateClick(form).then((res) => {});
    }
  };

  return (
    <div className="delegate-detail-info-buttons flex">
      {detail.kakaoYn !== "N" && (
        <button
          className="delegate-info-button"
          onClick={() =>
            delegateClick(4, detail.kakaoInfo, detail.adNo, detail.regUserNm)
          }
        >
          <img alt="kakaoImg" src={kakaoImg} />
        </button>
      )}
      {detail.phoneYn !== "N" && (
        <button
          className="delegate-info-button"
          onClick={() =>
            delegateClick(1, detail.phoneNo, detail.adNo, detail.regUserNm)
          }
        >
          <img alt="phoneImg" src={phoneImg} />
        </button>
      )}
      {detail.emailYn !== "N" && (
        <button
          className="delegate-info-button"
          onClick={() =>
            delegateClick(2, detail.userEmail, detail.adNo, detail.regUserNm)
          }
        >
          <img alt="mailImg" src={mailImg} />
        </button>
      )}
      {detail.webYn !== "N" && (
        <button
          className="delegate-info-button"
          onClick={() =>
            delegateClick(7, detail.siteUrl, detail.adNo, detail.regUserNm)
          }
        >
          <img alt="webImg" src={webImg} />
        </button>
      )}
      {detail.facebookYn !== "N" && (
        <button
          className="delegate-info-button"
          onClick={() =>
            delegateClick(5, detail.faUrl, detail.adNo, detail.regUserNm)
          }
        >
          <img alt="facebookImg" src={facebookImg} />
        </button>
      )}
      {detail.instagramYn !== "N" && (
        <button
          className="delegate-info-button"
          onClick={() =>
            delegateClick(6, detail.insUrl, detail.adNo, detail.regUserNm)
          }
        >
          <img alt="instaImg" src={instaImg} />
        </button>
      )}
      {modal && (
        <Modal>
          <Alert msg={message} setShowModal={setModal} />
        </Modal>
      )}
    </div>
  );
}

export default Contact;
