import React from "react";
import { useTranslation } from "react-i18next";

import "../../../css/infoLounge/Info.css";

import icon from "../../../images/news_icon.png";
import InfoItem from "./InfoItem";

function News({ info}) {
  const { t } = useTranslation();

  return (
    <div className="main">
      <div className="flex news-title-box">
        <img src={icon} alt="뉴스 아이콘" />
        <div className="news-titie">
          <p>{t('IpNewsMsg')}</p>
          <p>{t('IpNewsMsg2')}</p>
        </div>
      </div>

      {info.map((item, index) => (
        <InfoItem key={item.infoNo} item={item} />
      ))}
    </div>
  );
}

export default News;
