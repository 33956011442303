import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Modal from "../../../common/Modal";
import Terms from "./Terms";

function SignUpTerm({ checkbox, index, setCheckBoxes, checkBoxes }) {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);

  return (
    <>
      <div className="flex sign-up-terms-checkbox">
        <input
          type="checkbox"
          id={index}
          checked={checkbox.value}
          className="sign-up-terms-input"
          onChange={(e) => {
            setCheckBoxes(
              checkBoxes.map((checkbox, i) =>
                i === index
                  ? {
                      ...checkbox,
                      value: e.target.checked,
                    }
                  : checkbox
              )
            );
          }}
        />
        <label htmlFor={index} className="sign-up-terms-content">
          {" "}
          <span></span> {checkbox.text}
        </label>
        <button className="terms-modal-btn" onClick={() => setModal(true)}>
          {t("ViewTerms")}
        </button>
      </div>

      {modal && (
        <Modal>
          <Terms setModal={setModal} title={checkbox.text} />
        </Modal>
      )}
    </>
  );
}

export default SignUpTerm;
