import React from "react";

function Modal({ setModal, user }) {
  const pageMove = () => {
    setModal(false);
    if (user !== null) {
      window.location.href = "/";
    } else {
      window.close();
    }
  };

  return (
    <div className="inactive-modal-box">
      <div className="inactive-modal-content">
        <p>회원님 계정의 휴면 상태가 해제되었습니다.</p>
        <p>
          재로그인 후 자이플의 모든 서비스를 정상적으로 이용하실 수 있습니다.
        </p>
      </div>
      <button className="inactive-modal-ok" onClick={() => pageMove()}>
        확인
      </button>
    </div>
  );
}

export default Modal;
