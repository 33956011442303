import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { delegateList } from "../../services/delegateService";

import Category from "./components/Category";
import DelegateItem from "./components/DelegateItem";
import Loading from "../../common/Loading";

import "../../css/delegate/Delegate.css";

function List({ match }) {
  const { t } = useTranslation();
  const path = useLocation().pathname;
  //const [category, setCategory] = useState(match.params.keyword);
  const [category, setCategory] = useState("");
  const [delegate, setDelegate] = useState([]);
  const [delegateSize, setDelegateSize] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    delegateList({
      userDiv: "1", // 1 일반인, 2 대리인
      infoDiv: "", // 01 특허뉴스, 02 R&D, 03 경진대회
      subject: t(category),
      userNo: 1,
      pageNo: page,
      pageSize: 20,
      sortDbField: "reg_date",
      sortDirection: "desc",
    }).then((res) => {
      console.log(res);
      if (res != null) {
        if (page === 1) {
          setDelegate(res.lists);
        } else {
          setDelegate(delegate.concat(res.lists));
        }
        setDelegateSize(res.listCnt != undefined ? res.listCnt : 0);
      } else {
        setDelegateSize(0);
      }

      setLoading(false);
    });
  }, [path, category, page]);

  if (loading) {
    return (
      <div className="delegate">
        <Category category={category} setCategory={setCategory} />
        <Loading />
      </div>
    );
  }

  return (
    <div className="delegate">
      <Category category={category} setCategory={setCategory} />

      <div className="delegate-list-wrap">
        <div className="delegate-list-div flex">
          <span>{t(category) === "" ? t("ViewAll") : category}</span>
          <div>{delegateSize}</div>
        </div>
        {/* <div className="flex">
          <span className="delegate-ad-info">{t("PremiumAttorney")}</span>
          <img
            src={icon}
            alt="광고 아이콘"
            onMouseOver={() => setModal(true)}
            onMouseLeave={() => setModal(false)}
            onClick={() => setModal(!modal)}
          />
        </div> */}
        {/* {modal && <Alert />} */}
        {delegateSize !== 0 && (
          <div id="delegateItem">
            {delegate.map((item, index) => (
              <DelegateItem key={index} item={item} />
            ))}
          </div>
        )}

        {delegateSize === 0 && (
          <div className="delegate-list-empty">{t("Patent-No")}</div>
        )}

        {delegateSize > page * 20 && (
          <div onClick={() => setPage(page + 1)}>{t("ViewMore")}</div>
        )}
      </div>
    </div>
  );
}

export default List;
