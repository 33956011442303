import React, { useState, useEffect } from "react";
import { useParams, Prompt } from "react-router-dom";
// import { useTranslation } from "react-i18next";

import "../../css/inactive/Inactive.css";

import { userRelease, userLogout } from "../../services/loginService";

import Modal from "../../common/Modal";
import Release from "./Modal";
import Alert from "../../common/AlertModal";

function Inactive() {
  // const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  let user = JSON.parse(localStorage.getItem("ziple_user"));
  let no = useParams().id;

  const pageMove = () => {
    if (no !== undefined) {
      window.close();
    } else {
      window.location.href = "/login";
    }
  };

  useEffect(() => {
    if (user !== null) {
      userLogout(user.userNo).then((res) => {
        localStorage.removeItem("ziple_user");
      });
    }
  }, []);

  return (
    <>
      <div className="inactive-user-view grey">
        <div className="inactive-content">
          <div className="inactive-title">휴면 계정 해제 안내</div>
          <div className="inactive-notice">
            <div className="inactive-top-content">
              회원님의 계정이 1년간 로그인하지 않아 휴면 계정으로
              전환되었습니다.
              <br />
              <br />
              재로그인 후 자이플 서비스를 이용해 주세요.
              <br />
              <br />
              자이플은 정보통신망법에 따라 1년간 로그인 되지 않은
              <br />
              회원님의 계정을 휴면 전환하고 소중한 개인정보를 별도로 보관합니다.
              <br />
              <br />
            </div>
            <div className="inactive-middle-content">
              <p>휴면 해제 안내</p>
              <ul>
                <li>∙ 휴면 상태 해제 후에는 재로그인이 필요합니다.</li>
                <li>
                  ∙ 휴면 상태 해제 후 자이플의 모든 서비스를 정상적으로 이용하실
                  수 있습니다.
                </li>
              </ul>
            </div>
            <div className="inactive-bottom-content">
              <br />
              자이플의 모든 서비스를 다시 이용하고 싶으시면, 재로그인을
              해주세요.
              <br />
              <br />
            </div>
            <div className="inactive-button-box">
              {/* <button className="inactive-cancel">취소</button> */}
              <button className="inactive-ok" onClick={() => pageMove()}>
                로그인하러 가기
              </button>
            </div>
          </div>
        </div>
      </div>

      {modal && (
        <Modal>
          <Release setModal={setModal} user={user} />
        </Modal>
      )}
    </>
  );
}

export default Inactive;
