import React from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import Logo from "../images/logo.png";
import Youtube from "../images/youtubeIcon.png";
import Instagram from "../images/InstaIcon.png";
import Facebook from "../images/facebookIcon.png";
import Blog from "../images/blogIcon.png";
import { useMediaQuery } from "react-responsive";

import "../css/common/Footer.css";

import { recordClick } from "../common/util";

function Footer({ history }) {
  const { t } = useTranslation();
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    return isMobile ? children : null;
  };
  const DeskTop = ({ children }) => {
    const isDeskTop = useMediaQuery({ minWidth: 768 });
    return isDeskTop ? children : null;
  };
  return (
    <>
      <DeskTop>
        <div className="flex footer-box">
          <div className="footer-logo-wrap">
            <img src={Logo} alt="ziple_logo" className="white-logo-image" />
          </div>
          <div>
            <p className="footer-margin">
              <span
                onClick={() => {
                  history.push("/private/terms");
                  window.scrollTo(0, 0);
                }}
              >
                {t("Terms-Of-Service")}
              </span>{" "}
              |{" "}
              <span
                onClick={() => {
                  history.push("/private/privacy");
                  window.scrollTo(0, 0);
                }}
              >
                <span>{t("Privacy")}</span>
              </span>{" "}
              |{" "}
              <span
                onClick={() =>
                  window.open(
                    "https://www.ftc.go.kr/bizCommPop.do?wrkr_no=5798700638&apv_perm_no="
                  )
                }
              >
                {t("CheckInformation")}
              </span>
            </p>
            {/* <p className="footer-margin">
          <strong>{t("BusinessHours")}</strong>&nbsp;{t("Detail-BusinessHours")}
        </p> */}
            <p className="footer-common-margin">{t("footer-comNm")}</p>
            <p className="footer-common-margin">
              {t("footer-addr")}<br/>
              {t("footer-addr2")}<br/>
              {t("footer-comNum")} : 579-87-00638
            </p>
           
            <p className="footer-common-margin">
              {t("Representation")}&nbsp;{t("Company-Number")} :{" "}
              <a
                href="tel:0318901422"
                onClick={() => recordClick("Call", "0318901422", t("Call"))}
              >
                031-890-1422
              </a>
              &nbsp;{t("Email")} :{" "}
              <a
                href="mailto:cs@ziple.biz"
                onClick={() => recordClick("Mail", "cs@ziple.biz", t("Mail"))}
              >
                cs@ziple.biz
              </a>
            </p>
            <p className="footer-common-margin"></p>
            <p className="footer-common-margin"></p>
          </div>
          <div>
            <img
              className="footer-link-image"
              src={Youtube}
              alt="유투브"
              onClick={() =>
                recordClick(
                  "Youtube",
                  "https://www.youtube.com/channel/UC_hEmwHj-FZ_epHT2xCu5Ng",
                  t("Youtube")
                )
              }
            />
            <img
              className="footer-link-image"
              src={Instagram}
              alt="인스타그램"
              onClick={() =>
                recordClick(
                  "Instagram",
                  "https://www.instagram.com/ziple.biz",
                  t("Instagram")
                )
              }
            />
            <img
              className="footer-link-image"
              src={Facebook}
              alt="페이스북"
              onClick={() =>
                recordClick(
                  "FaceBook",
                  "https://www.facebook.com/ziplebiz",
                  t("FaceBook")
                )
              }
            />
            <img
              className="footer-link-image"
              src={Blog}
              alt="블로그"
              onClick={() =>
                recordClick(
                  "Blog",
                  "https://blog.naver.com/ziple_official",
                  t("Blog")
                )
              }
            />
          </div>
        </div>
      </DeskTop>
      <Mobile>
        <div className="flex footer-box">
          <div className="footer-logo-wrap">
            <img src={Logo} alt="ziple_logo" className="white-logo-image" />
          </div>
          <div>
            <p className="footer-margin">
              <span
                onClick={() => {
                  history.push("/private/terms");
                  window.scrollTo(0, 0);
                }}
              >
                {t("Terms-Of-Service")}
              </span>{" "}
              |{" "}
              <span
                onClick={() => {
                  history.push("/private/privacy");
                  window.scrollTo(0, 0);
                }}
              >
                <span>{t("Privacy")}</span>
              </span>{" "}
              |{" "}
              <span
                onClick={() =>
                  window.open(
                    "https://www.ftc.go.kr/bizCommPop.do?wrkr_no=5798700638&apv_perm_no="
                  )
                }
              >
                {t("CheckInformation")}
              </span>
            </p>
            {/* <p className="footer-margin">
          <strong>{t("BusinessHours")}</strong>&nbsp;{t("Detail-BusinessHours")}
        </p> */}
            <p className="footer-common-margin">
              {t("footer-comNm")}&nbsp;{t("footer-addr")}&nbsp;
              {t("footer-comNum")}
              &nbsp;: 579-87-00638
            </p>
            <p className="footer-common-margin"></p>
            <p className="footer-common-margin">
              {t("Representation")}&nbsp;{t("Company-Number")} :{" "}
              <a
                href="tel:0318901422"
                onClick={() => recordClick("Call", "0318901422", t("Call"))}
              >
                031-890-1422
              </a>
              &nbsp;{t("Email")} :{" "}
              <a
                href="mailto:cs@ziple.biz"
                onClick={() => recordClick("Mail", "cs@ziple.biz", t("Mail"))}
              >
                cs@ziple.biz
              </a>
            </p>
            <p className="footer-common-margin"></p>
            <p className="footer-common-margin"></p>
          </div>
        </div>
        <div className="footer-link-image-div">
          <img
            className="footer-link-image"
            src={Youtube}
            alt="유투브"
            onClick={() =>
              recordClick(
                "Youtube",
                "https://www.youtube.com/channel/UC_hEmwHj-FZ_epHT2xCu5Ng",
                t("Youtube")
              )
            }
          />
          <img
            className="footer-link-image"
            src={Instagram}
            alt="인스타그램"
            onClick={() =>
              recordClick(
                "Instagram",
                "https://www.instagram.com/ziple.biz",
                t("Instagram")
              )
            }
          />
          <img
            className="footer-link-image"
            src={Facebook}
            alt="페이스북"
            onClick={() =>
              recordClick(
                "FaceBook",
                "https://www.facebook.com/ziplebiz",
                t("FaceBook")
              )
            }
          />
          <img
            className="footer-link-image"
            src={Blog}
            alt="블로그"
            onClick={() =>
              recordClick(
                "Blog",
                "https://blog.naver.com/ziple_official",
                t("Blog")
              )
            }
          />
        </div>
      </Mobile>
    </>
  );
}

export default withRouter(Footer);
