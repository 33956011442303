import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Logo from "../../images/logo.png";
import Back from "../../images/back.png";

import "../../css/login/Login.css";
import "../../css/signUp/SignUp.css";
import "../../css/common/Common.css";

import SignUpForm from "./component/SignUpForm";
import SignUpTerm from "./component/SignUpTerm";
import Button from "./component/Button";
import Modal from "../../common/Modal";
import Alert from "../../common/AlertModal";

import { userSignUp } from "../../services/loginService";

function SignUp({ history }) {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState("");
  const [form, setForm] = useState({
    email: "",
    conditionEmail: true, // 이메일 입력 형식 확인
    checkEmail: true, // 중복 이메일 확인 : true 일 경우 사용 가능한 이메일
    password: "",
    conditionPassword: true, // 비밀번호 입력 조건 확인
    checkPassword: "", // 비밀번호 일치 확인
    name: "",
    phone: "",
    certiNumber: "", // 인증번호
    certi: false, // 인증번호 일치 여부
    agree: false, // 약관 동의 여부
  });
  const [checkBoxes, setCheckBoxes] = useState([
    {
      value: false,
      require: true,
      text: t("TermsOfService"),
      display: false,
    },
    {
      value: false,
      require: true,
      text: t("InformationUse"),
      display: false,
    },
  ]);

  const handleSignUp = () => {
    if (form.email === "") {
      setMessage(`${t("EmailIn")}`);
      setModal(true);
      return;
    }
    // 이메일 중복 체크
    // userEmailCheck(form.email).then((res) => {
    //   if (res.resCd !== "0000") {
    //     setForm({ ...form, checkEmail: false });
    //     setMessage(t("EmailCheck2"));
    //     setModal(true);
    //     return;
    //   }
    // });
    if (
      !form.conditionPassword ||
      form.checkPassword === "" ||
      form.password === ""
    ) {
      setMessage(`${t("PasswordCheckMsg")}`);
      setModal(true);
      return;
    }
    if (form.name === "") {
      setMessage(`${t("NameIn")}`);
      setModal(true);
      return;
    }
    if (form.phone === "" || form.certi === false) {
      setMessage(`${t("PhoneAuthCheck")}`);
      setModal(true);
      return;
    }
    if (!form.agree) {
      setMessage(`${t("TermsCheck")}`);
      setModal(true);
      return;
    }

    if (
      form.email.length > 1 &&
      form.conditionEmail &&
      form.password.length >= 8 &&
      form.conditionPassword &&
      form.checkPassword === form.password &&
      form.name !== "" &&
      form.phone !== "" &&
      form.certi === true &&
      form.agree
    ) {
      userSignUp({
        userEmail: form.email,
        password: form.password,
        password2: form.checkPassword,
        userNm: form.name,
        nickNm: form.name,
        phoneNo: form.phone,
        authYn: "Y",
        userDivCd: "1",
        userGradeCd: "",
        pushYn: "Y",
        languageCd: "KR",
        userStatusCd: "1",
        joinPath: "email",
        deviceId: "",
        deviceOs: "",
        osVersion: "",
        deviceNm: "",
        countryCd: "KR",
        signupCd: "web",
      }).then((res) => {
        if (res) {
          setMessage(t("SignUpComplete"));
          setModal(true);
        } else {
          setMessage(t("SignUpFail"));
          setModal(true);
        }
      });
    } else {
      setMessage(`${t("SignUpFail")}` + 1);
      setModal(true);
    }
  };

  // 이용약관 동의 여부 확인
  useEffect(() => {
    if (checkBoxes[0].value && checkBoxes[1].value) {
      setForm({
        ...form,
        agree: true,
      });
    } else {
      setForm({
        ...form,
        agree: false,
      });
    }
  }, [checkBoxes]);

  // 핸드폰 번호 입력 창 변경시 인증 확인 값 false 처리
  useEffect(() => {
    if (form.certi) {
      setForm({
        ...form,
        certi: false,
      });
    }
  }, [form.phone]);

  // 회원가입 완료 후 메인 로그인 페이지로 이동
  useEffect(() => {
    if (!modal) {
      if (message === t("SignUpComplete")) {
        window.location.href = "/login";
      }
    }
  }, [modal]);

  return (
    <div className="grey full-cover login-padding">
      <div>
        <img
          src={Logo}
          alt="ziple_logo"
          className="block logo-image"
          onClick={() => history.push("/")}
        />

        <div className="white sign-box">
          <div className="login-box">
            <div className="flex login-title">
              <div className="back-box">
                <img
                  src={Back}
                  alt="back_btn"
                  className="back-image"
                  onClick={() => history.goBack()}
                />
              </div>
              <p>{t("Sign")}</p>
            </div>

            <SignUpForm form={form} setForm={setForm} />

            <div className="sign-up-terms-box">
              <p className="sign-up-terms-title">{t("Terms")}</p>
              <div className="flex sign-up-terms-checkbox">
                <input
                  type="checkbox"
                  id="circle-checkbox"
                  className="sign-up-terms-input"
                  onChange={(e) => {
                    setCheckBoxes(
                      checkBoxes.map((checkbox) => ({
                        ...checkbox,
                        value: e.target.checked,
                      }))
                    );
                  }}
                />
                <label
                  htmlFor="circle-checkbox"
                  className="sign-up-terms-content"
                >
                  {" "}
                  <span></span> {t("AllAgree")}
                </label>
              </div>

              {checkBoxes.map((checkbox, index) => (
                <SignUpTerm
                  checkbox={checkbox}
                  checkBoxes={checkBoxes}
                  setCheckBoxes={setCheckBoxes}
                  index={index}
                  key={index}
                />
              ))}
            </div>

            <Button type="simple" msg={t("Sign")} change={handleSignUp} />
          </div>
        </div>
      </div>

      {modal && (
        <Modal>
          <Alert msg={message} setShowModal={setModal} />
        </Modal>
      )}
    </div>
  );
}

export default withRouter(SignUp);
