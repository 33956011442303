import { server } from "../config/config";
import axios from "axios";

let currentToken = "";
let user = JSON.parse(localStorage.getItem("ziple_user"));

if (user !== null) {
  currentToken = user.token;
}
//서버연결
const http = axios.create({
  baseURL: server,
});

// 401 에러 발생시 == user 정보 없을 시, 로그인 페이지로 이동
http.interceptors.response.use(
  (res) => {
    return res;
  },
  (e) => {
    if (e.response.status === 401) {
      localStorage.removeItem("ziple_user");
      window.location = "/";
    } else {
      return e;
    }
  }
);
// 정보라운지 게시글 리스트
export const infoList = async (form) => {
  try {
    return await http
      .get(`/infoList2`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentToken,
        },
        params: form,
      })
      .then((res) => res.data);
  } catch (e) {
    console.log(e);
  }
};

// 메인 기록
export const downloadHist = async (form) => {
  try {
    return await http
      .get(`/brand`, {
        headers: {
          "Content-Type": "application/json",
        },
        params: form,
      })
      .then((res) => res.data);
  } catch (e) {
    console.log(e);
  }
};

// 정보라운지 북마크 Y/N
export const BookMarkUp = async (form) => {
  try {
    return await http
      .post(`/bookMark`, form, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentToken,
        },
      })
      .then((res) => res.data);
  } catch (e) {
    console.log(e);
  }
};
