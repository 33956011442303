import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import "../../../css/myPage/Rez.css";
import Profile from "../../../images/profile.png";

import Button from "../../../images/ArrowRight.png";
import Lock from "../../../images/Lock.png";

import {
  ideaDivNm,
  formatDotDate,
  choiceRandomColor,
  
} from "../../../common/util";
import { getReplyList } from "../../../services/qnaService";
function RezItem({ history, rez }) {
  const { t } = useTranslation();

  return (
    <div
      className="my-page-rez flex"
      onClick={() => history.push("/rez/detail/" + rez.rezNo)}
    >
      <div style={{ width: "90%" }}>
        <div className="flex" style={{ width: "100%" }}>
          <div className="my-page-rez-timediv">{rez.rezTimeDiv}분 영상상담</div>
          <div className="my-page-rez-timediv">{rez.rezDate}</div>
          <div className="my-page-rez-timediv">
            {rez.rezApm}
            {rez.rezTime}
          </div>
        </div>
        <div className=" my-page-rez-wrap">
          <b>상담요청 내용</b>
          <br />
          <p>{rez.content}</p>
        </div>
      </div>
      <div style={{textAlign:'right'}}>
        <div className="my-page-rez-statusDiv">{rez.statusCd}</div>
        <img
          className="my-page-qna-click"
          src={Button}
          alt="영상상담 상세보기 이미지"
        />
      </div>
    </div>
  );
}

export default withRouter(RezItem);
