import React from "react";
import { useTranslation } from "react-i18next";

import IOS from "../../../images/app_store.png";
import Android from "../../../images/play_store.png";
import BannerImg from "../../../images/banner.jpeg";
import { recordClick } from "../../../common/util";

function Banner() {
  const { t } = useTranslation();

  return (
    <div className="banner-box flex"
    onClick={() =>
      recordClick(
        "banner",
        "https://purplestatedao.com",
        t("Banner")
      )
    }

    >
          <img src={BannerImg} style={{width:"100%"}} alt="ad"/>

      {/* <div className="bottom-banner-content">
        <p>{t("Main-Store-Msg1")}</p>
        <p>{t("Main-Store-Msg2")}</p>
      </div> */}
      <span>AD</span>
     
    </div>
  );
}

export default Banner;
