import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

// import Empty from "../../../images/empty_icon.png";

import Modal from "../../../common/Modal";
import Confirm from "../../../common/ConfirmModal";

import { infoProcess, formatDate } from "../../../common/util";
import { delegateLikeInsert } from "../../../services/delegateService";
import { BookMarkUp } from "../../../services/infoService";
import bookmarkOn from '../../../images/bookmark2.png';
import arrow from "../../../images/back.png";
import Profile from '../../../images/profile.png';
function Item({ history, item, type, setAction }) {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  let user = JSON.parse(localStorage.getItem("ziple_user"));

  const removeBookMark = () => {
    if (type === "변리사") {
      delegateLikeInsert(item.adNo, user.userNo, "N").then((res) => {
        if (res.resCd === "0000") {
          setAction(true);
        }
      });
    }

    if (type === "정보") {
      BookMarkUp({
        checkNo: item.checkNo,
        infoNo: item.infoNo,
        userNo: user.userNo,
        checkDiv: "1",
        checkYn: "N",
      }).then((res) => {
        if (res.resCd === "0000") {
          setAction(true);
        }
      });
    }
  };

  const handlePopUp = () => {
    window.open(item.link);
  };

  const pageMove = (location) => {
    history.push(location);
  };
  const handleImgError = (e) => {
    e.target.src = Profile;
  }

  return (
    <>
      {type === "변리사" && (
        <div className="flex my-page-book-mark-delegate">
          <div className="book-mark-delegate-content">
            <b>
              {item.regUserNm}
              </b>
              &nbsp;
              {item.delegateDiv === "1" ? t("Attorney") : t("Lawyer")}
           
            <div>{item.contents}</div>
          </div>
          <div className="book-mark-delegate-profile">
            <img src={item.profileUrl} alt="변리사 프로필" onError={handleImgError}/>
           
            {item.adGrade != '1' &&(
             <div className="delegate-detail-btn">
            <div
              onClick={() => {
                window.location.href = "/info/detail/" + item.userNo;
                window.scrollTo(0, 0);
              }}
            >
              {t('View-More')}
              <img src={arrow} />
            </div>
          </div>
            )}
          </div>
          <div className="book-mark-image" onClick={() => setModal(true)}>
            <img src={bookmarkOn}/>
          </div>
        </div>
      )}

      {type === "정보" && (
        <>
          {item.infoDiv === "01" && (
            <div className="flex my-page-book-mark-delegate">
              <div className="book-mark-delegate-content">
                <b>{item.subject}</b>
                <div>{item.contents}</div>
                <div>
                  {formatDate(item.regDate)} | {item.organizer}
                </div>
              </div>
              <div className="info-image-box">
                <img src={item.imgUrl} alt="info_img" />
                {/* <p className="info-news-link" onClick={handlePopUp}>
                  기사 확인하기 &gt;
                </p> */}
                <div className="info-detail-button">
              <button onClick={() => handlePopUp}>
                {t("CheckArticle")}
                
              </button>
            </div>
              </div>
              <div className="book-mark-image" onClick={() => setModal(true)}>
            <img src={bookmarkOn}/>
          </div>
            </div>
          )}
          {item.infoDiv !== "01" && (
            <div className="flex my-page-book-mark-delegate">
              <div className="info-process-state">
                <span
                  className={
                    item.process === "1"
                      ? "info-process-on"
                      : "info-process-off"
                  }
                >
                  {t(infoProcess(item.process))}
                </span>
              </div>
              <div className="book-mark-news-content">
                <b onClick={handlePopUp}>{item.subject}</b>
                <div>
                  {formatDate(item.regDate)} | {item.organizer}
                </div>
              </div>
              <div className="book-mark-image" onClick={() => setModal(true)}>
            <img src={bookmarkOn}/>
          </div>
            </div>
          )}
        </>
      )}

      {modal && (
        <Modal>
          <Confirm
            msg={t("Delete-BookMark")}
            setShowModal={setModal}
            setResult={removeBookMark}
          />
        </Modal>
      )}
    </>
  );
}

export default withRouter(Item);
