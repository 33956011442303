import React from "react";
import { useTranslation } from "react-i18next";

function InfoItem({ title, content }) {
  const { t } = useTranslation();

  return (
    <div className="delegate-info-div">
      <div className="delegate-info-div-title">{t(title)}</div>
      <div className="delegate-info-div-content">
        {title === "Mandate" ? (
          <pre>
            {content.map((item, index) => (
              <div key={index}>
                {item.indexOf("전문") > -1 ? (
                  <p>
                    <strong>{item}</strong>
                  </p>
                ) : (
                  <>
                    {item.indexOf(":") > -1 ? (
                      <p>
                        <strong>{item.substr(0, item.indexOf(":"))}</strong>
                        {item.substr(item.indexOf(":"))}
                      </p>
                    ) : (
                      <>
                        {item.indexOf("최근 1년간") > -1 ? (
                          <p>
                            <strong>{item}</strong>
                          </p>
                        ) : (
                          <p>{item.substr(0, item.lastIndexOf(","))}</p>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            ))}
          </pre>
        ) : (
          <>
            {title === "Introduce" ? (
              <pre style={{ lineHeight: "20px" }}>{content}</pre>
            ) : (
              <pre>{content}</pre>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default InfoItem;
