import React from "react";

import loading from "../images/loading.png";

function Loading() {
  return (
    <div className="loading-box">
      <img src={loading} alt="로딩 이미지" />
    </div>
  );
}

export default Loading;
