import { server } from "../config/config";
import axios from "axios";

let currentToken = "";
let user = JSON.parse(localStorage.getItem("ziple_user"));

if (user !== null) {
  currentToken = user.token;
}

//서버연결
const http = axios.create({
  baseURL: server,
});

// 401 에러 발생시 == user 정보 없을 시, 로그인 페이지로 이동
http.interceptors.response.use(
  (res) => {
    return res;
  },
  (e) => {
    if (e.response.status === 401) {
      localStorage.removeItem("ziple_user");
      window.location = "/";
    } else {
      return e;
    }
  }
);

//예약 안되는 정보
export const rezNoInfo = async (form) => {
  try {
    return await http
      .get(`/rezNoInfo`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentToken,
        },
        params: form,
      })
      .then((res) => res.data);
  } catch (e) {
    console.log(e);
  }
};
export const rezTimeInfo = async (form) => {
  try {
    return await http
      .get(`/rezTimeInfo`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentToken,
        },
        params: form,
      })
      .then((res) => res.data);
  } catch (e) {
    console.log(e);
  }
};
//예약정보 등록
export const rezInsert = async (form) => {
  try {
    return await http
      .post(`/rezIn`, form, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentToken,
        },
      })
      .then((res) => res.data);
  } catch (e) {
    console.log(e);
  }
};

// 예약 상세정보
export const rezDetail = async (form) => {
  try {
    return await http
      .get(`/rezDetail`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentToken,
        },
        params: form,
      })
      .then((res) => res.data);
  } catch (e) {
    console.log(e);
  }
};
//zoom room number 및 비번 정보 가져옴
export const zoomInfo = async () => {
  try {
    return await http
      .get(`/zoomInfo`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentToken,
        },
      })
      .then((res) => res.data);
  } catch (e) {
    console.log(e);
  }
};
