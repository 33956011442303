import React, { useState,useEffect } from "react";
import { useTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";

import "../../../css/myPage/Qna.css";
import Profile from "../../../images/profile.png";


import Button from "../../../images/ArrowRight.png";
import Lock from "../../../images/Lock.png";

import { ideaDivNm, formatDotDate,choiceRandomColor } from "../../../common/util";
import {getReplyList} from '../../../services/qnaService';
function QnaItem({ history, qna }) {
  const { t } = useTranslation();
  const [replyList, setReplyList]= useState({});
  useEffect(async () => {
    if (qna.qnaNo != null) {
      await getReplyList({
        qnaNo: qna.qnaNo,
        userNo: qna.userNo,
        div:'list'
      }).then((res) => {
        if (res !== null) {
          setReplyList(res.replyList);
        }
      });
    }
  }, [qna]);

  return (
    <div
      className="my-page-qna flex"
      onClick={() => history.push("/question/detail/" + qna.qnaNo)}
    >
      <div>
        <div className="flex">
          <div className="my-page-qna-category">
            {t(ideaDivNm(qna.ideaDiv))}
          </div>
          <div className="my-page-qna-date">{formatDotDate(qna.regDate)}</div>
        </div>
        <div className="flex my-page-qna-wrap">
          <p>{qna.title}</p>
         
          {qna.replyCnt > 0 && (
            <span className="qna-reply-count">{qna.replyCnt}</span>
          )}
        </div>
        <div className="qna-item-delegate-div">
            {replyList.length > 0 && (
              <>
                {replyList.map((reply, k) => (
                  <>
                    {k < 6 && (
                      <>
                        {qna.userNo !== reply.userNo && (
                          <div style={{ display: "inline-flex" }}>
                            {reply.profileUrl.indexOf("gravatar") === -1 ? (
                              <img
                                className="profile-image3"
                                src={reply.profileUrl}
                                alt="유저 프로필"
                                onError={(e) => {
                                  e.target.onError = null;
                                  e.target.src = Profile;
                                }}
                              />
                            ) : (
                              <div
                                className="qna-color-profile3"
                                style={{
                                  background: choiceRandomColor(qna.userNo),
                                }}
                              >
                                {reply.nickNm.substr(0, 1)}*
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </>
                ))}
              </>
            )}
            {replyList.length > 6 && (
              <div className="qna-item-delegate-div2">
                외 {replyList.length - 5}인
              </div>
            )}
          </div>
      </div>
      <div>
      {qna.toPublic === "N" ?
            <img className="lock-image" src={Lock} alt="좌물쇠 이미지" />
          :
        <img
          className="my-page-qna-click"
          src={Button}
          alt="질의응답 상세보기 이미지"
        />}
      </div>
      
    </div>
  );
}

export default withRouter(QnaItem);
