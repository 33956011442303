import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Empty from "../../../images/empty_icon.png";

import Item from "./BookmarkItem";
import Loading from "../../../common/Loading";

import "../../../css/myPage/BookMark.css";

import {
  bookMarkDelegate,
  getBookMarkInfo,
} from "../../../services/userService";

function MyBookMark({ user }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState(false); // 북마크 해제시 list 가져오기
  const [menu, setMenu] = useState("전체 목록");
  const [list, setList] = useState({
    delegate: [],
    info: [],
  });

  const getMyBookMark = async () => {
    try {
      let delegate = [];
      let info = [];
      setLoading(true);
      if (menu === "전체 목록" || menu === "변리사") {
        await bookMarkDelegate({
          userNo: user.no,
        }).then((res) => {
          delegate = res.lists;
        });
      }

      if (menu === "전체 목록" || menu !== "변리사") {
        await getBookMarkInfo({
          userNo: user.no,
          category: menu,
        }).then((res) => {
          info = res.list;
        });
      }

      setList({
        delegate: delegate,
        info: info,
      });
      setAction(false);
      setLoading(false);
    } catch (e) {}
  };

  useEffect(() => {
    getMyBookMark();
  }, [menu, action]);

  if (loading) {
    return (
      <div className="my-page-box white">
        <div className="my-page-sub-box">
          <div className="my-page-title">
            <p>{t("My-BookMark")}</p>
          </div>
          <div className="flex book-mark-category">
            <div
              className={
                menu === "전체 목록"
                  ? "bookmark-category-active"
                  : "bookmark-category-noactive"
              }
              onClick={() => setMenu("전체 목록")}
            >
              {t("All-List")}
            </div>
            <div
              className={
                menu === "변리사"
                  ? "bookmark-category-active"
                  : "bookmark-category-noactive"
              }
              onClick={() => setMenu("변리사")}
            >
              {t("Attorney")}
            </div>
            <div
              className={
                menu === "특허뉴스"
                  ? "bookmark-category-active"
                  : "bookmark-category-noactive"
              }
              onClick={() => setMenu("특허뉴스")}
            >
              {t("IPNews")}
            </div>
            <div
              className={
                menu === "IP"
                  ? "bookmark-category-active"
                  : "bookmark-category-noactive"
              }
              onClick={() => setMenu("IP")}
            >
              IP R&D
            </div>
            <div
              className={
                menu === "경진대회"
                  ? "bookmark-category-active"
                  : "bookmark-category-noactive"
              }
              onClick={() => setMenu("경진대회")}
            >
              {t("IPCompetition")}
            </div>
          </div>
          {/* <Loading /> */}
        </div>
      </div>
    );
  }

  return (
    <div className="white my-page-box">
      <div className="my-page-sub-box">
        <div className="my-page-title">
          <p>{t("My-BookMark")}</p>
        </div>
        <div className="flex book-mark-category">
          <div
            className={
              menu === "전체 목록"
                ? "bookmark-category-active"
                : "bookmark-category-noactive"
            }
            onClick={() => setMenu("전체 목록")}
          >
            {t("All-List")}
          </div>
          <div
            className={
              menu === "변리사"
                ? "bookmark-category-active"
                : "bookmark-category-noactive"
            }
            onClick={() => setMenu("변리사")}
          >
            {t("Attorney")}
          </div>
          <div
            className={
              menu === "특허뉴스"
                ? "bookmark-category-active"
                : "bookmark-category-noactive"
            }
            onClick={() => setMenu("특허뉴스")}
          >
            {t("IPNews")}
          </div>
          <div
            className={
              menu === "IP"
                ? "bookmark-category-active"
                : "bookmark-category-noactive"
            }
            onClick={() => setMenu("IP")}
          >
            IP R&D
          </div>
          <div
            className={
              menu === "경진대회"
                ? "bookmark-category-active"
                : "bookmark-category-noactive"
            }
            onClick={() => setMenu("경진대회")}
          >
            {t("IPCompetition")}
          </div>
        </div>

        {menu === "전체 목록" && (
          <>
            {list.delegate.length > 0 && (
              <>
                {list.delegate.map((item, index) => (
                  <Item
                    key={index}
                    item={item}
                    type="변리사"
                    setAction={setAction}
                  />
                ))}
              </>
            )}

            {list.info.length > 0 && (
              <>
                {list.info.map((item, index) => (
                  <Item
                    key={index}
                    item={item}
                    type="정보"
                    menu={menu}
                    setAction={setAction}
                  />
                ))}
              </>
            )}
          </>
        )}

        {menu === "변리사" && (
          <>
            {list.delegate.length > 0 && (
              <>
                {list.delegate.map((item, index) => (
                  <Item
                    key={index}
                    item={item}
                    type="변리사"
                    setAction={setAction}
                  />
                ))}
              </>
            )}
          </>
        )}

        {menu !== "전체 목록" && menu !== "변리사" && (
          <>
            {list.info.length > 0 && (
              <>
                {list.info.map((item, index) => (
                  <Item
                    key={index}
                    item={item}
                    type="정보"
                    menu={menu}
                    setAction={setAction}
                  />
                ))}
              </>
            )}
          </>
        )}

        {list.delegate.length <= 0 && list.info.length <= 0 && (
          <div className="book-mark-empty-wrap">
            <img
              src={Empty}
              alt="비어있는 아이콘"
              className="book-mark-empty"
            />
            <p>{t("Empty-BookMark")}</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default MyBookMark;
