import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import moment, { weekdays } from "moment";
import "../css/common/Calendar.css";

function Calendar3({ history, rezDate, setRezDate, noDate }) {
  const [getMoment, setMoment] = useState(moment());
  const [getAllDate, setAllDate ] = useState([]);
 
  const today = getMoment;
  const weekly = today.clone().week();
  useEffect(()=>{
    if(getAllDate.indexOf(noDate.rezNoBothDate) == -1){
      setAllDate(getAllDate.concat(noDate.rezNoBothDate));
    }
  },[noDate.rezNoBothDate]);
  const getCal = () => {
    let result = [];
    let d;
    let week = weekly;
    // for (d = nowDate; d <= 31; d++) {
    result = result.concat(
      <tr>
        {Array(14)
          .fill(0)
          .map((data, index) => {
            let days = today
              .clone()
              .startOf("year")
              .week(week)
              .startOf("week")
              .add(index, "day");

            // if (d > 31) {
            //   d = 1;
            // } else
            if (days.day() == "0" || days.day() == "6") {
              //토일 제외
              return;
            } else if (moment().format("YYYYMMDD") >= days.format("YYYYMMDD")) {
              //오늘 이전 선택안되고 보여지게만 하기
              return (
                <td key={index} className="noSelect">
                  <button className="noSelect">
                    <div>{getWeekDay(days.day())}</div>
                    <div>{days.format("D")}</div>
                  </button>
                </td>
              );
            } else if ( getAllDate.includes(days.format("YYYY-MM-DD"))) {
              return (
                <td key={index} className="noSelect">
                  <button className="noSelect">
                    <div>{getWeekDay(days.day())}</div>
                    <div>{days.format("D")}</div>
                  </button>
                </td>
              );
            }
            return (
              <td
                key={index}
                className={rezDate == days.format("YYYY-MM-DD") ? "select" : ""}
              >
                <button
                  className={
                    rezDate == days.format("YYYY-MM-DD") ? "select" : ""
                  }
                  onClick={() => {
                    setRezDate(days.format("YYYY-MM-DD"));
                  }}
                >
                  <div>{getWeekDay(days.day())}</div>
                  <div>{days.format("D")}</div>
                </button>
              </td>
            );
          })}
      </tr>
    );

    return result;
    // }
  };
  const getWeekDay = (val) => {
    switch (val) {
      case 1:
        return "월";
      case 2:
        return "화";
      case 3:
        return "수";
      case 4:
        return "목";
      case 5:
        return "금";
    }
  };
  return (
    <div className="Calendar">
      <table>
        <tbody>{getCal()}</tbody>
      </table>
    </div>
  );
}

export default withRouter(Calendar3);
