import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import "../../../css/myPage/SignOut.css";

import Modal from "../../../common/Modal";
import Alert from "../../../common/AlertModal";
import Confirm from "../../../common/ConfirmModal";

import { userSignOut, userLogout } from "../../../services/loginService";

function SignOut({ user }) {
  const { t } = useTranslation();
  const [reason, setReason] = useState("");
  const [modal, setModal] = useState(false);
  const [isOut, setIsOut] = useState(false);
  const [message, setMessage] = useState("");

  const checkSignOut = () => {
    if (reason === "") {
      setMessage(t("WithdrawReason"));
      setModal(true);
    } else {
      setIsOut(true);
    }
  };

  const signOut = () => {
    userSignOut({
      userNo: user.no,
      userMemo: reason,
      userStatusCd: "9",
    }).then((res) => {
      if (res.resCd === "0000") {
        setMessage(t("WithdrawComplete"));
        setModal(true);
      }
    });
  };

  const LogoutHandle = () => {
    userLogout({
      userNo: user.no,
    }).then((res) => {
      if (res) {
        localStorage.removeItem("ziple_user");
        document.location.href = "/";
      } else {
        console.log("error");
      }
    });
  };

  useEffect(() => {
    if (!modal) {
      if (message === t("WithdrawComplete")) {
        LogoutHandle();
      }
    }
  }, [modal]);

  return (
    <>
      <div className="white my-page-box">
        <div className="my-page-title">
          <p>{t("Withdrawal")}</p>
        </div>
        <div className="sign-out-wrap">
          <p className="sign-out-notice">*{t("Precautions3")}</p>
          <textarea
            className="sign-out-reason"
            placeholder={t("WithdrawReason")}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
          />
          <button
            className="sign-out-button dark-blue"
            onClick={() => checkSignOut()}
          >
            {t("Withdraw")}
          </button>
        </div>
      </div>

      {}

      {modal && (
        <Modal>
          <Alert msg={message} setShowModal={setModal} />
        </Modal>
      )}

      {isOut && (
        <Modal>
          <Confirm
            msg={t("WithdrawMsg")}
            setShowModal={setIsOut}
            setResult={signOut}
          />
        </Modal>
      )}
    </>
  );
}

export default SignOut;
