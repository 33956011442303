import React from "react";
import { useTranslation } from "react-i18next";

import "../../../css/login/Terms.css";

function Terms({ setModal, title }) {
  const { t } = useTranslation();

  return (
    <div className="terms-modal-wrap">
      {title === t("TermsOfService") && (
        <>
          <p>{t("Terms-Of-Service")}</p>
          <p>
            제1장 총칙
            <br /> <br /> <br />
            제1조 (목적)
            <br /> <br />
            본 약관은 주식회사 지식피플(이하 ‘회사‘)이 회원에게 제공하는 Ziple
            서비스의 이용과 관련하여 회사와 회원 간의 권리, 의무 및 책임사항,
            기타 필요한 사항을 규정함을 목적으로 한다
            <br /> <br /> <br />
            제2조 (정의)
            <br /> <br />
            Ziple 서비스(이하 ‘서비스‘): 회사가 운영하는 인터넷 또는 모바일 등의
            인터페이스에서 발명자 회원이 남긴 아이디어에 대하여 변리사 회원이
            해당 아이디어에 적합한 형태의 지식재산권으로 등록하기 위한 출원
            업무를 수임할 수 있도록, 발명자 회원·변리사 회원을 중개하는 서비스를
            의미한다
            <br />
            * 이용자: 본 약관에 따라 회사가 제공하는 서비스를 이용하는 회원 및
            비회원을 의미한다
            <br />
            * 이용계약: 회사가 제공하는 서비스의 이용과 관련하여 회사와 이용자
            간에 체결하는 계약을 의미한다
            <br />
            * Ziple 계정: 이용자가 Ziple 서비스를 사용하기 위하여 필요한 이메일
            주소 및 아이디·패스워드 기반의 로그인 계정을 의미한다
            <br />
            * 회원: 본 약관에 동의함으로써 회사와 이용계약을 체결하고 Ziple
            계정을 등록한 자로서, 회사가 제공하는 정보와 서비스를 지속적으로
            이용할 수 있으며 발명자회원, 변리사 회원을 포함한다
            <br />
            * 아이디어: 자연법칙을 이용한 기술적 사상의 창작, 인간의 사상 또는
            감정을 표현한 창작물, 자기의 상품과 타인의 상품을 식별하기 위하여
            사용하는 표장, 물품의형상·모양·색채 또는 이들을 결합하여 시각을
            통하여 미감을 일으키게 하는 것, 제품 및 서비스 등의
            미적·기능적·경제적 가치를 최적화함으로써 생산자 및 소비자의
            물질적·심리적 욕구를 충족시키기 위한 창작 및 개선행위와 그 결과물 등
            지식재산권으로서 보호될 수 있는 모든 형태의 사상, 창작물 등을
            포함한다
            <br />
            * 사업화: 아이디어에서 비롯하여 개발된 기술이나 창작물 등을 이용하여
            제품의 개발, 생산, 판매, 대여, 라이선스 부여 등의 영리적인 사업을
            수행하거나, 그 과정에 관련 기술을 향상시켜 제품에 적용하는 것을
            의미한다
            <br />
            {/* * 투자: 참여자가 발명자와의 약정에 의하여 아이디어의 개발 및
            사업화를 위하여 발명자에게 일정 금액을 투자하고, 그 대가로서
            아이디어의 개발 및 사업화로 인하여 발생하는 이윤 중 일부를 취득할 수
            있는 지분을 갖게 되는 것을 의미한다
            <br />
            * 투자금: 참여자가 아이디어에 대한 투자를 위하여 발명자에게
            지급하여야 하는 투자금, 세금 등을 모두 포함한 금원을 의미한다
            <br /> */}
            * 출원: 발명자 회원이 게시·등록한 아이디어에 대하여 변리사 회원이 그
            아이디어에 적합한 형태의 지식재산권으로서 등록하기 위하여 관련
            법령에서 정하는 바 및 변리사 회원의 전문적인 역량에 따라 행하는
            신청이나 출원 등의 제반 절차를 의미한다
            <br />
            * 보수 등: 변리사 회원이 출원업무를 수행하는 것에 대하여 발명자
            회원이 변리사 회원에게 지급하는 보수, 세금 등을 모두 포함한 금원을
            의미한다
            <br />
            * 발명자 회원(이하 ‘발명자‘): 회원 중 변리사 회원으로부터 지식재산권
            등록을 위한 제반 업무 서비스를 받기 위하여 회사가 운영하는 인터넷
            또는 모바일 등의 인터페이스에 아이디어를 게시·등록하는 자를 의미한다
            <br />
            {/* * 참여자 회원(이하 ‘참여자‘): 회원 중 발명자의 아이디어를 발명·개발
            또는 사업화하기 위하여 발명자와의 약정에 따라 아이디어로 인하여
            발생하는 수익금을 분배 받을 수 있는 일정한 지분을 받고 그 발명자에게
            일정한 투자금을 지급하는 자를 의미한다
            <br /> */}
            * 변리사 회원(이하 ‘변리사‘): 회원 중 발명자와의 약정에 따라
            수임료를 지급 받고 발명자의 아이디어를 출원하기 위한 제반 업무를
            수행하는 자로서 변리사자격이 있는 자를 의미한다
            <br />
            * 아이디(ID): 회원의 식별과 회원의 서비스 이용을 위하여 회원이
            선정하고 회사가 승인하는 문자 또는 숫자의 조합을 의미한다
            <br />
            * 비밀번호(Password): 회원이 부여 받은 아이디와 일치하는 회원임을
            확인하고 회원의 권익을 보호하기 위하여 회원이 선정한 문자와 숫자의
            조합을 의미한다
            <br />
            * 전자우편(Email): 인터넷을 통한 우편 혹은 전기적 매체를 이용한
            우편을 의미한다
            <br />
            * 콘텐츠(Contents): 회사가 제공하는 각종 정보 및 회원이 입력 또는
            게시하는 모든 정보(게시물)을 의미한다
            <br />
            * 해지(또는 탈퇴): 회사 또는 회원이 이용계약을 해약하는 것을
            의미한다
            <br /> <br /> <br />
            제3조 (약관의 명시와 개정)
            <br /> <br />
            회사는 이 약관의 내용을 이용자가 알 수 있도록 회사가 운영하는
            인터페이스에 게시하거나 연결화면을 제공하는 방법으로 회원에게
            공지한다
            <br />
            본 약관은 이용자가 약관의 내용에 동의하며 회원가입을 신청하고,
            회사가 그 신청에 대하여 승낙함으로써 효력이 발생한다
            <br />
            회사는 이용자가 회사와 이 약관의 내용에 관하여 질의 및 응답을 할 수
            있도록 조치를 취한다
            <br />
            회사는 ‘약관의 규제에 관한 법률’ 등 관련 법령에 위배하지 않는
            범위에서 본 약관을 개정할 수 있다
            <br />
            회사는 약관을 개정할 경우에는 적용일자 및 개정내용, 개정 사유 등을
            명시하여 그 적용일자로부터 최소한 7일 이전(회원에게 불리하거나
            중대한 사항의 변경은 30일 이전)부터 그 적용일자 경과 후 상당한
            기간이 경과할 때까지 초기화면 또는 초기화면과의 연결화면을 통해
            공지한다
            <br />
            회사는 약관을 개정할 경우에는 원칙적으로 개정약관 공지 후 개정약관의
            적용에 대한 회원의 동의 여부를 확인한다 다만 개정약관 공지 시 회원이
            동의 또는 거부의 의사표시를 하지 않으면 승낙한 것으로 간주하겠다는
            내용도 함께 공지한 경우에는 회원이 약관 시행일까지 거부의사를
            표시하지 않는다면 개정약관에 동의한 것으로 간주할 수 있다
            <br />
            회원이 개정약관의 적용에 동의하지 않는 경우, 회원은 서비스
            이용계약을 해지할 수 있다
            <br />
            본 약관에 명시되지 않은 사항에 대해서는 회사가 정한 서비스 이용안내
            및 세부규칙, ‘약관의 규제에 관한 법률‘, ‘개인정보보호법‘,
            ‘정보통신망 이용촉진 및 정보보호 등에 관한 법률‘, ‘변리사법’ 등 관련
            법령 또는 상관습에 따른다
            <br /> <br /> <br />
            제4조 (회원가입)
            <br /> <br />
            회원이 되려고 하는 자는 약관의 내용에 대하여 동의를 하고, 회사의
            회원가입 페이지에서 제공하는 양식을 작성하여 회원가입 하는 방법으로
            이용신청을 하여야 한다
            <br />
            {/* 서비스를 이용하고자 하는 참여자는 일반회원 양식을 작성하여야 한다
            <br /> */}
            회사 형태, 자기 소개, 보유 기술, 포트폴리오 등 추가적인 정보를
            입력하여야 하며, 추가적인 정보를 입력하지 아니한 경우 서비스 이용이
            제한될 수 있습니다
            <br />
            회사는 회원이 되려고 하는 자에게 실명확인 및 본인인증, 기타 필요한
            정보를 요구할 수 있으며, 그 절차와 방식은 관련 법령에 따릅니다
            <br />
            회원이 되려고 하는 자는 이용 신청 시 제1항 및 제2항에서 요구하는
            정보 항목에 대하여 실제 정보를 기재하여야 합니다 실명 또는 정보는
            허위로 기재하거나
            <br />
            명의를 도용한 경우 이 약관에 의한 회원의 권리를 주장할 수 없고,
            회사는 환급 없이 서비스 이용계약을 취소하거나 해지할 수 있습니다
            <br />
            회원가입은 회원의 이용약관에 대한 동의와 회원의 이용 신청에 대한
            회사의 승낙으로 성립합니다 이용약관에 관한 동의는 이용 신청 당시
            회사 이용약관에 동의함을 선택하거나 기타 동의 버튼 또는 서명 등을
            통해 의사표시를 합니다
            <br /> <br /> <br />
            <b>제2장 서비스 이용계약</b>
            <br /> <br /> <br />
            제5조 (서비스 이용계약의 성립 등)
            <br /> <br />
            서비스 이용계약은 회사가 운영하는 인터넷 또는 모바일 등 인터페이스에
            가입하고자 하는 자가 회사가 정한 이용약관 및 개인정보 수집 및 이용
            동의서에 대하여 ‘동의’를 선택하고, 온라인 신청 양식을 작성하여
            서비스 이용을 신청한 후 이용신청에 대하여 회사가 이를 승낙함으로써
            성립한다
            {/* <br />
            서비스를 이용하고자 하는 참여자는 일반회원 양식을 작성하여야 한다 */}
            <br />
            투자와 관련하여, 발명자는 회사가 운영하는 인터넷 또는 모바일 등
            인터페이스를 통해 명시적으로 입력한 조건으로 약정을 체결한 것으로
            본다
            <br /> <br /> <br />
            제6조 (이용신청의 승낙과 제한)
            <br /> <br />
            회사는 회원이 신청양식의 각 사항을 정확히 기재하고 본 약관에 동의할
            경우 특별한 사정이 없는 한 서비스의 이용신청을 승낙한다
            <br />
            회사는 다음 각 호의 경우에는 회원의 서비스 이용신청의 승낙을 거절할
            수 있다
            <br />
            신청양식의 기재사항에 허위, 기재누락, 도용, 오기가 있는 경우
            <br />
            신용정보의 이용과 보호에 관한 법률에 의한 PC통신, 인터넷 서비스의
            신용불량자로 등록되어 있는 경우
            <br />
            정보통신윤리위원회에 PC통신, 인터넷 서비스의 불량 이용자로 등록되어
            있는 경우
            <br />
            이용 신청자가 본 약관에 의하여 이전에 회원자격을 상실한 사실이 있는
            경우 (다만 회원자격 상실 후 6개월이 경과한 자로서 회사로부터 회원
            재가입 승낙을 얻은 경우는 예외로 한다)
            <br />
            기타 회사가 정한 이용신청의 요건이 만족되지 않은 경우
            <br />
            회사는 다음 각 호의 경우에는 회원의 서비스 이용신청의 승낙을 유보할
            수 있다
            <br />
            서비스 관련 설비의 여유가 없거나 기술상 또는 업무상 문제가 있는 경우
            <br />
            기타 회사의 귀책사유로 이용신청의 승낙이 곤란한 경우
            <br />
            회사가 본 조 제2)항과 제3)항에 따라 회원가입신청의 승낙을 거절하거나
            유보한 경우에는 팝업창을 통하여 즉시 이용신청자에게 알린다 단,
            회사의 귀책사유 없이 이용자에게 알릴 수 없는 경우에는 예외로 한다
            <br /> <br /> <br />
            제7조 (회원정보의 변경)
            <br /> <br />
            회원은 언제든지 자신의 개인정보를 열람하고 수정할 수 있다
            <br />
            회원이 전항의 변경사항을 수정하지 않아 발생한 불이익에 대하여 회사는
            책임지지 않는다
            <br /> <br /> <br />
            제8조 (회원의 아이디 및 비밀번호의 관리에 대한 의무)
            <br /> <br />
            회원은 아이디와 비밀번호에 대한 모든 관리책임이 있으며, 이를
            제3자에게 제공 또는 공유, 공개하여 제3자가 이용하도록 하여서는 안
            된다
            <br />
            회원이 본 조 제1)항을 위반한 경우, 회사는 회원의 자격을 박탈할 수
            있다
            <br />
            회원은 자신의 아이디 및 비밀번호가 유출되어 제3자에 의해 사용되고
            있음을 인지한 경우, 즉시 회사에 알려야 한다
            <br />
            회사는 본 조 제3)항과 같은 상황이 발생한 경우 회원의 개인정보보호 및
            기타 서비스 부정이용행위 방지 등을 위하여 회원에게 비밀번호의 변경
            등 필요한 조치를 요구할 수 있으며, 회원은 회사의 요구가 있는 즉시
            회사의 요청에 성실히 응해야 한다
            <br />
            회사는 회원이 본 조 제2)항 및 제3)항에 따른 의무를 성실히 이행하지
            않아 발생한 불이익에 대하여 책임지지 않는다
            <br /> <br /> <br />
            제9조 (이용계약의 해지)
            <br /> <br />
            회원이 이용계약의 해지를 원할 경우에는 서비스 내의 회원 탈퇴 기능을
            통하여 언제든지 회원탈퇴를 신청할 수 있다 이 경우 회사는 불가피한
            사정이 없는 한 회원의 탈퇴 요청을 지체 없이 처리한다
            <br /> * 회사는 회원이 본 약관, 이용계약, 관계법령 등을 위반하는
            경우에 이용계약을 해지할 수 있다
            <br /> * 본 조에 따라 이용계약이 해지되더라도, 회사 또는 회원에게
            이미 발생한 권리와 의무 및 손해배상청구권에 영향을 미치지 아니한다
            <br /> <br /> <br />
            <b>제3장 서비스의 이용</b>
            <br /> <br /> <br />
            제10조 (서비스의 제공 및 변경)
            <br /> <br />
            회사는 회원의 서비스 이용 신청을 승낙한 때부터 회원에게 서비스를
            제공한다
            <br />
            서비스의 이용은 연중무휴 1일 24시간을 원칙으로 한다 다만, 시스템
            점검, 증설과 교체 및 고장 등의 이유로 회사가 정한 기간에는 서비스가
            일시 중지될 수 있다
            <br />
            이 경우 회사는 사전에 인터넷이나 모바일 등 인터페이스에 해당 사실을
            공지한다
            <br />
            회사가 통제할 수 없는 사유로 인하여 서비스가 중단됨으로써 사전
            공지가 불가능한 경우에는 사후 공지로 갈음한다
            <br />
            회사는 서비스가 변경되는 경우 변경되는 서비스 내용 및 제공일자는
            사이트를 통하여 공지하거나 회원에게 통지한다
            <br /> <br /> <br />
            제11조 (발명자의 권리 및 의무)
            <br /> <br />
            회원은 관계 법령, 본 약관의 규정, 이용안내 및 서비스 상에 공지한
            주의사항, 회사가 통지한 사항을 준수해야 하며, 기타 회사의 업무를
            방해하는 행위를 해여서는 안 된다
            <br />
            회원은 서비스의 이용 전·중·후를 불문하고 아이디어와 관련한 모든
            정보를 투자 외의 목적이나 용도로 사용할 수 없으며, 투자와 관련하여
            사용할 경우에도 필요한 업무 수행의 범위를 초과하여 아이디어를 임의로
            복제, 수정, 저장, 변형 또는 분석하는 등의 행위를 할 수 없다
            <br />
            서비스와 관련 없이 변리사가 이미 알고 있었거나 보유하고 있던 정보
            <br />
            변리사의 귀책 사유에 의하지 아니하고 공지의 사실로 된 정보
            <br />
            변리사가 아이디어를 이용하지 아니하고 독자적으로 개발한 정보
            <br />
            발명자가 명시적으로 비밀유지 의무가 없음을 밝히며 제공한 정보
            <br />
            법원 또는 정부당국의 명령에 의하여 불가피하게 공개하여야 하는 정보
            단, 이 경우에도 필요한 최소한도의 범위 내에서 공개하며, 발명자가
            적절한 구제책을 마련할 수 있도록 즉시 이상의 명령 사실을 통지해야
            한다
            <br />
            회원이 본 조에 따른 비밀유지의무를 위반한 경우, 해당 회원은 관련
            법령에 따라 모든 민·형사상 책임을 진다
            <br />
            회원은 회사가 제공하는 서비스의 지식재산권을 침해하는 행위를
            하여서는 안 된다
            <br />
            본 조 제1)항 내지 제8)항에 따라 회원에게 부과된 의무는 이용계약의
            종료나 각 당사자 간의 관계가 종료한 이후에도 존속한다
            <br /> <br /> <br />
            제12조 (회사의 권리 및 의무)
            <br /> <br />
            회사는 서비스의 신뢰도를 위하여 회원에게 온라인 상의 본인인증 절차를
            거치도록 요구할 수 있다
            <br />
            회사가 제공하는 서비스 및 콘텐츠에 대한 지식재산권은 회사에 귀속된다
            <br />
            회사는 서비스의 향상을 위하여 투자금의 금액 정보, 보수 등의 금액
            정보 등을 익명화 및 비공개화한 후 수집하여 통계에 활용할 수 있다
            <br />
            회사는 본 약관 및 관계법령에 따라 서비스를 제공하여야 하며, 다음 각
            호의 사유가 발생한 경우를 제외하고 계속적, 안정적으로 서비스를
            제공하기 위하여 최선의 노력을 다한다
            <br />
            서비스용 설비의 보수, 정기점검 또는 공사 등으로 인하여 부득이한 경우
            <br />
            전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지한
            경우
            <br />
            전시, 사변, 천재지변 또는 이에 준하는 국가비상사태가 발생하거나
            발생할 우려가 있는 경우
            <br />
            설비장애 또는 이용 폭주 등으로 인하여 서비스 이용에 지장이 있는 경우
            <br />
            회사는 회원으로부터 제기되는 의견이나 불만이 정당하다고 객관적으로
            인정될 경우에는 적절한 절차를 거쳐 즉시 처리하여야 한다 다만, 즉시
            처리가 곤란한 경우에는 회원에게 그 사유와 처리일정을 통보하여야 한다
            <br />
            회사는 회원 사이에 체결한 계약에 따른 업무 수행과정 및 결과물에
            관하여 보증하지 아니하며, 이에 관하여 어떠한 책임도 부담하지 않는다
            <br /> <br /> <br />
            제13조 (회사의 회원에 대한 제재)
            <br /> <br />
            회원이 본 약관의 내용에 위배되는 행동을 한 경우, 임의로 서비스
            사용을 제한 및 중지할 수 있다 이 경우 회사는 회원의 접속을 금지할 수
            있으며, 회원이 게시한 내용의 전부 또는 일부를 임의로 삭제할 수
            있습니다
            <br />
            1년 이상 휴면 회원인 경우에는 안내 메일 또는 공지사항 발표 후 1주일
            간의 통지기간을 거쳐 서비스 사용을 중지할 수 있다
            <br />
            회원이 제공하는 정보의 내용이 허위인 것으로 판명되거나, 허위가
            있다고 의심할만한 합리적인 사유가 발생할 경우 회원의 본 서비스
            사용을 일부 또는 전부 이용 정지할 수 있으며, 이로 인해 발생하는
            불이익에 대해 회사는 책임을 부담하지 않습니다
            <br /> <br /> <br />
            제14조 (회원의 개인정보보호)
            <br /> <br />
            회사는 개인정보보호와 관련된 자세한 사항을 개인정보취급방침으로
            정하고 이를 전자적 표시형태로 제공하여야 한다
            <br />
            회사는 회원의 개인정보에 대하여 철저한 보안을 유지하여야 하고, 오직
            서비스의 운영 및 개선에만 사용하여야 하며, 이외의 목적으로 타 기관
            및
            <br />
            개인에게 제공하지 않는다 다만 다음 각 호에 해당하는 경우에는
            그러하지 아니하다
            <br />
            관계법령에 의해 수사상의 목적으로 관계기관으로부터 요청이 있는 경우
            <br />
            정보통신윤리위원회의 요청이 있는 경우
            <br />
            기타 관계법령에서 정한 절차에 따른 요청이 있는 경우
            <br />
            회사는 회원의 개인정보 관리를 위하여 회원의 동의 하에 개인정보를
            제3자에게 위탁할 수 있다
            <br />
            회사는 회원의 귀책사유로 인하여 노출된 개인정보에 대하여는 어떠한
            책임도 지지 않는다
            <br /> <br /> <br />
            <b>제4장 지식재산권 정책 </b>
            <br /> <br /> <br />
            제15조 (서비스 게시물의 등록 및 관리)
            <br /> <br />
            회원은 약관에서 정하는 범위 및 서비스에서 허용하는 범위 내에서
            자율적으로 게시물을 작성할 수 있다
            <br />
            회원의 게시물이 정보통신망법, 지식재산권 관련 법령 등 법령에
            위반되는 내용을 포함되는 하는 경우, 회사는 관련 법령에 따라 해당
            게시물에 대해 임시조치등을 취할 수 있다
            <br /> <br /> <br />
            제16조 (서비스 게시물의 지식재산권)
            <br /> <br />
            서비스에서 회원이 작성한 모든 게시물은 관련 법령에 따라
            지식재산권으로서 보호된다
            <br />
            타인의 지식재산권에 속하는 대상물을 서비스 내에서 이용할 경우에는
            반드시 해당 권리자의 사전 서면 동의를 받아야 한다
            <br />
            회원이 서비스 내에 게시한 게시물의 지식재산권은 해당 게시물의
            권리자에게 귀속된다
            <br />
            회원이 서비스 내에 게시하는 게시물의 내용은 포털사이트 등 인터넷의
            검색 결과 내지 서비스 프로모션 등에 노출될 수 있으며, 해당 노출을
            위해 필요한 범위 내에서는 일부 수정, 편집되어 게시될 수 있다 다만 이
            경우, 회원은 언제든지 회사에 전화, 팩스, 전자우편 등을 통해
            연락하거나 또는 서비스 내 관리기능을 통해 해당 게시물에 대해 삭제,
            수정, 비공개 등의 조치를 취할 수 있다
            <br />
            회사는 본 조 제4)항 이외의 방법으로 회원의 게시물을 이용하고자 하는
            경우에는 전화, 팩스, 전자우편 등을 통해 사전에 회원의 동의를 얻어야
            한다
            <br /> <br /> <br />
            제17조 (회원 및 제3자의 권리 보호)
            <br /> <br />
            회원은 타인의 지식재산권을 포함한 기타 권리를 침해하여서는 안 된다
            만약 타인의 권리를 침해하는 내용의 게시물로 인하여 발생할 경우, 이에
            대한 모든 책임은 회원 본인에게 있다
            <br />
            회원의 게시물에 대하여 제3자로부터 지식재산권 및 기타 권리가
            침해되었다는 사유로 소송 및 기타 법률적인 이의가 제기된 경우, 해당
            회원은 변호사 비용을 함하여 권리침해로 인하여 야기된 모든 법률적
            쟁점을 해결하기 위한 비용을 부담하는 등 모든 조치를 취하여 회사를
            보호할 의무가 있다
            <br />
            회원의 게시물에 대하여 제3자로부터 지식재산권 및 기타 권리의 침해로
            이의가 제기된 경우, 회사는 당해 게시물을 임시 삭제할 수 있으며,
            이의를 제기한 자와 게시물 등록자 간에 소송·합의 등을 통해 당해
            게시물이 이의를 제기한 자의 권리를 침해하지 않는다는 최종적인 결론이
            도출되고 이의를 제기한 자가 해당 게시물을 다시 게시하여도 좋다는
            명시적인 의사를 밝혔으며 게시물 등록자의 신청이 있는 경우에 한하여
            회사는 위 임시 삭제된 게시물을 다시 등록할 수 있다
            <br />
            회원은 서비스를 이용함으로써 얻은 정보를 회사 및 해당 정보에 대한
            권리자의 사전 승낙 없이 복제, 송신, 출판, 전송, 배포, 방송 기타
            방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 해서는 안
            된다
            <br />
            회사의 서비스에 게재 또는 등록된 회원의 게시물을 제3자가 사용하고자
            할 경우에는 반드시 해당 회원의 명시적인 동의를 얻은 후 사용하여야
            한다 단, 제3자가 본 약관 또는 이용계약에 근거하여 해당 게시물을
            사용할 수 있는 경우에는 그러하지 아니하다
            <br />
            서비스 상의 게시물은 회원 간의 자율적인 의사소통이므로, 회사는
            게시물의 정확성에 대해서는 보증을 하지 않는다
            <br />
            회사는 본 조 제4)항 및 제5)항에서 요구하는 동의 절차를 중개하거나 그
            과정에 개입하지 않는다
            <br /> <br /> <br />
            제18조 (지식재산권 침해 게시물의 게시중단 요청)
            <br /> <br />
            게시물의 내용에 대한 권리자 본인의 지식재산권을 침해한 게시물을
            신고하려는 경우, 그 권리자는 회사에게 전화, 팩스, 전자우편 등을 통해
            신고할 수 있다
            <br />
            회사는 게시중단 요청된 게시물에 대해서는 최대 24시간 이내에 게시중단
            처리를 완료하고 그 결과를 통보한다
            <br /> <br /> <br />
            <b>제5장 회원자격 박탈 및 분쟁의 조정</b>
            <br /> <br /> <br />
            제19조 (회원자격의 박탈)
            <br /> <br />
            회사는 다음 각 호의 경우 회원의 서비스 이용기간을 제한하거나
            회원자격을 예고 없이 박탈할 수 있다
            <br /> * 회원의 개인신상정보가 사실과 다를 경우
            <br /> * 타인에게 ID를 양도, 대여한 경우
            <br /> * 다른 이용자의 ID를 부정 사용한 경우
            <br /> * 불법정보, 유해정보 또는 회사로부터 허가 받지 않은 광고,
            브로커제안, 장난글 등을 게시한 경우
            <br /> * 서비스를 통하여 취득한 다른 회원의 정보를 수집, 저장,
            양도하거나 중개에 이용한 경우
            <br /> * 아이디어를 해당 발명자의 사전 서면 승낙 없이
            복제·편집·전시·전송·배포·판매·방송·공연하는 등의 행위를 한 경우
            <br /> * 회사 직원, 운영자 등을 포함한 타인을 사칭한 경우
            <br /> * 서비스의 운영에 지장을 주거나 줄 우려가 있는 행위를 한 경우
            <br /> * 본 약관을 포함하여 기타 회사가 정한 규정 또는 이용계약을
            위반한 경우
            <br /> * 스토킹 등 다른 회원에게 범죄행위를 한 경우
            <br /> * 컴퓨터 소프트웨어, 하드웨어, 전기통신 장비의 정상적인
            가동을 방해, 파괴할 목적으로 고안된 컴퓨터 바이러스, 기타 다른
            컴퓨터코드, 파일, 프로그램을 포함하고 있는 자료를 게시하거나
            전자우편으로 배포하는 행위를 한 경우
            <br /> * 기타 법령에 위배되는 행위를 한 경우
            <br /> * 회사는 회원의 서비스 이용 권한을 일정 기간 제한하거나 이용
            권한을 박탈한 경우, 회원에게 시정 또는 소명 기회를 부여할 수 있다
            회원의 이의신청이 정당한 경우 회사는 즉시 회원이 서비스를 이용할 수
            있도록 필요한 조치를 취하여야 한다
            <br /> <br /> <br />
            제20조 (면책조항)
            <br /> <br />
            회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할
            수 없거나 제공이 지연된 경우에는 서비스 제공에 관한 책임이 면제된다
            <br />
            회사는 회원의 귀책사유로 인하여 발생한 서비스 이용의 장애에 대해서는
            책임을 지지 않는다
            <br />
            회사는 회원이 서비스와 관련하여 게시 또는 제공한 정보, 자료, 사실의
            신뢰도, 정확성 등의 내용에 대해서는 책임을 지지 않는다
            <br />
            회사는 회원에게 플랫폼을 제공하는 역할만을 담당하며, 회원 간에
            발생하는 분쟁에 대해서는 개입할 수 없고 이로 인한 손해를 배상할
            책임이 없다
            <br />
            회사는 회원 상호간 또는 회원과 제3자 간에 콘텐츠를 매개로 하여
            발생한 분쟁 등에 대하여 책임을 지지 않는다
            <br />
            본 약관의 규정을 위반함으로 인하여 회사에 손해가 발생하는 경우, 해당
            위반자는 회사에 발생되는 모든 손해를 배상하여야 하며, 동 손해로부터
            회사를 면책시켜야 한다
            <br /> <br /> <br />
            제21조 (분쟁의 해결)
            <br /> <br />
            회사와 회원은 서비스와 관련하여 발생한 분쟁을 원만하게 해결하기
            위하여 서로 성실하게 노력하여야 한다
            <br />
            회사가 제공하는 서비스는 합법적인 목적으로만 사용되어야 하며,
            대한민국 법령에 반하는 형태의 사용은 금지된다 회원의 금지된 형태의
            사용으로 인하여 손해가 발생할 경우, 회사는 이에 대하여 책임을 지지
            않는다
            <br />
            회사와 회원 사이에 발생한 소송의 관할법원은 회사의 본사 소재지 관할
            법원으로 한다
            <br />
            회사와 회원 사이에 발생한 소송에는 대한민국 법령을 적용한다
            <br /> <br />
            본 약관은 2017년 12월 1일부터 시행한다
            <br /> <br /> <br /> <br />
            주식회사 지식피플
            <br /> <br />
            대표이사 윤재원, 이정섭
          </p>
        </>
      )}
      {title === t("InformationUse") && (
        <>
          <p>{t("Privacy")}</p>
          <p>
            지식피플은 개인정보보호법에 따라 이용자의 개인정보 보호 및 권익을
            보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록
            다음과 같은 처리 방침을 두고 있습니다. 지식피플은 개인정보
            처리방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여
            공지할 것입니다.
            <br /> <br /> <br />
            제 1조 (기본 원칙)
            <br /> <br />
            주식회사 지식피플(이하 “지식피플”)은 정보통신망 이용촉진 및 정보보호
            등에 관한 법률, 통신비밀보호법, 전기통신사업법, 개인정보보호법 등
            정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호
            규정을 준수하며, 관련 법령에 의거한 개인정보 처리방침을 정하여 회원
            권익 보호에 최선을 다하고 있습니다.
            <br /> <br /> <br />
            제 2조 (최초 회원가입 시 수집하는 개인정보)
            <br /> <br />
            지식피릉은 회원가입 및 관리, 원활한 고충처리, 각종 서비스의 제공을
            위해 최초 회원가입 당시 회원으로부터 다음의 개인정보를 수집하고
            있습니다.
            <br /> <br />
            필수항목: 이메일 주소, 아이디, 비밀번호, 이용 목적
            <br /> <br /> <br />
            제 3조 (서비스 이용 시 수집하는 개인정보)
            <br /> <br />
            지식피플은 원활하고 신뢰할 수 있는 서비스의 제공을 위해 회원가입 후
            아이디어 등록 및 대리인 매칭 절차에 앞서 회원으로부터 다음 각 호의
            개인정보를 수집하고 있습니다.
            <br /> <br />
            (1) 고객(발명자)의 아이디어 등록 시 필수 항목: 성명, 핸드폰 번호,
            사진, 자기소개 등의 정보
            <br /> <br />
            (2) 대리인(변리사)의 참여 시 필수 항목: 사진, 기업
            형태(법인/개인/팀), 성명, 회사명, 대표자명, 성별, 생년월일, 주소,
            핸드폰 번호, 사업자등록증, 대리인 코드, 보유기술, 자격증 등의 정보
            <br /> <br />
            (3) 대리인의 참여 시 선택 항목: 회사 유선전화 번호, 팩스 번호, 경력,
            학력등의 정보
            <br /> <br />
            지식피플은 원활하고 신뢰할 수 있는 서비스의 제공을 위해 계약 체결에
            앞서 회원으로부터 다음의 개인정보를 수집하고 있습니다.
            <br /> <br />
            사업자등록증, 주민등록증, 여권, 기타 신분증 등 신원을 확인할 수 있는
            서류에 기재된 주민등록번호(단, 주민등록번호의 경우 관련 법령에서
            회원의 주민등록번호 수집·이용을 허용하는 경우에 한하여 수집합니다),
            사업자등록번호 등의 정보
            <br /> <br />
            지식피플은 서비스 이용과정에서 추가로 생성되는 다음 각 호의 회원의
            정보들을 수집할 수 있습니다.
            <br /> <br />
            (1) 본적으로 수집되는 정보: IP주소, 쿠키, 기기식별번호(MAC주소 등),
            서비스 이용 기록, 방문 기록, 접속 로그, 불량 이용 기록 등 정보
            <br /> <br />
            (2) 서비스 이용 시 수집되는 정보: 휴대전화 전화번호, 신용카드 정보,
            은행계좌 정보, 결제 기록 등 정보
            <br /> <br /> <br />
            제 4조 (민감한 개인정보의 수집 금지 등)
            <br /> <br />
            지식피플은 회원의 기본적 인권 침해의 우려가 있는 민감한
            개인정보(인종, 사상, 신조, 정치적 성향, 범죄기록, 의료정보 등)는
            수집하지 않으며, 회원이 선택 항목의 정보를 입력하지 않은 경우에도
            지식피플이 제공하는 서비스 이용에 제한은 없습니다.
            <br /> <br /> <br />
            제 5조 (개인정보 수집 방법)
            <br /> <br />
            지식피플은 다음 각 호의 방법으로 개인정보를 수집합니다.
            <br /> <br />
            (1) 지식피플이 제공하는 플랫폼, 서면 양식, 전화/팩스 등 서비스
            가입이나 사용 중 회원의 자발적 제공을 통한 수집
            <br /> <br />
            (2) 생성 정보 수집 툴을 통한 수집
            <br /> <br /> <br />
            제 6조 (개인정보 수집 및 이용 목적)
            <br /> <br />
            지식피플은 다음 각 호의 목적으로 회원의 개인정보를 수집 및
            이용합니다. 수집한 개인정보는 다음의 목적 이외의 용도로 사용되지
            않으며 이용 목적이 변경될 시에는 별도의 사전동의를 구합니다.
            <br /> <br />
            (1) 플랫폼 회원가입 및 관리: 회원 가입의사 확인, 회원제 서비스
            제공에 따른 본인 식별·인증, 회원자격 유지·관리, 제한적 본인 확인제
            시행에 따른 본인확인, 서비스 부정이용 방지, 만14세 미만 아동
            개인정보 수집 시 법정대리인 동의 여부 확인, 각종 고지·통지,
            고충처리, 분쟁 조정을 위한 기록 보존 등의 목적
            <br /> <br />
            (2) 민원사무의 처리: 민원인의 신원 확인, 민원사항 확인, 사실조사를
            위한 연락·통지, 처리결과 통보 등의 목적
            <br /> <br />
            (3) 서비스의 제공: 서비스 제공, 본인 인증, 연령 인증, 채권 추심 등의
            목적
            <br /> <br />
            (4) 마케팅 및 광고에의 활용: 신규 서비스 개발 및 맞춤 서비스 제공,
            이벤트 및 광고성 정보 제공 및 참여기회 제공, 인구통계학적 특성에
            따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 접속빈도 파악
            또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를
            처리합니다.
            <br /> <br /> <br />
            제 7조 (개인정보 제3자 제공의 기본 원칙)
            <br /> <br />
            지식피플은 회원들의 개인정보를 제6조에 고지한 범위 내에서 사용하며,
            회원의 사전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로
            회원의 개인정보를 제3자에 제공하거나 외부에 공개하지 않습니다. 다만,
            다음 각 호의 경우는 예외로 합니다.
            <br /> <br />
            (1) 회원들이 사전에 공개에 동의한 경우
            <br /> <br />
            (2) 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와
            방법에 따라 수사기관의 요구가 있는 경우
            <br /> <br />
            (3) 지식피플이 제공하는 서비스를 통해 거래가 성사되었을 때에는 쌍방
            당사자간의 계약과 관련한 정보를 필요한 범위 내에서 쌍방당사자에게
            제공합니다.
            <br /> <br /> <br />
            제 8조 (예외적 개인정보 제3자 제공)
            <br /> <br />
            지식피플은 현재 개인정보를 제3자에게 제공하거나 외부에 공개하고 있지
            않습니다.
            <br /> <br /> <br />
            제 9조 (개인정보의 취급 위탁)
            <br /> <br />
            지식피플은 현재 개인정보의 취급 등을 외부에 위탁하지 않습니다.
            지식피플이 개인정보의 취급을 제3자에게 위탁하게 될 경우, 다음 각
            호와 같이 처리합니다
            <br /> <br />
            (1) 본 개인정보 처리방침을 통하여 회원들에게 그 사실을 공개합니다.
            <br /> <br />
            (2) 위탁 업무의 내용이나 수탁자가 변경될 경우에는 지체 없이 본
            개인정보 처리방침을 통하여 공개합니다.
            <br /> <br /> <br />
            제 10조 (개인정보 보유 및 이용기간의 기본 원칙)
            <br /> <br />
            원칙적으로 회원의 개인정보는 개인정보의 수집 및 이용목적이 달성되면
            지체 없이 파기됩니다.
            <br /> <br /> <br />
            제 11조 (지식피플 내부 방침에 의한 개인정보의 보유)
            <br /> <br />
            제10조에도 불구하고, 지식피플은 거래 관련 분쟁 방지를 위하여
            지식피플 내부 방침에 따라 회원 가입 및 관리 관련 정보를 다음 각 호의
            기간 동안 보존합니다.
            <br /> <br />
            (1) 원칙: 회원 탈퇴 시까지
            <br /> <br />
            (2) 회원에 대하여 관계 법령 위반에 따른 수사·조사 등이 진행중인
            경우: 해당 수사·조사 종료 시까지
            <br /> <br />
            (3) 지식피플과 회원 사이에 채권·채무관계가 잔존하는 경우: 해당
            채권·채무관계 정산 완료 시까지
            <br /> <br />
            (4) 회원에서 탈퇴한 후 회원 재가입, 임의 해지 등을 반복적으로 행하여
            정당하지 않은 경제상의 이익을 취하거나 이 과정에서 명의를 무단으로
            사용하는 편법과 불법행위를 하는 회원을 차단 하고자 회원 탈퇴 후
            1개월 동안 회원의 성명,주민등록번호(또는 CI), DI, 아이디(ID),
            비밀번호(Password), 이메일(E-mail)정보를 보관 하며, 로그기록, 접속
            아이피(IP)정보는 12개월간 보관합니다.
            <br /> <br /> <br />
            제 12조 (관련 법령에 의한 개인정보의 보유)
            <br /> <br />
            지식피플은 상법, 전자상거래 등에서의 소비자보호에 관한 법률 등
            관계법령의 규정에 의하여 다음 각 호에 따라 회원의 해당 개인정보를
            보관하며, 그 목적의 범위 내에서만 이를 이용합니다. 단, 지식피플
            이용계약(이용약관)에 의해 이용계약이 존속 중인 회원(탈퇴하지 아니한
            회원)의 경우 보관기간은 보존의무기간 이상 보관할 수 있으며, 해당
            기간이 경과된 기록에 대해서는 회원의 삭제 요청이 있는 경우
            파기합니다.
            <br /> <br />
            (1) 계약 또는 청약철회 등에 관한 기록: 전자상거래 등에서의
            소비자보호에 관한 법률에 따라 5년간 보존
            <br /> <br />
            (2) 대금결제 및 재화 등의 공급에 관한 기록: 전자상거래 등에서의
            소비자보호에 관한 법률에 따라 5년간 보존
            <br /> <br />
            (3) 소비자의 불만 또는 분쟁 처리에 관한 기록: 전자상거래 등에서의
            소비자보호에 관한 법률에 따라 3년간 보존
            <br /> <br />
            (4) 표시·광고에 관한 기록: 전자상거래 등에서의 소비자보호에 관한
            법률에 따라 6개월간 보존
            <br /> <br />
            (5) 본인확인에 관한 기록: 정보통신망 이용촉진 및 정보보호 등에 관한
            법률에 따라 6개월간 보존
            <br /> <br />
            (6) 전기통신일시, 개시·종료시간, 가입자 번호, 사용 도수, 발신 기지국
            위치추적자료에 관한 기록: 통신비밀보호법에 따라 1년간 보존
            <br /> <br />
            (7) 컴퓨터통신, 인터넷 로그기록자료, 접속지 추적 자료:
            통신비밀보호법에 따라 3개월간 보존
            <br /> <br />
            (8) 신용정보의 수집/처리 및 이용 등에 관한 기록: 3년
            <br /> <br /> <br />
            제 13조 (개인정보의 파기 절차 및 방법)
            <br /> <br />
            지식피플은 원칙적으로 개인정보 이용 목적이 달성된 경우에는 파기
            사유가 발생한 개인정보를 선정하고, 지식피플의 개인정보 보호책임자의
            승인을 받아 지체 없이 해당 개인정보를 파기합니다. 파기의 절차, 기한
            및 방법은 다음과 같습니다.
            <br /> <br />
            (1) 파기 절차: 이용자가 입력한 정보는 목적 달성 후 별도의 DB에
            옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타 관련 법령에 따라
            일정기간 저장된 후 혹은 즉시 파기됩니다. 이 때, DB로 옮겨진
            개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지
            않습니다.
            <br /> <br />
            (2) 파기 기한: 이용자의 개인정보는 개인정보의 보유기간이 경과된
            경우에는 보유기간의 종료일로부터 5일 이내에, 개인정보의 이용 목적
            달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게
            되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터
            5일 이내에 그 개인정보를 파기합니다.
            <br /> <br />
            (3) 파기 방법: 종이에 기록, 저장된 개인정보는 분쇄기로 분쇄하거나
            소각을 통하여 파기하며, 전자적 파일 형태로 저장된 개인정보는 기록을
            재생할 수 없도록 기술적 방법을 사용하여 삭제합니다.
            <br /> <br /> <br />
            제 14조 (개인정보의 수집, 이용, 제공에 대한 동의 철회)
            <br /> <br />
            회원은 회원 가입 등을 통해 개인정보의 수집, 이용, 제공에 대하여
            동의한 내용을 언제든지 철회할 수 있습니다.
            <br /> <br /> <br />
            제 15조 (회원의 권리와 행사방법)
            <br /> <br />
            회원은 지식피플에 대해 언제든지 다음과 각 호와 같은 권리를 행사할 수
            있습니다.
            <br /> <br />
            (1) 개인정보 열람 요구
            <br /> <br />
            (2) 오류 등이 있을 경우 정정 요구
            <br /> <br />
            (3) 삭제 요구
            <br /> <br />
            (4) 처리 정지 요구
            <br /> <br />
            제1항에 따른 권리 행사는 지식피플에 대해 개인정보 보호법 시행규칙
            별지 제8호 서식에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여
            하실 수 있으며 지식피플은 본인 확인을 위한 요청인의 신분증 사본 등의
            증표를 제시 받아 해당 요구가 진정한 본인의 의사인지 여부를 확인할 수
            있으며, 본인으로 확인되고 개인정보에 오류가 있거나 보존기간을 경과한
            것이 판명되는 등 정정 또는 삭제할 필요가 있다고 인정되는 경우 지체
            없이 그에 따른 조치를 취합니다.
            <br />
            회원이 개인정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는
            지식피플은 정정 또는 삭제를 완료할 때까지 당해 개인정보를 이용하거나
            제공하지 않습니다. 제1항에 따른 권리 행사는 회원의 법정대리인이나
            위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보
            보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하여야 합니다.
            <br /> <br /> <br />
            제 16조 (법정대리인의 권리)
            <br /> <br />
            법정대리인은 만 14세 미만 아동의 개인정보 수집·이용 또는 제공에 대한
            동의를 철회할 수 있으며, 해당 아동이 제공한 개인정보에 대한 열람
            또는 오류의 정정을 요구할 수 있습니다.
            <br />
            유선 또는 서면을 통하여 만 14세 미만 아동인 회원의 법정대리인이
            열람, 증명을 요구하는 경우, 지식피플은 대리관계를 증명하는 위임장 및
            인감증명서, 요청인 및 대리인의 신분증 사본 등의 증표를 제시 받아
            해당 요구를 하는 자가 진정한 법정대리인인지 여부를 확인합니다.
            <br /> <br /> <br />
            제 17조 (지식피플의 개인정보 열람 및 이용 제한)
            <br /> <br />
            회원 또는 법정대리인의 요청에 의해 해지 또는 삭제, 정정된 개인정보는
            제10조 내지 제12조에 명시된 바에 따라 처리되고, 그 외의 용도로 열람
            또는 이용할 수 없도록 처리하고 있습니다.
            <br />
            회원 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세
            미만 아동의 개인정보를 조회하거나 수정할 수 있으며 가입 해지를
            요청할 수도 있습니다.
            <br /> <br /> <br />
            제 18조 (쿠키의 운용)
            <br /> <br />
            지식피플은 회원에게 특화된 맞춤서비스를 제공하기 위해서 회원들의
            정보를 수시로 저장하고 찾아내는 '쿠키(cookie)' 등을 운용합니다.
            쿠키란 웹사이트를 운영하는 데 이용되는 서버가 회원의 브라우저에
            보내는 아주 작은 텍스트 파일로서 회원의 컴퓨터 하드디스크에
            저장되기도 합니다.
            <br />
            지식피플은 회원과 비회원의 접속 빈도나 방문 시간 등을 분석, 회원의
            취향과 관심분야를 파악 및 자취 추적, 각종 이벤트 참여 정도 및 방문
            횟수 파악 등을 통한 타깃 마케팅 및 개인 맞춤 서비스 제공 등의
            목적으로 쿠키를 사용합니다.
            <br />
            회원은 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 회원은 웹
            브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가
            저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도
            있습니다.
            <br />
            제3항에 따라 쿠키 설정을 거부하는 방법으로, 회원은 사용하는 웹
            브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할
            때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.
            인터넷 익스플로러의 경우를 예로 들면, 웹 브라우저 상단의 도구 &lt;
            인터넷 옵션 &gt; 개인정보 메뉴를 통하여 쿠키 설정의 거부가
            가능합니다.
            <br />
            회원이 쿠키 설치를 거부하는 경우 로그인이 필요한 일부 서비스 이용에
            어려움이 있을 수 있습니다.
            <br /> <br /> <br />
            제 19조 (해킹 등에 대비한 대책)
            <br /> <br />
            지식피플은 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에
            필요한 기술적/관리적 및 물리적 조치를 하고 있습니다.
            <br /> <br />
            개인정보 취급 직원의 최소화 및 교육: 개인정보를 취급하는 직원을
            지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을
            시행하고 있습니다.
            <br /> <br />
            정기적인 자체 감사 실시: 개인정보 취급 관련 안정성 확보를 위해
            정기적(분기 1회)으로 자체 감사를 실시하고 있습니다.
            <br /> <br />
            내부관리계획의 수립 및 시행: 개인정보의 안전한 처리를 위하여
            내부관리계획을 수립하고 시행하고 있습니다.
            <br /> <br />
            개인정보의 암호화: 이용자의 개인정보는 비밀번호는 암호화 되어 저장
            및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송
            데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도
            보안기능을 사용하고 있습니다.
            <br /> <br />
            해킹 등에 대비한 기술적 대책: 지식피플은 해킹이나 컴퓨터 바이러스
            등에 의한 개인정보 유출 및 훼손을 막기 위하여 보안프로그램을
            설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에
            시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.
            <br /> <br />
            개인정보에 대한 접근 제한: 개인정보를 처리하는 데이터베이스시스템에
            대한 접근 권한의 부여, 변경, 말소를 통하여 개인정보에 대한
            접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을
            이용하여 외부로부터의 무단 접근을 통제하고 있습니다.
            <br /> <br />
            접속 기록의 보관 및 위변조 방지: 개인정보처리시스템에 접속한 기록을
            최소 6개월 이상 보관, 관리하고 있으며, 접속 기록이 위변조 및 도난,
            분실되지 않도록 보안기능 사용하고 있습니다.
            <br /> <br />
            문서보안을 위한 잠금 장치 사용: 개인정보가 포함된 서류, 보조저장매체
            등을 잠금 장치가 있는 안전한 장소에 보관하고 있습니다.
            <br /> <br />
            비인가자에 대한 출입 통제: 개인정보를 보관하고 있는 물리적 보관
            장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고
            있습니다.
            <br /> <br />
            기타: 그 외 시스템적으로 보안성을 확보하기 위한 가능한 모든 기술적
            장치를 갖추려 노력하고 있습니다.
            <br /> <br /> <br />
            제 20조 (취급 직원의 최소화 및 교육)
            <br /> <br />
            지식피플의 개인정보 관련 취급 직원은 담당자에 한정시키고 있고 이를
            위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 담당자에
            대한 수시 교육을 통하여 지식피플은 개인정보 처리방침의 준수를 항상
            강조하고 있습니다.
            <br /> <br /> <br />
            제 21조 (개인정보보호전담기구의 운영)
            <br /> <br />
            지식피플은 사내 개인정보보호전담기구 등을 통하여 개인정보 처리방침의
            이행사항 및 담당자의 준수여부를 확인하여 문제가 발견될 경우 즉시
            수정하고 바로 잡을 수 있도록 노력하고 있습니다. 단, 회원 본인의
            부주의나 인터넷상의 문제로 개인 정보가 유출되어 발생한 문제에 대해
            지식피플은 일체의 책임을 지지 않습니다.
            <br /> <br /> <br />
            제 22조 (개인정보관리 책임자 및 담당자)
            <br /> <br />
            회원은 지식피플의 서비스를 이용하시며 발생하는 모든 개인정보보호에
            대한 문의, 불만처리, 피해구제 등 관련 민원을 다음 각 호의
            개인정보관리책임자 혹은 담당부서로 신고하실 수 있습니다. 지식피플은
            정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.
            <br /> <br />
            (1)개인정보 보호 책임자
            <br />
            성명: 윤재원, 이정섭
            <br />
            직책: 공동대표
            <br />
            연락처: (031) 890-1422, biz@ziple.biz
            <br />
            ※ 개인정보 보호 담당부서로 연결됩니다
            <br /> <br />
            (2)개인정보 보호 담당부서
            <br />
            부서명: 경영관리팀
            <br />
            담당자: 이정섭
            <br />
            연락처: (031) 890-1422, biz@ziple.biz
            <br /> <br /> <br />
            제 23조 (개인정보 열람 창구)
            <br /> <br />
            회원은 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다.
            지식피플은 회원의 개인정보 열람 청구가 신속하게 처리되도록
            노력하겠습니다.
            <br /> <br />
            부서명: 경영관리팀
            <br />
            담당자: 이정섭
            <br />
            연락처: (031) 890-1422, biz@ziple.biz
            <br /> <br /> <br />
            제 24조 (개인정보 처리방침 관련 고지 및 통지 방법)
            <br /> <br />
            지식피플은 현행 개인정보 처리방침에 대한 내용 추가, 삭제 및 수정이
            있을 시에는 개정 최소 7일 전부터 플랫폼을 통해 변경 이유 및 그
            내용을 고지합니다. 다만, 개인정보의 수집 및 활용 등에 있어 이용자
            권리의 중요한 변경이 있을 경우에는 최소 30일 전에 고지합니다.
            <br />
            지식피플은 회원이 동의한 범위를 넘어 회원의 개인정보를 이용하거나
            제3자에게 취급위탁하기 위해 회원의 추가적인 동의를 얻고자 하는
            때에는 미리 회원에게 개별적으로 서면, 전자우편, 전화 등으로
            해당사항을 고지합니다.
            <br />
            지식피플은 개인정보의 수집, 보관, 처리, 이용, 제공, 관리, 파기 등을
            제3자에게 위탁하는 경우에는 개인정보 처리방침 등을 통하여 그 사실을
            회원에게 고지합니다.
            <br />만 14세 미만 아동의 개인정보를 수집, 이용하거나 제3자에게
            제공하기 위하여 법정대리인의 동의를 받고자 하는 경우에는 전화, 팩스,
            우편, 아동으로 하여금 통지 내용을 법정대리인에게 제출하게 하는 방법,
            법정대리인에게 이메일을 보내 본 개인정보 처리방침을 볼 수 있도록
            연결 (하이퍼링크)시키는 방법 또는 기타 법정대리인에게 고지 내용이
            도달할 수 있는 합리적인 방법을 통하여 법정대리인에게 고지합니다.
          </p>
        </>
      )}
      <button className="terms-modal-close" onClick={() => setModal(false)}>
        {t("Close")}
      </button>
    </div>
  );
}

export default Terms;
