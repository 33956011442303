import React, { useState } from "react";
import "../../../css/view/Accordion.css";

function Accordion({ title, children, type }) {
  const [isOpen, setOpen] = useState(false);
  return (
    <div className="accordion-wrapper">
      {type !== "notice" ? (
        <div
          className={`accordion-title ${isOpen ? "open" : ""}`}
          onClick={() => setOpen(!isOpen)}
        >
          Q&nbsp;&nbsp;&nbsp;&nbsp;{title}
        </div>
      ) : (
        <div
          className={`accordion-title ${isOpen ? "open" : ""}`}
          onClick={() => setOpen(!isOpen)}
        >
          {title}
        </div>
      )}
      <div className={`accordion-item ${!isOpen ? "collapsed" : ""}`}>
        <div className="accordion-content">{children}</div>
      </div>
    </div>
  );
}
export default Accordion;
