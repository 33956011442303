import React from "react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import '../../../css/view/ViewPage.css';

function ViewMenu({ history, menu, setMenu }) {
 const {t} = useTranslation();

  const pageMove = (location) => {
    history.push(location);
  };

  return (
    <div className="view-menu">
      {(history.location.pathname.indexOf("faq") === 1 || history.location.pathname.indexOf("notice") === 1) &&(<>
        <div
          className={menu === "FAQ" && "active-menu"}
          onClick={() => {setMenu("FAQ"); pageMove("/faq")}}
        >
          {t('FAQ')}
        </div>
        <div
        className={menu === "notice" && "active-menu"}
        onClick={() => {setMenu("notice"); pageMove("/notice")}}
      >
        {t('NOTICE')}
      </div>
      </>)
       
}
{ history.location.pathname.indexOf("private") === 1 &&
  (<>
       <div
       className={menu === 'terms' && "active-menu"}
       onClick={() => {setMenu("terms"); pageMove("/private/terms")}}
     >
       {t('Policies')}
     </div>
     <div
          className={menu === 'privacy' && "active-menu"}
          onClick={() => {setMenu("privacy"); pageMove("/private/privacy")}}
        >
          {t('Privacy')}
        </div>
     </>
  )
}
      </div>

  );
}

export default withRouter(ViewMenu);
