import React from "react";
import { useTranslation } from "react-i18next";

import "../../../css/delegate/Category.css";

function Category({ category, setCategory }) {
  const { t } = useTranslation();

  return (
    <div className="category">
      <div className="flex category-box">
        {/* <div className="category-title">{t("Keyword")}&nbsp;&nbsp;&nbsp;|</div> */}
        
        <div className="flex category-sub-box">
        <div
            className={
              t(category) === ""
                ? "category-active category-title"
                : "category-title"
            }
            onClick={() => setCategory("")}
          >
            {t("ViewAll")}
          </div>
          <div
            className={
              t(category) === t("Patent")
                ? "category-active category-title"
                : "category-title"
            }
            onClick={() => setCategory(t("Patent"))}
          >
            {t("Patent")}
          </div>
          <div
            className={
              t(category) === t("Trademark")
                ? "category-active category-title"
                : "category-title"
            }
            onClick={() => setCategory(t("Trademark"))}
          >
            {t("Trademark")}
          </div>
          <div
            className={
              t(category) === t("Design")
                ? "category-active category-title"
                : "category-title"
            }
            onClick={() => setCategory(t("Design"))}
          >
            {t("Design")}
          </div>
          <div
            className={
              t(category) === "IT"
                ? "category-active category-title"
                : "category-title"
            }
            onClick={() => setCategory("IT")}
          >
            IT
          </div>
         
        </div>
        <div className="flex category-sub-box">
        <div
            className={
              t(category) === t("Chemistry")
                ? "category-active category-title"
                : "category-title"
            }
            onClick={() => setCategory(t("Chemistry"))}
          >
            {t("Chemistry")}
          </div>
          <div
            className={
              t(category) === t("Electrical")
                ? "category-active category-title"
                : "category-title"
            }
            onClick={() => setCategory(t("Electrical"))}
          >
            {t("Electrical")}
          </div>
          <div
            className={
              t(category) === t("Information")
                ? "category-active category-title"
                : "category-title"
            }
            onClick={() => setCategory(t("Information"))}
          >
            {t("Information")}
          </div>
          <div
            className={
              t(category) === t("BigData")
                ? "category-active category-title"
                : "category-title"
            }
            onClick={() => setCategory(t("BigData"))}
          >
            {t("BigData")}
          </div>
         
        </div>
      </div>
    </div>
  );
}

export default Category;
