import React from "react";
import { useTranslation } from "react-i18next";

const ModalBodyAlert = ({ msg, setShowModal }) => {
  const { t } = useTranslation();

  const check = () => {
    setShowModal(false);
    if (msg === t("LoginErrorMsg")) {
      window.location.href = "/login";
    }
  };

  return (
    <div className="modal-body-alert">
      <div className="modal-msg-area-alert">
        <p className="alert-msg">{msg}</p>
      </div>
      <div className="modal-btn-area">
        <button className="alert-default-btn dark-blue" onClick={() => check()}>
          {t("Check")}
        </button>
      </div>
    </div>
  );
};

export default ModalBodyAlert;
