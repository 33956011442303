import moment from "moment";
import { downloadHist } from "../services/infoService";

//input값 공백 제거
export const unBlank = (x) => {
  return x.replace(/^\s+|\s+$/gm, ""); // 입력값 공백 제거
};

//날짜 형태변환
export const formatDate = (date) => {
  return moment(date).format("YYYY-MM-DD");
};
//날짜 형태변환
export const formatDotDate = (date) => {
  return moment(date).format("YYYY.MM.DD");
};
//정보라운지 상태값
export const infoProcess = (v) => {
  if (v === "1") {
    return "infoOn";
  } else {
    return "infoOff";
  }
};
//qna 종류
export const ideaDivNm = (val) => {
  switch (val) {
    case "":
      return "IdeaDivText";
    case "01":
      return "Patent";
    case "02":
      return "UtilityModel";
    case "03":
      return "Trademark";
    case "04":
      return "Design";
    case "05":
      return "PreviousInvestigation";
    case "51":
      return "PriorityInvestigation(Patent)";
    case "52":
      return "PriorInvestigation(UtilityModel)";
    case "53":
      return "PriorInvestigation(Trademark)";
    case "54":
      return "PriorInvestigation(Design)";
    case "06":
      return "Review";
    case "61":
      return "Review(Patent)";
    case "62":
      return "Review(UtilityModel)";
    case "63":
      return "Review(Trademark)";
    case "64":
      return "Review(Design)";
    case "07":
      return "PreliminaryApplication";
    case "71":
      return "PreliminaryApplication(Patent)";
    case "72":
      return "PreliminaryApplication(UtilityModel)";
    case "08":
      return "Other";
    case "09":
      return "Litigation(warning)";
    case "91":
      return "Patent";
    case "92":
      return "UtilityModel";
    case "93":
      return "Trademark";
    case "94":
      return "Design";
    default:
      break;
  }
};

export const delegateBtnNm = (val) => {
  switch (val) {
    case "1":
      return "전화번호";
    case "2":
      return "메일";
    case "4":
      return "카카오";
    case "5":
      return "페이스북";
    case "6":
      return "인스타그램";
    case "7":
      return "웹사이트";
    case "8":
      return "일반클릭";
    case "9":
      return "북마크";
    case "10":
      return "지도보기";
    case "11":
      return "공유하기";
    case "12":
      return "펼쳐보기";
    case "13":
      return "사무실 전화";
    default:
      return "";
  }
};

// 메인 기록 남기기
export const recordClick = async (action, url, division) => {
  // action : IOS/Android/Youtube/Instagram/FaceBook/Blog/Call
  // url : 주소
  // division : openNm과 office 사이트 기록을 위한 구분자
  if (action !== "Call" && action !== "Mail") {
    window.open(url, "_blank");
  }

  window.gtag("event", openNm(division) + "버튼 클릭", {
    eventCategory: action,
    eventLabel: "다운로드",
    eventAction: action + "버튼 클릭",
    eventValue: 1,
  });

  const form = { platform: url, div: division };
  await downloadHist(form).then((res) => {});
};

export const openNm = (val) => {
  switch (val) {
    case "1a":
      return "안드로이드 다운";
    case "1a_e":
      return "안드로이드 영어 다운";
    case "1i":
      return "아이폰 다운";
    case "1i_e":
      return "아이폰 영어 다운";
    case "3":
      return "유튜브";
    case "6t":
      return "사무실 전화";
    case "6t_e":
      return "사무실 전화 영어";
    case "6m":
      return "메일";
    case "6m_e":
      return "메일 영어";
    case "7f":
      return "페이스북";
    case "7f_e":
      return "페이스북영어";
    case "7i":
      return "인스타그램 ";
    case "7i_e":
      return "인스타그램영어";
    case "7b":
      return "블로그";
    case "7b_e":
      return "블로그영어";
    default:
      return "";
  }
};

//이메일 정규식
export const validateEmail = (email) => {
  if (email !== undefined && email.trim() !== "") {
    const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexp.test(email);
  }
  return false;
};

export const choiceRandomColor = (number) => {
  // let min = 100;
  // let max = 1000;
  // let rand =  min + (Math.random() * (max-min));
   if(number<= 9){
    return "#" +number.toString()+"00" ;
  }else if(number<=99){
    return "#" + number.toString()+"0" ;
  }else if(number<= 999){
    return "#" + number.toString() ;
  }else{
    return "#" + number.toString().substr(1);
  }
 
};

//분야
export const getProfessional = (profField,ipMandate) => {
  var filepath = "/images/delegate/";
  let flagUrls = [];
  let field = [];
  let flag;
  let backgroundColor= [];
  let url = profField.concat(ipMandate);
  url.split(",").forEach((v) => {
    let value = v.toLowerCase().trim();
    if (value === "특허" || value === "patent" || value === "patents") {
      flag = "patent.png";
      field.push(value);
      backgroundColor.push("#D0D09E");
      flagUrls.push(filepath + flag);
    } else if (value === "상표" || value === "brand" || value === "trademark") {
      flag = "brand.png";
      field.push(value);
      backgroundColor.push("#99B6E3");
      flagUrls.push(filepath + flag);
    } else if (value === "디자인" || value === "design") {
      flag = "design.png";
      field.push(value);
      backgroundColor.push("#B8C3A7");
      flagUrls.push(filepath + flag);
    } else if (value === "실용신안" || value === "utility") {
      flag = "patent.png";
      field.push(value);
      backgroundColor.push("#ddbbbb");
      flagUrls.push(filepath + flag);
    } else if (
      value === "전기" ||
      value === "전자" ||
      value === "전기전자" || value === "전기/전자부품" ||
      value === "electronic"
    ) {
      flag = "electronic.png";
      field.push(value);
      backgroundColor.push("#A0A0B9");
      flagUrls.push(filepath + flag);
    } else if (value === "화학" || value === "chemistry") {
      flag = "chemistry.png";
      field.push(value);
      backgroundColor.push("#ccddbb");
      flagUrls.push(filepath + flag);
    } else if (value === "출원" || value === "application") {
      flag = "application.png";
      field.push(value);
      backgroundColor.push("#E7BD8B");
      flagUrls.push(filepath + flag);
    } else if (value === "IT") {
      flag = "it.png";
      field.push(value);
      backgroundColor.push("#bbd4dd");
      flagUrls.push(filepath + flag);
    } else if (value === "UX/UI" || value === "UI/UX") {
      flag = "ux.png";
      field.push(value);
      backgroundColor.push("#cce5ee");
      flagUrls.push(filepath + flag);
    } else if (value === "스타트업" || value === "startup") {
      flag = "startup.png";
      field.push(value);
      backgroundColor.push("#B9B2A0");
      flagUrls.push(filepath + flag);
    }else if (value === "빅데이터" || value === "빅데이터/인공지능" || value === "big data" || value === "big data/artificial intelligence") {
      flag = "big data.png";
      field.push(value);
      backgroundColor.push("#c3ddbb");
      flagUrls.push(filepath + flag);
    } 
    else if (value === "반도체" || value === "semiconductor") {
      flag = "semiconductor.png";
      field.push(value);
      backgroundColor.push("#bbddc3");
      flagUrls.push(filepath + flag);
    }
    else if (value === "설비/공정" || value === "equipment/process") {
      flag = "process.png";
      field.push(value);
      backgroundColor.push("#bbbbdd");
      flagUrls.push(filepath + flag);
    }
    else if (value === "전기차" || value === "electric car") {
      flag = "electricCar.png";
      field.push(value);
      backgroundColor.push("#ddbbcc");
      flagUrls.push(filepath + flag);
    }
    else if (value === "화장품" ) {
      flag = "cosmetics.png";
      field.push(value);
      backgroundColor.push("#B9A0AF");
      flagUrls.push(filepath + flag);
    }
    else if (value === "식품" ) {
      flag = "food.png";
      field.push(value);
      backgroundColor.push("#c3bbdd");
      flagUrls.push(filepath + flag);
    }
    else if (value === "음식점" ) {
      flag = "restaurant.png";
      field.push(value);
      backgroundColor.push("#eeddcc");
      flagUrls.push(filepath + flag);
    }
    else if (value === "간판" ) {
      flag = "sign.png";
      field.push(value);
      backgroundColor.push("#eed4cc");
      flagUrls.push(filepath + flag);
    }
    else if (value === "귀금속" ) {
      flag = "precious.png";
      field.push(value);
      backgroundColor.push("#ddccbb");
      flagUrls.push(filepath + flag);
    }
    else if (value === "공구" ) {
      flag = "tools.png";
      field.push(value);
      backgroundColor.push("#ddc3bb");
      flagUrls.push(filepath + flag);
    }
    else if (value === "생활용품" ) {
      flag = "household_goods.png";
      field.push(value);
      backgroundColor.push("#ddd4bb");
      flagUrls.push(filepath + flag);
    }
      else if (value === "소송") {
      flag = "lawsuit.png";
      field.push(value);
      backgroundColor.push("#B0B4D6");
      flagUrls.push(filepath + flag);
    }
    else {
      flag = ""; 
      // flagUrls.push(flag);
    }
    // } else if (value === "게임") {
    //   flag = "game.png";
    //   flagUrls.push(filepath + flag);
    // } else if (value === "심판") {
    //   flag = "referee.png";
    //   flagUrls.push(filepath + flag);
    // }
    // else if (value === "BM") {
    //   flag = "bm.png";
    //   flagUrls.push(filepath + flag);
    // }
    // else if (value === "기계" || value === "기계설비") {
    //   flag = "machine.png";
    //   flagUrls.push(filepath + flag);
    // }
   

    // } else if (value === "드론") {
    //   flag = "drone.png";
    //   flagUrls.push(filepath + flag);
    // } else if (value === "컨설팅") {
    //   flag = "consulting.png";
    //   flagUrls.push(filepath + flag);
  });
  const uniqueArr = flagUrls.filter((element, index) => {
    return flagUrls.indexOf(element) === index;
});
const uniqueArr2 = field.filter((element, index) => {
  return field.indexOf(element) === index;
});
const uniqueArr3 = backgroundColor.filter((element, index) => {
  return backgroundColor.indexOf(element) === index;
});
 
return { flagUrls: uniqueArr, field: uniqueArr2,  backgroundColor: uniqueArr3};
};
