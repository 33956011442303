import React, { useState } from "react";
import { useTranslation } from "react-i18next";
// import { FacebookShareButton } from "react-share";
import sns from "../../images/message.png";
import URL from "../../images/link.png";
import mail from "../../images/Email_d.png";
import "../../css/common/Common.css";
// import face from "../../images/face.png";
const ShareButtons = ({ msg, link, content, setShowModal2, user ,phoneNo}) => {
  const { t } = useTranslation();
  const [copy, setCopy] = useState("");
  const mailurl = "mailto:" + user.email + "?subject=ziple대리인 광고" + "&body=" + content;

  const copyclip = () => {
    var textField = document.createElement("textarea");
    textField.innerText = window.location.href;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    setCopy(t('CopyComplete'));
    textField.remove();
    setTimeout(() => {
      setCopy("");
    }, 1000);
  };
  const checkMobile = () => {
    var varUA = navigator.userAgent.toLowerCase(); //userAgent 값 얻기

    if (varUA.indexOf("android") > -1) {
      //안드로이드
      return "android";
    } else if (
      varUA.indexOf("iphone") > -1 ||
      varUA.indexOf("ipad") > -1 ||
      varUA.indexOf("ipod") > -1
    ) {
      //IOS
      return "ios";
    } else {
      //아이폰, 안드로이드 외
      return "other";
    }
  };
  let snsUrl =
    "sms:" +phoneNo+ (checkMobile() === "ios" ? "&" : "?") + "body=%0d%0a" + content;
  return (
    <div className="modal-body-alert">
      <div className="modal-body-header share-modal">{msg}</div>
      <div className="modal-msg-area" style={{height:'55%'}}>
        <div className="ShareButtons flex">
          <div onClick={() => {
              copyclip();
            }}>
          
            <img src={URL} alt="urlcopy" /><br/>
            <span>URL {t('Copy')}{copy}</span>
          </div>
          <div >
          <a href={mailurl}>
            <img src={mail} alt="mail" /><br/>
            <span>{t('Email')}</span>
          </a>
          </div>
          <div>
          <a href={snsUrl}>
            <img src={sns} alt="sns" /><br/>
            <span>{t('Sns')}</span>
          </a>
          </div>
          {/*<div>
           <FacebookShareButton url={link} quote={content} hashtag="#ziple">
           <FacebookIcon size={iconSize} /> 
            <img src={face} alt="facebook" />
            <br />
            <span>{t('Write')}</span>
          </FacebookShareButton>
          </div>
           */}
        </div>
      </div>
      <div className="modal-btn-area2">
        <button
          className="alert-default-btn"
          onClick={() => {setShowModal2(false);}}
        >
          {t("Close")}
        </button>
      </div>
    </div>
  );
};

export default ShareButtons;
