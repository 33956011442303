import React from "react";
import { useTranslation } from "react-i18next";
import { recordClick } from "../../../common/util";
import youtube from '../../../images/youtube_c.png';
import icon from '../../../images/youtube_icon.png';
function Youtube() {
  const { t } = useTranslation();

  return (
    <div className="ziple-video">
       <div className="flex">
       <img src={icon} alt="search" style={{ marginRight: 10 }} />
      <p className="main-youtube-title">{t("Main-Video")} </p>
    
      {/* <img
            className="ziple-video-image"
            src={youtube}
            alt="유투브" 
            onClick={() =>
              recordClick(
                "Youtube",
                "https://www.youtube.com/channel/UC_hEmwHj-FZ_epHT2xCu5Ng",
                t("Youtube")
              )
            }
          /> */}
          </div>

      <div className="youtube-iframe">
        <iframe
          title="자이플소개"
          className="youtube-frame"
          src="https://www.youtube.com/embed/HL7WN8ZAUbM"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen={true}
        />
        <iframe
          title="특허관련인터뷰1"
          className="youtube-frame"
          src="https://www.youtube.com/embed/6AiN-YIMMD0"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen={true}
        />
        <iframe
          title="특허관련인터뷰2"
          className="youtube-frame"
          src="https://www.youtube.com/embed/M_Ghh__AxzQ"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen={true}
        />
      </div>
    </div>
  );
}

export default Youtube;
