import { server } from "../config/config";
import axios from "axios";

let currentToken = "";
let user = JSON.parse(localStorage.getItem("ziple_user"));

if (user !== null) {
  currentToken = user.token;
}

//서버연결
const http = axios.create({
  baseURL: server,
});

// 401 에러 발생시 == user 정보 없을 시, 로그인 페이지로 이동
http.interceptors.response.use(
  (res) => {
    return res;
  },
  (e) => {
    if (e.response.status === 401) {
      localStorage.removeItem("ziple_user");
      window.location = "/";
    } else {
      return e;
    }
  }
);

// 로그인
export const userLogin = async (form) => {
  try {
    return await http
      .post(`/app/login`, form, {
        "Content-Type": "application/json",
      })
      .then((res) => res.data);
  } catch (e) {
    console.log(e);
  }
};

//facebookLogin
export const loginFacebook = async (form) => {
  try {
    return await http
      .post(`/app/loginFacebook`, form, {
        "Content-Type": "application/json",
      })
      .then((res) => res.data);
  } catch (e) {
    console.log(e);
  }
};

//facebook id 확인
export const FacebookId = async (value) => {
  try {
    const form = { facebookId: value };
    return await http
      .get(`/app/facebookId`, {
        headers: {
          "Content-Type": "application/json",
        },
        params: form,
      })
      .then((res) => res.data);
  } catch (e) {
    console.log(e);
  }
};

// 카카오 로그인
export const kakaoLogin = async (form) => {
  try {
    return await http
      .post(`/app/kakao`, form, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => res.data);
  } catch (e) {
    console.log(e);
  }
};

// 회원가입
export const userSignUp = async (form) => {
  try {
    return await http
      .post(`/app/user`, form, {
        "Content-Type": "application/json",
      })
      .then((res) => res);
  } catch (e) {
    console.log(e);
  }
};

// 화원가입 유저 이메일 중복체크
export const userEmailCheck = async (email) => {
  try {
    return await http
      .get(`/admin/user/emailcheck?email=` + email, {
        "Content-Type": "application/json",
      })
      .then((res) => res.data);
  } catch (e) {
    console.log(e);
  }
};

// 회원가입 핸드폰 인증
export const authPhone = async (phone) => {
  try {
    return await http
      .post(`/user/auth/sms`, phone, {
        headers: { "Content-Type": "text/plain" },
      })
      .then((res) => res.data);
  } catch (e) {}
};

// // 회원 비밀번호 찾기 자이플 DB에 있는 이메일인지 조회
export const emailCheck = async (email,name) => {
  try {
    return await http
      .get(`/user/email/check?email=${email}&userNm=${name}`)
      .then((response) => response.data);
  } catch (e) {}
};

// 비밀번호 재설정 이메일 발송
export const sendPasswordEmail = async (email) => {
  try {
    return await http
      .put(`/user/email`, email)
      .then((response) => response.data);
  } catch (e) {}
};

//회원 로그아웃
export const userLogout = async (form) => {
  try {
    return await http
      .put(`/app/logout`, form, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentToken,
        },
      })
      .then((res) => res.data);
  } catch (e) {
    console.log(e);
  }
};

// 회원 탈퇴
export const userSignOut = async (form) => {
  try {
    return await http
      .put(`/api/userexit`, form, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentToken,
        },
      })
      .then((res) => res.data);
  } catch (e) {
    console.log(e);
  }
};

// 휴면 해제
export const userRelease = async (userNo) => {
  try {
    return await http
      .put(`/user/release/${userNo}`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => res.data);
  } catch (e) {
    console.log(e);
  }
};
