// 이메일 형식 검증 true 이면 이메일 형식이다.
export const checkRealEmail = (str) => {
  var pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

  if (!pattern.test(str)) {
    return false;
  } else {
    return true;
  }
};

// 비밀번호 형식 : 숫자+영문+특수문자 조합 최소 8자
export const checkPassword = (str) => {
  var pattern = /^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

  if (!pattern.test(str)) {
    return false;
  } else {
    return true;
  }
};

// 특수문자 제거 : 숫자+영문+한글 허용
export const removeSpecialSymbol = (str) => {
  var pattern = /[^(가-힣ㄱ-ㅎㅏ-ㅣa-zA-Z0-9)]/gi;
  if (pattern.test(str)) {
    return str.replace(pattern, "");
  }
  return str;
};

// 숫자만 입력
export const onlyNumber = (str) => {
  return str.replace(/[^0-9.]/g, "").replace(/(\.*)\./g, "$1");
};

// 핸드폰 번호인지 확인
export const checkPhoneNumber = (str) => {
  let check = /^(?:(010\d{4})|(01[1|6|7|8|9]\d{3,4}))(\d{4})$/;
  return check.test(str);
};

// 핸드폰 번호 하이픈 표시
export const phoneHyphen = (str) => {
  if (str.length === 10) {
    return str.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
  }
  if (str.length === 11) {
    return str.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
  }
};
