import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Dot from "../../../images/dot.png";
import Profile from "../../../images/profile.png";

import Modal from "../../../common/Modal";
import Confirm from "../../../common/ConfirmModal";
import SubMenu from "./SubButton";

import { formatDotDate, choiceRandomColor } from "../../../common/util";
import { qnaReplyDelete } from "../../../services/qnaService";

function ReplyItem({ reply, setData, user, action, setAction }) {
  const { t } = useTranslation();
  const [subMenu, setSubMenu] = useState(false);
  const [idata, setIData] = useState({});
  const [subAction, setSubAction] = useState("");
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState("");
  const [result, setResult] = useState(false);

  const replyDelete = () => {
    qnaReplyDelete({
      qnaNo: reply.qnaNo,
      qnaReplyNo: reply.qnaReplyNo,
    }).then((res) => {
      if (res.resCd === "0000") {
        setSubMenu(false);
        setAction("답글삭제");
        setSubAction("");
      }
    });
  };

  const replyModify = () => {
    setData({ content: idata.content, replyNo: idata.replyNo });
    setSubMenu(false);
    setSubAction("");
    // setData({ content: reply.content, replyNo: reply.qnaReplyNo });
    setAction(t("ReplyUp"));
  };

  useEffect(() => {
    if (subAction === "답글수정") {
      setIData({ replyNo: reply.qnaReplyNo, content: reply.content });
      // replyModify();
    }
    if (subAction === "답글삭제") {
      setMessage(t("DeleteReplyMsg"));
      setModal(true);
    }
  }, [subAction]);

  useEffect(() => {
    if (result) {
      if (message === t("DeleteReplyMsg")) {
        replyDelete();
      }
    } else {
      setSubMenu(false);
      setSubAction("");
    }
  }, [result, modal]);

  return (
    <>
      <div className="question-detail-wrap">
        <div>
          <div className="qna-detail-profile-wrap">
            <div className="question-detail-profile-image">
              {reply.profileUrl.indexOf("gravatar") === -1 ? (
                <img
                  src={reply.profileUrl}
                  alt="유저 프로필"
                  onError={(e) => {
                    e.target.onError = null;
                    e.target.src = Profile;
                  }}
                />
              ) : (
                <div
                  className="qna-color-profile"
                  style={{ background: choiceRandomColor(reply.userNo) }}
                >
                  {reply.nickNm.substr(0, 1)}*
                </div>
              )}
            </div>
          </div>
          <div className="qna-detail-writer">
            <div className="flex">
              <p>
                {reply.userDivCd === "2" ? (
                  <>
                    {reply.userNm} {t("Attorney")}
                  </>
                ) : (
                  <>{reply.nickNm}</>
                )}
              </p>
              {user === reply.userNo && (
                <div>
                  <img
                    className="sub-menu-image"
                    src={Dot}
                    alt="수정_삭제"
                    onClick={() => setSubMenu(!subMenu)}
                  />
                  {subMenu && <SubMenu setAction={setSubAction} type="답글" />}
                </div>
              )}
            </div>
            <div className="qna-detail-content">{reply.content}</div>
            {subAction === "답글수정" && (
              <div className="question-reply-update-box flex">
                <textarea
                  value={idata.content}
                  onChange={(e) =>
                    setIData({
                      replyNo: reply.qnaReplyNo,
                      content: e.target.value,
                    })
                  }
                />
                <div onClick={() => replyModify()}>{t("Modify")}</div>
              </div>
            )}
            <div className="qna-detail-content qna-reply-date">
              {formatDotDate(reply.regDate)}
            </div>
          </div>
        </div>
      </div>

      {modal && (
        <Modal>
          <Confirm
            msg={message}
            setShowModal={setModal}
            setResult={setResult}
          />
        </Modal>
      )}
    </>
  );
}

export default ReplyItem;
