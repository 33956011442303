import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Modal from "../../../common/Modal";
import Alert from "../../../common/AlertModal";
import Timer from "../../../common/Timer";

import { authPhone } from "../../../services/loginService";
import { userEmailCheck } from "../../../services/loginService";

import {
  checkRealEmail,
  checkPassword,
  removeSpecialSymbol,
  onlyNumber,
  checkPhoneNumber,
} from "../../../util/inputUtil";

function SignUpForm({ form, setForm }) {
  const { t } = useTranslation();
  const [message, setMessage] = useState("");
  const [modal, setModal] = useState(false);
  const [authNumber, setAuthNumber] = useState("");
  const [startTimer, setStartTimer] = useState(false);
  const [resetTimer, setResetTimer] = useState(false);
  const [expire, setExpire] = useState(false);
  const [showRepeatBtn, setShowRepeatBtn] = useState(false);
  const [repeatCerti, setRepeatCerti] = useState(false);

  const sameEmailCheck = (mail) => {
    if (mail.length > 1 && checkRealEmail(mail)) {
      userEmailCheck(mail).then((res) => {
        if (res.resCd !== "0000") {
          setForm({
            ...form,
            checkEmail: false,
            email: mail,
            conditionEmail: checkRealEmail(mail),
          });
          return;
        }
      });
    }
    setForm({
      ...form,
      checkEmail: true,
      email: mail,
      conditionEmail: checkRealEmail(mail),
    });
  };

  const sendCertiNumber = () => {
    // 휴대폰 번호 11자리인지 확인 후 인증번호 전송
    if (checkPhoneNumber(form.phone) !== false) {
      setMessage(t("SendAuthAlert"));
      setModal(true);
      setStartTimer(true);
      authPhone(form.phone).then((res) => {
        setAuthNumber(res.authCode);
      });
      setShowRepeatBtn(true);
    } else {
      // if (form.phone.length >= 3 && checkPhoneNumber(form.phone) === false) {
      setMessage(`${t("PhoneNoCheck1")}`);
      setModal(true);
      return;
      // }
    }
  };

  const sendCertiNumberRepeat = async () => {
    if (form.phone.length <= 3 || checkPhoneNumber(form.phone) === false) {
      setMessage(t("PhoneNoCheck1"));
      setModal(true);
      return;
    }
    setMessage(t("SendAuthAlert"));
    setModal(true);
    setRepeatCerti(true);
    setResetTimer(true);
    setExpire(false);
    await authPhone(form.phone).then((res) => {
      setAuthNumber(res.authCode);
      setResetTimer(false);
    });
  };

  const checkCertiNumber = () => {
    if (form.phone.length <= 3 || checkPhoneNumber(form.phone) === false) {
      setMessage(t("PhoneNoCheck1"));
      setModal(true);
      return;
    }
    if (form.certiNumber === "") {
      setMessage(t("AuthInsert"));
      setModal(true);
      return;
    }
    // 입력한 인증번호와, 서버에서 받아온 인증번호가 일치한지 확인
    if (form.certiNumber !== "") {
      if (!expire && form.certiNumber === authNumber) {
        setMessage(t("AuthSuccess"));
        setModal(true);
        setStartTimer(false);
        setForm({
          ...form,
          certi: true,
        });
      } else if (!expire && form.certiNumber !== authNumber) {
        setMessage(`${t("AuthFail")}`);
        setModal(true);
      } else {
        setMessage(t("AuthTimerFail"));
        setModal(true);
      }
    } else if (authNumber !== "") {
      setMessage(t("AuthInsert"));
      setModal(true);
    } else {
      setMessage(t("AuthInsert"));
      setModal(true);
    }
  };

  return (
    <>
      <div
        className={
          form.checkEmail && form.conditionEmail
            ? "common-input-box"
            : "common-input-box sign-up-red-line"
        }
      >
        <p>{t("Email")}</p>
        <input
          type="text"
          placeholder={t("EmailIn")}
          value={form.email}
          maxLength="100"
          onChange={(e) => {
            sameEmailCheck(e.target.value);
          }}
        />
        {form.email.length > 1 && form.checkEmail === false && (
          <div className="sign-up-confirm-message">{t("DuplicateEmail")}</div>
        )}
        {form.email.length > 1 && form.conditionEmail === false && (
          <div className="sign-up-confirm-message">{t("EmailInE")}</div>
        )}
      </div>

      <div
        className={
          form.conditionPassword
            ? "common-input-box"
            : "common-input-box sign-up-red-line"
        }
      >
        <p>{t("Password")}</p>
        <input
          type="password"
          placeholder={t("PasswordIn")}
          value={form.password}
          maxLength="20"
          onChange={(e) => {
            setForm({
              ...form,
              password: e.target.value,
              conditionPassword: checkPassword(e.target.value),
            });
          }}
        />
      </div>

      <div
        className={
          form.checkPassword === "" || form.password === form.checkPassword
            ? "common-input-box"
            : "common-input-box sign-up-red-line"
        }
      >
        <p>{t("PasswordCheck")}</p>
        <input
          type="password"
          placeholder={t("PasswordCheck")}
          value={form.checkPassword}
          maxLength="20"
          onChange={(e) => {
            setForm({
              ...form,
              checkPassword: e.target.value,
              conditionPassword: checkPassword(e.target.value),
            });
          }}
        />
      </div>

      <div className="common-input-box">
        <p>{t("Name")}</p>
        <input
          type="text"
          placeholder={t("NameIn")}
          value={form.name}
          maxLength="20"
          onChange={(e) => {
            setForm({
              ...form,
              name: removeSpecialSymbol(e.target.value),
            });
          }}
        />
      </div>

      <div className="common-input-box">
        <p>{t("PhoneNo")}</p>
        <div className="common-input-button-box flex">
          <input
            placeholder={t("PhoneNoIn")}
            value={form.phone}
            maxLength="11"
            onChange={(e) => {
              setForm({
                ...form,
                phone: onlyNumber(e.target.value),
              });
            }}
          />
          {startTimer && (
            <Timer
              mm="3"
              ss="00"
              setExpire={setExpire}
              resetTimer={resetTimer}
            />
          )}
          {showRepeatBtn ? (
            <button
              onClick={sendCertiNumberRepeat}
              disabled={repeatCerti ? true : false}
            >
              {t("SendAuthRe")}
            </button>
          ) : (
            <button onClick={sendCertiNumber}>{t("SendAuth")}</button>
          )}
        </div>
      </div>

      <div className="common-input-box">
        <div className="common-input-button-box flex">
          <input
            type="text"
            placeholder={t("AuthIn")}
            value={form.certiNumber}
            maxLength="6"
            onChange={(e) => {
              setForm({
                ...form,
                certiNumber: onlyNumber(e.target.value),
              });
            }}
          />
          <button onClick={checkCertiNumber}>{t("Ok")}</button>
        </div>
      </div>

      {modal && (
        <Modal>
          <Alert msg={message} setShowModal={setModal} />
        </Modal>
      )}
    </>
  );
}

export default SignUpForm;
