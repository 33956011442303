import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { faqList } from "../../../services/qnaService";
import Accordion from "./Accordion";
import "../../../css/view/ViewPage.css";

function Faq() {
  const { t } = useTranslation();
  const [faq, setFaq] = useState([]);
  let lang = localStorage.getItem("language");
  useEffect(() => {
    faqList().then((res) => {
      setFaq(res.lists);
    });
  }, []);

  return (
    <div className="white view-page-box">
      <div className="faq-page-title">
        <p>{t("FAQ")}</p>
      </div>
      <div className="faq-page-Content">
        
        {faq.map((faq) => (
          <Accordion
            type={'faq'}
            key={faq.faqNo}
            title={lang === "ko" ? faq.title : faq.titleEn}
            children={lang === "ko" ? faq.contents : faq.contentsEn}
          />
        ))}
      </div>
    </div>
  );
}

export default Faq;
