import { server } from "../config/config";
import axios from "axios";
let currentToken = "";
let user = JSON.parse(localStorage.getItem("ziple_user"));

if (user !== null) {
  currentToken = user.token;
}

//서버연결
const http = axios.create({
  baseURL: server,
});

// 401 에러 발생시
http.interceptors.response.use(
  (res) => {
    return res;
  },
  (e) => {
    if (e.response.status === 401) {
      localStorage.removeItem("ziple_user");
      window.location = "/";
    } else {
      return e;
    }
  }
);

// qna 리스트 조회
export const getQnaList = async (form) => {
  try {
    return await http
      .get(`/qna/list`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentToken,
        },
        params: form,
      })
      .then((res) => res.data);
  } catch (e) {
    console.log(e);
  }
};
export const getReplyList = async (form) =>{
  try {
    return await http
      .get(`/qnareply/select`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentToken,
        },
        params: form,
      })
      .then((res) => res.data);
  } catch (e) {
    console.log(e);
  }
}

// qna 등록
export const qnaPost = async (form) => {
  try {
    return await http
      .post(`/qna/post`, form, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentToken,
        },
      })
      .then((res) => res.data);
  } catch (e) {
    console.log(e);
  }
};

// qna 상세정보
export const getQnaDetailList = async (form) => {
  try {
    return await http
      .get(`/qna/select`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentToken,
        },
        params: form,
      })
      .then((res) => res.data);
  } catch (e) {
    console.log(e);
  }
};

//qna 수정
export const QnaUpdate = async (form) => {
  try {
    return await http
      .post(`/qna/questionUpdate`, form, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentToken,
        },
      })
      .then((res) => res.data);
  } catch (e) {
    console.log(e);
  }
};

// qna 삭제
export const qnaDelete = async (form) => {
  try {
    return await http
      .post(`/qna/questionDelete`, form, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentToken,
        },
      })
      .then((res) => res.data);
  } catch (e) {
    console.log(e);
  }
};

// qna 답변 등록
export const qnaReplyInsert = async (form) => {
  try {
    return await http
      .post(`/qna/replyInsert`, form, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentToken,
        },
      })
      .then((res) => res.data);
  } catch (e) {
    console.log(e);
  }
};

// qna 답변 삭제
export const qnaReplyDelete = async (form) => {
  try {
    return await http
      .post(`/qna/replyDelete`, form, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentToken,
        },
      })
      .then((res) => res.data);
  } catch (e) {
    console.log(e);
  }
};
// qna 답변 수정 
export const qnaReplyModify = async (form) => {
  try {
    return await http
      .post(`/qna/replyUpdate`, form, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentToken,
        },
      })
      .then((res) => res.data);
  } catch (e) {
    console.log(e);
  }
};

// faq
export const faqList = async () => {
  try {
    return await http
      .get(`/faq/list`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + currentToken,
        },
      })
      .then((res) => res.data);
  } catch (e) {
    console.log(e);
  }
};
