import React from "react";
import { useTranslation } from "react-i18next";
import service_1 from '../../../images/service_step_1.png';
import service_2 from '../../../images/service_step_2.png';
import service_3 from '../../../images/service_step_3.png';
import service_4 from '../../../images/service_step_4.png';


import Service_KR from "../../../images/main_service_kr.jpg";
import Serbice_EN from "../../../images/main_service_en.jpg";
import icon from '../../../images/phone_icon.png';

function Service() {
  const { t } = useTranslation();
  const language = localStorage.getItem("language");
  
  return (
    <div className="ziple-service">
        <div className="flex">
       <img src={icon} alt="search" style={{ marginRight: 10 }} />
      <p>{t("Main-Service")}</p>
      </div>
      {/* {language === "ko" ? (
        <> */}
        <div className="flex service-img-div" >
          <img src={service_1} alt="서비스 이미지1"  />
          <img src={service_2} alt="서비스 이미지2" />
          <img src={service_3} alt="서비스 이미지3" />
          <img src={service_4} alt="서비스 이미지4" />
        </div>
       
        {/* </>
        // <img src={Service_KR} alt="서비스 이미지" />
      ) : (
        <img src={Serbice_EN} alt="서비스 이미지" />
      )} */}
    </div>
  );
}

export default Service;
