import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { infoProcess, formatDate } from "../../../common/util";

import bookmark_off from "../../../images/bookmark.png";
import bookmark_on from "../../../images/bookmark2.png";

import { BookMarkUp } from "../../../services/infoService";

import Modal from "../../../common/Modal";
import Alert from "../../../common/AlertModal";
import arrow from "../../../images/back.png";
import News from '../../../images/news.png';
function InfoItem({ item }) {
  const { t } = useTranslation();
  const path = useLocation().pathname;
  let userInfo = JSON.parse(localStorage.getItem("ziple_user"));
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState("");
  const [user, setUser] = useState(0);
  const [checkYn, setCheckYn] = useState(item.checkYn);

  const handlePopUp = async (checkNo, val) => {
    window.open(item.link);
    let form = {
      checkNo: checkNo,
      infoNo: val,
      userNo: user,
      checkDiv: "2",
    };
    await BookMarkUp(form);
  };

  const bookmark = async (checkNo, val, check) => {
    if (userInfo === null) {
      setMessage(t("LoginErrorMsg"));
      setModal(true);
      return;
    }
    if (check === "Y") {
      setCheckYn("N");
      check = "N";
    } else {
      setCheckYn("Y");
      check = "Y";
    }
    let form = {
      checkNo: checkNo,
      infoNo: val,
      userNo: user,
      checkDiv: "1",
      checkYn: check,
    };
    await BookMarkUp(form).then((res) => {
      if (res.resCd === "0000") {
        item.checkYn = check;
        item.checkNo = checkNo;
        // window.location.href="/lounge/"+item.infoDiv;
      }
    });
  };

  const handleImgError = (e) => {
    e.target.src = News;
  }
  useEffect(() => {
    if (userInfo !== null) {
      setUser(userInfo.userNo);
    }
  }, []);

  return (
    <div className="info-item-box flex">
      {/* 특허뉴스 */}
      {item.infoDiv === "01" && (
        <div className="info-item-news">
          <div className="info-content-box">
            <p className="info-content-title">{item.subject}</p>
            {item.contents != null && (
              <p className="info-content-contents">
                {item.contents.substr(0, 50)}
              </p>
            )}

            <p className="info-content-date">
              {formatDate(item.regDate)}{" "}
              <span style={{ opacity: "0.5" }}>|</span> {item.organizer}
            </p>
          </div>
          <div className="info-image-box">
            {item.infoDiv == 1 && item.imgUrl != null && item.imgUrl != "null" &&(<img src={item.imgUrl} alt="info_img"  onError={handleImgError}  />)}
            
            
            {/* <p
              className="info-news-link"
              onClick={() => handlePopUp(item.checkNo, item.infoNo)}
            >
              {t("CheckArticle")} &gt;
            </p> */}
          </div>
          {path.indexOf("lounge") !== -1 && user !== null && (
            <div className="bookmark-btn">
              {checkYn === "Y" && user !== 0 ? (
                <img
                  src={bookmark_on}
                  className="bookmark"
                  alt="bookmarkon"
                  onClick={() =>
                    bookmark(item.checkNo, item.infoNo, item.checkYn)
                  }
                />
              ) : (
                <img
                  src={bookmark_off}
                  className="bookmark"
                  alt="bookmarkoff"
                  onClick={() =>
                    bookmark(item.checkNo, item.infoNo, item.checkYn)
                  }
                />
              )}
            </div>
          )}
           <div className="info-detail-button">
              <button onClick={() => handlePopUp(item.checkNo, item.infoNo)}>
                {t("View-More")}
                <img src={arrow} />
              </button>
            </div>
        </div>
      )}

      {/* R&D, 경진대회 */}
      {item.infoDiv !== "01" && (
        <div className="info-item-RnD">
          <div className="info-process-state">
            <span
              className={
                item.process === "1" ? "info-process-on" : "info-process-off"
              }
            >
              {t(infoProcess(item.process))}
            </span>
          </div>
          <div className="info-content-box">
            <p
              className="info-RnD-title"
              onClick={() => handlePopUp(item.checkNo, item.infoNo)}
            >
              {item.subject}
            </p>
            {/* <p className="info-content-contents">
              {item.contents.substr(0, 50)}
            </p> */}
            <p className="info-content-date">
              {formatDate(item.regDate)} <span style={{opacity: '0.5'}}>|</span> {item.organizer}
            </p>
          </div>
          {path.indexOf("lounge") !== -1 && (
            <>
            <div className="bookmark-btn">
              {checkYn === "Y" && user !== 0 ? (
                <img
                  src={bookmark_on}
                  className="bookmark"
                  alt="bookmarkon"
                  onClick={() =>
                    bookmark(item.checkNo, item.infoNo, item.checkYn)
                  }
                />
              ) : (
                <img
                  src={bookmark_off}
                  className="bookmark"
                  alt="bookmarkoff"
                  onClick={() =>
                    bookmark(item.checkNo, item.infoNo, item.checkYn)
                  }
                /> 
              )}
             
            </div>
            
            </>
          )}
         
           <div className="info-detail-button">
              <button onClick={() => handlePopUp(item.checkNo, item.infoNo)}>
                {t("View-More")}
                <img src={arrow} />
              </button>
            </div>
        </div>
      )}

      {modal && (
        <Modal>
          <Alert msg={message} setShowModal={setModal} />
        </Modal>
      )}
    </div>
  );
}

export default InfoItem;
