import React from "react";
import { useTranslation } from "react-i18next";

import "../../../css/infoLounge/Info.css";

import icon from "../../../images/rnd_icon.png";
import InfoItem from "./InfoItem";

function RnD({ info }) {
  const { t } = useTranslation();

  return (
    <div className="main">
      <div className="flex RnD-title-box">
        <img src={icon} alt="RnD 아이콘" />
        <div className="news-titie">
          <p>{t('IpRnDMsg')}</p>
          <p>{t('IpRnDMsg2')}</p>
          <p>{t('IpRnDMsg3')}</p>
        </div>
      </div>

      {info.map((item, index) => (
        <InfoItem key={item.infoNo} item={item} />
      ))}
    </div>
  );
}

export default RnD;
