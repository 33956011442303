import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Logo from "../../images/logo.png";
import Back from "../../images/back.png";
import Email from "../../images/Email.png";
import person from "../../images/person.png";

import "../../css/login/Login.css";
import "../../css/signUp/SignUp.css";

import Button from "./component/Button";
import Modal from "../../common/Modal";
import Alert from "../../common/AlertModal";

import { checkRealEmail } from "../../util/inputUtil";
import { emailCheck, sendPasswordEmail } from "../../services/loginService";

function Password({ history }) {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState("");

  const sendEmail = (type) => {
    console.log("name", name.trim() !== "", name.trim());
    // 유효성 검사
    let check = checkRealEmail(email);
    if (check) {
      //이름 빈문자 확인
      if (name.trim() !== "") {
        // 가입된 이메일인지 검사
        emailCheck(email, name).then((res) => {
          if (res.resCode === "0000") {
            setMessage(t("Send-Email-Alert"));
            setModal(true);
            // 이메일 전송
            sendPasswordEmail(email).then((res) => {
              // res === 0000 이면 이메일 전송 성공
            });
          }
          if (res.resCode === "9999") {
            setMessage(t("Send-Email-Fail"));
            setModal(true);
          }
          if (res.resCode === "8888") {
            setMessage(t("Send-Email-Social"));
            setModal(true);
          }
          return;
        });
      } else {
        // 이름이 빔
        setMessage(`${t("NameIn")}`);
        setModal(true);
      }
    } else {
      // 이메일 형식이 아님
      setMessage(`${t("Reset-Password-Msg")}`);
      setModal(true);
    }
  };

  useEffect(() => {
    if (!modal) {
      if (message === `${t("Send-Email-Alert")}`) {
        window.location.href = "/login";
      }
    }
  }, [modal]);

  return (
    <div className="grey full-cover login-padding">
      <div>
        <img
          src={Logo}
          alt="ziple_logo"
          className="block logo-image"
          onClick={() => history.push("/")}
        />

        <div className="white sign-box">
          <div className="login-box">
            <div className="flex login-title">
              <div className="back-box">
                <img
                  src={Back}
                  alt="back_btn"
                  className="back-image"
                  onClick={() => history.goBack()}
                />
              </div>
              <p>{t("PasswordChg")}</p>
            </div>

            <p className="find-password-title">{t("Reset-Password-Msg")}</p>
            <div
              className="flex login-input-box"
              style={{ marginBottom: "20px" }}
            >
              <img src={Email} alt="email" />
              <input
                type="text"
                placeholder={t("EmailIn")}
                value={email}
                maxLength="100"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div
              className="flex login-input-box"
              style={{ marginBottom: "30px" }}
            >
              <img src={person} alt="name" />
              <input
                type="text"
                placeholder={t("NameIn")}
                value={name}
                maxLength="20"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <Button type="simple" msg={t("Send-Email")} change={sendEmail} />
          </div>
        </div>
      </div>

      {modal && (
        <Modal>
          <Alert msg={message} setShowModal={setModal} />
        </Modal>
      )}
    </div>
  );
}

export default withRouter(Password);
