import React from "react";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Menu({ history, menu, user }) {
  const { t } = useTranslation();

  const pageMove = (location) => {
    history.push(location);
  };

  return (
    <div className="my-page-hello">
      <div className="active-menu">
        <p>{t("hello")}</p>
        {user.nickname}&nbsp;{t("Sir")}!
      </div>

      <div className="my-page-menu">
        <div
          className={menu === "마이페이지" ? "active-menu" : undefined}
          onClick={() => pageMove("/my/personal")}
        >
          {t("My-Page")}
        </div>
        <div
          className={menu === "나의 북마크" ? "active-menu" : undefined}
          onClick={() => pageMove("/my/mark")}
        >
          {t("My-BookMark")}
        </div>
        <div
          className={menu === "나의 질의응답" ? "active-menu" : undefined}
          onClick={() => pageMove("/my/qna")}
        >
          {t("My-Q&A")}
        </div>
        <div
          className={menu === "회원탈퇴" ? "active-menu" : undefined}
          onClick={() => pageMove("/my/sign-out")}
        >
          {t("Withdrawal")}
        </div>
      </div>
    </div>
  );
}

export default withRouter(Menu);
