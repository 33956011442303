import React from "react";
import { useTranslation } from "react-i18next";

function ConfirmModal({ msg, setShowModal, setResult }) {
  const { t } = useTranslation();

  return (
    <div className="modal-body-confirm">
      <div className="modal-msg-area">
        <p className="alert-msg">{msg}</p>
      </div>

      <div className="modal-btn-area">
        <button className="confirm-btn" onClick={() => setShowModal(false)}>
          {t("Cancel")}
        </button>
        <button
          className="confirm-btn dark-blue"
          onClick={() => {
            setShowModal(false);
            setResult(true);
          }}
        >
          {t("Check")}
        </button>
      </div>
    </div>
  );
}

export default ConfirmModal;
