import React, { useEffect, useState } from "react";

import Menu from "./component/MenuBar";
import News from "./component/News";
import RnD from "./component/RnD";
import Contest from "./component/Contest";
import Loading from "../../common/Loading";

import { infoList } from "../../services/infoService";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

function Lounge() {
  const { t } = useTranslation();
  let user = JSON.parse(localStorage.getItem("ziple_user"));
  const location = useParams().type;
  const [menu, setMenu] = useState(
    location === "" || location === undefined ? "01" : location
  );
  const [info, setInfo] = useState([]);
  const [infoCnt, setInfoCnt] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [loading, setLoading] = useState(false);
  const [more, setMore] = useState(false);

  const MoreView = async () => {
    if (infoCnt > info.length) {
      setMore(true);
      infoList({
        userDiv: "1", // 1 일반인, 2 대리인
        infoDiv: menu, // 01 특허뉴스, 02 R&D, 03 경진대회
        subject: "",
        userNo: user === null ? 1 : user.userNo,
        pageNo: pageNo + 1,
        pageSize: 6,
        sortDbField: "reg_date",
        sortDirection: "desc",
      }).then((res) => {
        const fetchedData = res.lists; // 피드 데이터 부분
        const mergedData = info.concat(...fetchedData);
        setInfo(mergedData);
        setInfoCnt(res.listCnt);
        setMore(false);
        setPageNo(pageNo + 1);
      });
    }
  };

  useEffect(() => {
    setLoading(true);
    infoList({
      userDiv: "1", // 1 일반인, 2 대리인
      infoDiv: menu, // 01 특허뉴스, 02 R&D, 03 경진대회
      subject: "",
      userNo: user === null ? 1 : user.userNo,
      pageNo: 1,
      pageSize: 6,
      sortDbField: "reg_date",
      sortDirection: "desc",
    }).then((res) => {
      if(res != null){
        setInfo(res.lists);
        setInfoCnt(res.listCnt);
        setPageNo(1);
      }
      
      setLoading(false);
    });
  }, [menu]);

  if (loading) {
    return (
      <div>
        <Menu menu={menu} setMenu={setMenu} />
        <Loading />
      </div>
    );
  }

  return (
    <div>
      <Menu menu={menu} setMenu={setMenu} />

      {menu === "01" && <News info={info} />}
      {menu === "02" && <RnD info={info} />}
      {menu === "03" && <Contest info={info} />}

      {infoCnt > pageNo * 6 && (
        <div className="info-more-view" onClick={() => MoreView()}>
          {t("View-More")}
        </div>
      )}

      {more && <Loading />}
    </div>
  );
}

export default Lounge;
